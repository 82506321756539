import { ReactElement, useState, useMemo } from "react";
import { Typography, message } from "antd";
import { _isEmpty } from "app/utils/helpers";
import { VendorOfferType, VendorOfferCategories } from "app/types/vendors/vendors.types";
import { useGetVendorOffers } from "app/utils/api/queries/offers.query";
import { IUser } from "app/store/types/user.types";
import { useGetWidgetRaces } from "app/utils/api/queries/races.query";
import User from "app/utils/user";
import VendorOffersCard from "app/components/elements/cards/vendor_offers_card/vendor_offers_card";
import PageWithLoader from "app/hoc/page_with_loader/page_with_loader";
import VendorOffersModal from "app/views/protected/plan/offers/offer_modal";
import userSlice from "app/store/user/user.slice";
import modalsSlice from "app/store/modals/modals.slice";
import "app/views/protected/plan/offers/offers.scss";

type GroupedOffers = {
    [category: string]: VendorOfferType[];
};

const VendorOffers = (): ReactElement => {
    const { user } = userSlice((state) => state);
    const { data, isLoading, isError } = useGetVendorOffers();
    const [messageApi, contextHolder] = message.useMessage();
    const { data: widgetRaces } = useGetWidgetRaces({ display: "menu" });
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [offerDetails, setOfferDetails] = useState<VendorOfferType | null>(null);
    const { toggleMemberFeatureVendorOffersModal } = modalsSlice((state) => state);
    const UserModule = User(user as IUser);

    const groupedOffers =
        useMemo(
            () =>
                data?.reduce<GroupedOffers>((acc, offer) => {
                    const group = offer.category as string;
                    acc[group] = acc[group] || [];
                    acc[group].push(offer);
                    return acc;
                }, {}),
            [data]
        ) || {};

    const toggleOffersModal = (pk: number, mode: "instructions" | "quote" | "payments"): void => {
        if (mode === "payments" && !UserModule.isPremiumUser()) {
            toggleMemberFeatureVendorOffersModal();
        } else {
            const foundOffer = data?.find((offer: VendorOfferType) => offer.pk === pk);
            if (foundOffer) setOfferDetails(foundOffer);

            setTimeout(() => {
                setModalVisible(true);
            });
        }
    };

    const renderGroupedVendorOffers = (): JSX.Element[] | null => {
        if (!_isEmpty(groupedOffers)) {
            const groupKeys = Object.keys(groupedOffers);
            return groupKeys.map((group: string) => (
                <div className="vendor-offers__groups" key={group}>
                    <div className="vendor-offers__heading-wrap">
                        <Typography.Title level={4}>
                            <span>{group}</span>
                        </Typography.Title>
                    </div>
                    <div className="vendor-offers__vendor-groups">
                        {groupedOffers[group].map((offer: VendorOfferType) => (
                            <VendorOffersCard
                                pk={offer.pk}
                                key={offer.pk}
                                title={offer.title}
                                content={offer.content}
                                image={offer.image}
                                listing_url={offer.listing_url}
                                premium={offer.premium}
                                sponsor={offer.sponsor}
                                mode={offer.claim_mode}
                                cta={offer.cta}
                                cta_url={offer.cta_url}
                                toggleModal={toggleOffersModal}
                                user={user}
                            />
                        ))}
                    </div>
                </div>
            ));
        }

        return null;
    };

    const modalFooterVisible = (): boolean => offerDetails?.claim_mode !== "instructions";

    const renderContent = (): JSX.Element => (
        <PageWithLoader isLoading={isLoading} error={isError}>
            <div className="vendor-offers">
                <div className="page__header">
                    <Typography.Title level={1}>Vendor Offers</Typography.Title>
                    <Typography.Text>
                        Exclusive offers from some of the industry&apos;s most trusted vendors.
                    </Typography.Text>
                </div>
                <div className="page__toolbar">
                    <Typography.Text strong>{data?.length} offers</Typography.Text>
                </div>
            </div>
            <div className="vendor-offers__container">
                <div className="vendor-offers__groups-wrap">{renderGroupedVendorOffers()}</div>
            </div>
            <VendorOffersModal
                pk={offerDetails?.pk as number}
                title={offerDetails?.title as string}
                cta={offerDetails?.cta as string}
                content={offerDetails?.content as string}
                sponsor={offerDetails?.sponsor as string}
                conditions={offerDetails?.conditions as string[]}
                claimMode={(offerDetails?.claim_mode as "quote" | "instructions") || "quote"}
                claimInstructions={offerDetails?.claim_instructions as string}
                isVisible={isModalVisible}
                footerVisible={modalFooterVisible()}
                closeModal={() => setModalVisible(false)}
                widgetRaces={widgetRaces || []}
                onSuccess={(message: string) => {
                    messageApi.success(message);
                }}
            />
        </PageWithLoader>
    );

    return (
        <>
            {contextHolder}
            {renderContent()}
        </>
    );
};
export default VendorOffers;
