import { number, object, string } from "yup";

const validationMessages = {
    required: "This field is required",
};

export const claimOfferValidation = object().shape({
    message: string()
        .required(validationMessages.required)
        .min(
            50,
            "Please help vendors better understand your needs by adding a few more details on your request (minimum 50 characters)"
        ),

    phone_number: string().required(validationMessages.required),
    race: object()
        .shape({
            name: string().required(),
            value: object()
                .shape({
                    name: string().required(),
                    pk: number().required(),
                })
                .required(),
        })
        .nullable(),
});
