import LoadingScreen from "app/components/elements/loading_screen/loading_screen";
import { useVendorListings } from "app/utils/api/queries/listings";
import { Navigate, useNavigate } from "react-router-dom";
import URL from "app/constants/route_urls";
import { Button, message, Typography } from "antd";
import { pluralize } from "app/utils/helpers";
import { useEffect, useState } from "react";
import logo from "assets/logo_full.svg";
import "app/views/protected/business_dashboard/listing_upgrade_redirect/listing_upgrade_redirect.scss";

const ListingUpgradeRedirect = () => {
    const { data: listings } = useVendorListings();
    const navigate = useNavigate();
    const basicListings = listings?.filter(
        (listing) => !listing.is_premium && listing.plan.upgradeable
    );
    const [selectedListingUUID, setSelectedListingUUID] = useState<string | null>(null);
    const firstListingUUID = basicListings?.[0]?.uuid;

    const confirmListingUpgradeHandler = () => {
        if (!selectedListingUUID) return;

        navigate(`${URL.LISTING_UPGRADE}/${selectedListingUUID}/`, {
            state: {
                listing: listings?.find((listing) => listing.uuid === selectedListingUUID),
            },
        });
    };

    // Auto select first basic listing
    useEffect(() => {
        if (firstListingUUID) {
            setSelectedListingUUID(firstListingUUID);
        }
    }, [firstListingUUID]);

    // Handle the scenario if listing is not found
    if (listings && listings?.length === 0) {
        message.info("No listing to upgrade");
        return <Navigate to={URL.BUSINESS_DASHBOARD} />;
    }

    // Handle the scenario if listing is already upgraded or all user's listings are already upgraded
    if (listings && listings.every((listing) => listing.is_premium)) {
        message.success(
            `Your ${pluralize("listing", listings.length)} ${listings.length > 1 ? "have" : "has"} already been upgraded to the Premium listing plan`
        );
        return <Navigate to={URL.BUSINESS_LISTINGS} />;
    }

    // Handle the scenario if listing is not upgraded
    if (listings && listings?.length > 0) {
        return (
            <div className="auth">
                <div className="auth-container">
                    <div className="auth-wrap">
                        <div className="auth-wrap__header">
                            <a href={URL.BUSINESS_DASHBOARD} rel="noopener noreferrer">
                                <div className="auth-wrap__logo">
                                    <img src={logo} alt="" />
                                </div>
                            </a>
                        </div>
                        <div className="auth-wrap__content">
                            <Typography.Text>
                                {" "}
                                Please select one of your listings to upgrade:
                            </Typography.Text>
                            <div className="auth-wrap__location-select-wrap">
                                {basicListings?.map((listing) => (
                                    <div
                                        key={listing.uuid}
                                        className={`listing-upgrade-redirect__listing ${selectedListingUUID === listing.uuid ? "active" : ""}`}
                                        onClick={() => {
                                            setSelectedListingUUID(listing.uuid);
                                        }}
                                        role="button"
                                        tabIndex={0}
                                    >
                                        <div>
                                            <Typography.Text className="listing-upgrade-redirect__listing-title">
                                                {listing.title}
                                            </Typography.Text>
                                            <Typography.Text
                                                type="secondary"
                                                className="listing-upgrade-redirect__listing-category"
                                            >
                                                {listing.primary_category.name}
                                            </Typography.Text>
                                        </div>

                                        {/* <div className="listing-upgrade-redirect__listing-status">
                                            {listing.display_status}
                                        </div> */}
                                    </div>
                                ))}
                            </div>

                            <div className="auth-wrap__form-group--submit">
                                <Button
                                    block
                                    type="primary"
                                    onClick={confirmListingUpgradeHandler}
                                    disabled={!selectedListingUUID}
                                >
                                    Upgrade listing
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return <LoadingScreen type="layout" />;
};

export default ListingUpgradeRedirect;
