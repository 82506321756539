import React, { Dispatch, ReactNode, SetStateAction, createContext, useContext } from "react";
import { BudgetItemType, ExtendBudgetItemType } from "app/types/budget/budget.types";
import { Button, Popconfirm } from "antd";
import { UseFormReturn } from "react-hook-form";

interface ItemActionsContextProps {
    isLoading: boolean;
    isRemoving: boolean;
    handleRemoveItem: (item: BudgetItemType) => void;
    item:
        | Partial<
              BudgetItemType & {
                  quantity_override: number | null;
              }
          >
        | undefined;
    formContext: UseFormReturn<ExtendBudgetItemType, any, undefined>;
    setAddMode: Dispatch<SetStateAction<"search" | "select">>;
    type: "revenue" | "cost";
    children?: ReactNode;
}

const ItemActionsContext = createContext<ItemActionsContextProps | undefined>(undefined);

const useItemActionsContext = () => {
    const context = useContext(ItemActionsContext);
    if (!context) {
        throw new Error("useItemActionsContext must be used within an ItemActionsProvider");
    }
    return context;
};

const ItemActionsProvider: React.FC<ItemActionsContextProps> = ({ children, ...props }) => (
    <ItemActionsContext.Provider value={props}>{children}</ItemActionsContext.Provider>
);

const Save = () => {
    const { isLoading, isRemoving } = useItemActionsContext();
    return (
        <Button
            type="primary"
            loading={isLoading}
            disabled={isLoading || isRemoving}
            className="add-edit-budget__cta"
            htmlType="submit"
        >
            <span>Save changes</span>
        </Button>
    );
};

const Delete = () => {
    const { isLoading, isRemoving, handleRemoveItem, item } = useItemActionsContext();
    return (
        <Popconfirm
            title="Delete item"
            description=" Are you sure you want to delete this item?"
            onConfirm={() => handleRemoveItem(item as BudgetItemType)}
            okText="Delete"
            cancelText="Cancel"
            okButtonProps={{ loading: isLoading || isRemoving, danger: true }}
            getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
        >
            <Button
                type="default"
                loading={isRemoving}
                disabled={isLoading || isRemoving}
                danger
                className="add-edit-budget__delete"
            >
                <span>Delete item</span>
            </Button>
        </Popconfirm>
    );
};

const AddOtherItem = () => {
    const {
        isLoading,
        formContext: { trigger, setValue },
        setAddMode,
        type,
    } = useItemActionsContext();

    return (
        <Button
            disabled={isLoading}
            className="ant-btn-primary-outline"
            type="default"
            onClick={() => {
                setValue("virtual_search_key", "");
                setValue("category", null);
                setValue("virtual_group", "");
                trigger("virtual_group");
                setAddMode("select");
            }}
        >
            <span>Add other {type} item</span>
        </Button>
    );
};

const AddItem = () => {
    const {
        formContext: { watch },
        setAddMode,
    } = useItemActionsContext();

    return (
        <Button
            type="primary"
            disabled={!watch("virtual_search_key")}
            onClick={() => setAddMode("select")}
        >
            <span>Add item</span>
        </Button>
    );
};

const ItemActions = {
    Save,
    Delete,
    AddOtherItem,
    AddItem,
};

export { ItemActions, ItemActionsProvider };
