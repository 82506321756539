import { useMemo } from "react";

export const useSelectOptions = (options: any) =>
    useMemo(() => {
        const optionsInStandardFormat =
            Array.isArray(options) &&
            options.every(
                (o) =>
                    Object.keys(o).includes("key") &&
                    Object.keys(o).includes("label") &&
                    Object.keys(o).includes("value")
            );

        const optionsInGroupFormat = Array.isArray(options) && options.every((o) => o.options);

        if (!options) return [];

        // If options already in the standard format (key, label, value)
        if (optionsInStandardFormat) {
            return options;
        }

        // This is for timezones, regions and types
        if (Array.isArray(options)) {
            if (optionsInGroupFormat) {
                return options.map((group) => ({
                    label: group.label,
                    options: group.options.map((o: any) => ({
                        k: o.pk,
                        label: o.display_name || o.name,
                        value: JSON.stringify(o),
                    })),
                }));
            }

            return options.map((o) => ({
                key: o.pk,
                label: o.display_name || o.name,
                value: JSON.stringify(o),
            }));
        }
        // This is for participants and distance_units
        return Object.keys(options || {}).map((key) => ({
            key: options[key],
            label: options[key],
            value: key,
        }));
    }, [options]);
