/* eslint-disable react/no-unstable-nested-components */
import { useState } from "react";
import { Button, Table, Tooltip, Typography } from "antd";
import userSlice from "app/store/user/user.slice";
import { ThinPlusOutlined } from "assets";
import { useCanAccessBB } from "app/hooks/useCanAccessBudgetBuilder";
import AddEditBudgetItemModal from "../add_edit_budget/add_edit_budget_item";
import { budget_columns } from "./budget_columns";
import BudgetTableSummary from "./budget_table_summary";
import BudgetTableHeaderRow from "./budget_table_header-row";

const BudgetTableHeader = ({ type }: { type: "cost" | "revenue" }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const closeModalHandler = () => setModalOpen(false);
    const { user } = userSlice((state) => state);
    const canAccessBB = useCanAccessBB();

    return (
        <>
            <Table
                className="budget-sheet__meta-table budget-sheet__main-header-table"
                showHeader={false}
                columns={budget_columns({ with: user, canAccessBB })}
                summary={() => (
                    <BudgetTableHeaderRow
                        items={{
                            0: (
                                <div className="budget-sheet__header">
                                    <Typography.Text className="budget-sheet__title">
                                        {type === "cost" ? "Costs" : "Revenues"}
                                    </Typography.Text>

                                    <Tooltip title="Add item">
                                        <Button
                                            className="ant-custom-btn-icon"
                                            onClick={() => setModalOpen(true)}
                                        >
                                            <ThinPlusOutlined />
                                        </Button>
                                    </Tooltip>
                                </div>
                            ),
                            5: <Typography.Text strong>Registered</Typography.Text>,
                            7: <Typography.Text strong>Projected</Typography.Text>,
                        }}
                        classNames={{
                            5: "text-center",
                            7: "text-center",
                        }}
                    />
                )}
            />

            <Table
                className="budget-sheet__meta-table"
                columns={budget_columns({ with: user, type, canAccessBB })}
            />

            <AddEditBudgetItemModal
                isVisible={isModalOpen}
                closeModalHandler={closeModalHandler}
                formMode="add"
                type={type}
                item={{
                    category: null,
                    event: null,
                    // price: 0,
                    quantity_type: "",
                    quantity_override: undefined,
                }}
            />
        </>
    );
};

export default BudgetTableHeader;
