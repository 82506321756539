import { ReactElement, useEffect, useCallback } from "react";
import { FacebookLoginClient } from "@greatsumini/react-facebook-login";
import { FacebookIcon } from "assets/icons/icons";
import "app/components/modules/facebook_auth/facebook_auth.scss";

const errorMessages = {
    unknown: "Something went wrong. If the problem persists please contact us.",
    unauthorized: "Please authorize Race Directors HQ App to be able to login with Facebook.",
};

function FacebookAuthLogin({
    text,
    isLoading,
    onError,
    tokenHandler,
}: {
    text: string;
    isLoading: boolean;
    onError: (arg: string) => void;
    tokenHandler: (arg: string) => void;
}): ReactElement {
    const initializeWithCallback = useCallback(async () => {
        FacebookLoginClient.clear();
        await FacebookLoginClient.loadSdk("en_US");
        FacebookLoginClient.init({
            appId: import.meta.env.VITE_FACEBOOK_AUTH as string,
            version: "v9.0",
        });
    }, []);

    useEffect(() => {
        initializeWithCallback();
    }, [initializeWithCallback]);

    const loginHandler = () => {
        FacebookLoginClient.login(
            (props): void => {
                if (props.status === "unknown") onError(errorMessages.unknown);
                if (props.status === "not_authorized") onError(errorMessages.unauthorized);
                if (props.authResponse) tokenHandler(props.authResponse.accessToken);
            },
            {
                scope: "public_profile, email",
            }
        );
    };

    return (
        <button
            className={`facebook-auth ${isLoading ? "isLoading" : ""}`}
            type="button"
            onClick={loginHandler}
        >
            <FacebookIcon className="facebook-auth__icon" />
            <span>{text}</span>
        </button>
    );
}

export default FacebookAuthLogin;
