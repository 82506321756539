import { ReactElement, ReactNode, useEffect, useState } from "react";
import { Typography } from "antd";
import { useSearchParams } from "react-router-dom";
import { useGetRaceReport, useGetReportStatus } from "app/utils/api/queries/race_marketer.query";
import { _isEmpty } from "app/utils/helpers";
import { IUser } from "app/store/types/user.types";
import { useGetWidgetRaces } from "app/utils/api/queries/races.query";
import RaceReport from "app/components/templates/race_marketer/race_report";
import ListModal from "app/components/templates/race_marketer/list_modal";
import modalsSlice from "app/store/modals/modals.slice";
import LoadingScreen from "app/components/elements/loading_screen/loading_screen";
import User from "app/utils/user";
import userSlice from "app/store/user/user.slice";
import racesSlice from "app/store/races/races.slice";
import "app/views/protected/promote/calendar_marketer/calendar_marketer.scss";

const CalendarMarketerError = ({ children }: { children: ReactNode }) => (
    <div className="race-marketer__wrap">
        <div className="race-marketer__error">{children}</div>
    </div>
);

// TODO -> Change the Redux use to ReactQuery
// TODO -> Change the modal layout for the race details
const CalendarMarketer = (): ReactElement => {
    const { toggleImportRaceModal, modals } = modalsSlice((state) => state);
    const { data: widgetRaces, isLoading: isWidgetRacesLoading } = useGetWidgetRaces({
        display: "menu",
    });
    const { currentRace } = racesSlice((state) => state);
    const { user } = userSlice((state) => state);
    const UserModule = User(user as IUser);
    const [showModal, setShowModal] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const modalFromParams = searchParams.get("showModal");

    const {
        data: report,
        refetch: refetchRaceReport,
        isLoading: isReportLoading,
        isError,
        error,
    } = useGetRaceReport(
        { pk: currentRace.pk! },
        {
            enabled: false,
        }
    );

    const {
        isError: isReportStatusError,
        error: reportStatusError,
        isLoading: isReportStatusLoading,
    } = useGetReportStatus(
        {
            pk: currentRace.pk!,
        },
        {
            enabled: !_isEmpty(currentRace),
        }
    );

    const reportNeedsUpdate = isReportStatusError && currentRace.type === "upcoming";

    const isLoading = isReportStatusLoading || isWidgetRacesLoading || isReportLoading;

    // Trigger refetch report if it doesn't exist or has updated
    useEffect(() => {
        if (reportNeedsUpdate && refetchRaceReport) refetchRaceReport();
    }, [refetchRaceReport, reportNeedsUpdate]);

    // Open submit modal if "submit" param is true
    useEffect(() => {
        if (searchParams.get("submit") === "true" && !_isEmpty(report) && !isLoading) {
            setShowModal(true);
            searchParams.delete("submit");
            setSearchParams(searchParams);
        }
    }, [searchParams, isLoading, report, setSearchParams]);

    useEffect(() => {
        if (Boolean(modalFromParams) && !_isEmpty(report) && !isLoading) {
            setShowModal(true);
            searchParams.delete("showModal");
            setSearchParams(searchParams);
        }
    }, [searchParams, isLoading, report, setSearchParams]);

    const renderReportByType = (): JSX.Element | null => {
        if (!_isEmpty(report)) {
            return (
                <RaceReport
                    pk={currentRace.pk as number}
                    listings={report?.listings}
                    status={report?.status}
                    race={report?.race}
                    search_tag={report?.search_tag}
                    race_details={report?.race_details}
                    org_details={report?.org_details}
                    date_created={report?.date_created}
                    date_submitted={report?.date_submitted}
                    currentRace={currentRace}
                    setShowModal={setShowModal}
                />
            );
        }

        return null;
    };

    // Race Marketer Content
    const renderRaceMarketerContent = (): JSX.Element => {
        if (
            error?.response?.status === 404 ||
            (reportStatusError?.response?.status === 404 && currentRace.type === "past")
        ) {
            return (
                <CalendarMarketerError>
                    <Typography.Text>No listing report available for this race.</Typography.Text>
                </CalendarMarketerError>
            );
        }

        if (widgetRaces?.length === 0 && !isWidgetRacesLoading) {
            return (
                <CalendarMarketerError>
                    <Typography.Text>
                        To use this tool, please{" "}
                        <span onClick={toggleImportRaceModal} role="presentation" className="link">
                            add a race{" "}
                        </span>
                        first.
                    </Typography.Text>
                </CalendarMarketerError>
            );
        }

        if (!isReportLoading && !isReportStatusLoading && isError && error) {
            return (
                <CalendarMarketerError>
                    <Typography.Text>Something went wrong. Please try again.</Typography.Text>
                </CalendarMarketerError>
            );
        }

        return <div className="race-marketer__inner-wrap">{renderReportByType()}</div>;
    };

    return (
        <div className="race-marketer__wrap">
            <Typography.Title level={1}>Race Calendar Wizard</Typography.Title>

            <div className="race-marketer__top-wrap">
                <div className="race-marketer__top-item">
                    <div className="page__header">
                        <Typography.Text>
                            Promote your race on the most popular race calendars.
                        </Typography.Text>
                    </div>

                    {!isLoading && !_isEmpty(report) && (
                        <div className="page__toolbar">
                            <Typography.Text strong>
                                {report?.listings?.length} race calendars matched with{" "}
                                {currentRace.name}
                            </Typography.Text>

                            {UserModule.isPremiumUser() && (
                                <Typography.Text strong>
                                    Listing credits: {UserModule.listingCredits("total")}
                                </Typography.Text>
                            )}
                        </div>
                    )}
                </div>
            </div>

            {isLoading && (
                <LoadingScreen
                    type="element"
                    text={
                        currentRace.type === "upcoming" && isReportLoading
                            ? "Checking for listings of your race around the web.\n This may take a few seconds."
                            : "\n\n"
                    }
                />
            )}

            {!isLoading && (
                <div className="race-marketer">
                    <div className="race-marketer__wrap">{renderRaceMarketerContent()}</div>
                </div>
            )}
            <ListModal
                isVisible={showModal}
                closeModal={() => setShowModal(false)}
                pk={currentRace.pk as number}
            />
        </div>
    );
};

export default CalendarMarketer;
