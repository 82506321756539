import { ReactElement, useState, SVGProps, FC } from "react";
import { Typography, Button, Tooltip, Popconfirm } from "antd";
import {
    CashOutlined,
    ProductOutlined,
    ProductDiscountOutlined,
    MarketingOutlined,
    FormOutlined,
    EmailOutlined,
} from "assets/icons/icons";
import { SponsorBenefit } from "app/types/sponsors/sponsor.types";
import { asyncSponsorsTracking } from "app/views/protected/plan/sponsors/sponsor_utils";
import modalsSlice from "app/store/modals/modals.slice";
import userSlice from "app/store/user/user.slice";
import User from "app/utils/user";
import { IUser } from "app/store/types/user.types";
import { BrokenLinkOutlined } from "assets";
import { obscureData } from "app/utils/helpers/obscure_data";
import ProTooltip from "app/components/elements/wrappers/pro_tooltip";
import { SponsorCardProps } from "./index.types";
import BlurredImage from "../../wrappers/blurred_image";
import "app/components/elements/cards/sponsor_card/sponsor_card.scss";

type IconProps = SVGProps<SVGSVGElement> & {
    className?: string;
    title?: string;
};

const Icons: Record<string, FC<IconProps>> = {
    products: (props) => <ProductOutlined {...props} />,
    cash: (props) => <CashOutlined {...props} />,
    product_discounts: (props) => <ProductDiscountOutlined {...props} />,
    marketing: (props) => <MarketingOutlined {...props} />,
    form: (props) => <FormOutlined {...props} />,
    email: (props) => <EmailOutlined {...props} />,
};

const SponsorCard = ({
    pk,
    name,
    description,
    apply_mode,
    apply_url,
    benefits,
    image,
    logo,
    brokenLinks,
    reportLink,
    obscured = false,
}: SponsorCardProps): ReactElement => {
    const [imageLoaded, setImageLoaded] = useState<boolean>(false);
    const { toggleMemberFeatureShowResultsModal } = modalsSlice((state) => state);
    const disabledBrokenLink = (): boolean => brokenLinks.includes(pk);
    const { user } = userSlice((state) => state);
    const UserModule = User(user as IUser);

    const renderIcons = (): JSX.Element => {
        const icons = benefits.map((benefit: SponsorBenefit): JSX.Element => {
            const icon = benefit.name.split(" ").join("_").toLowerCase();
            const IconComponent = Icons[icon];

            return (
                <div className="sponsor-card__tooltip-wrap" key={benefit.pk}>
                    <Tooltip title={benefit.description} placement="bottom">
                        <div className="sponsor-card__tooltip-content">
                            <IconComponent
                                className="sponsor-card__tooltip-icon"
                                key={benefit.pk}
                                title={benefit.name}
                            />
                        </div>
                    </Tooltip>
                </div>
            );
        });

        const applyModeIcon = (): JSX.Element => {
            const IconComponent: any = Icons[apply_mode.name.toLocaleLowerCase()];
            return (
                <div className="sponsor-card__tooltip-wrap">
                    <Tooltip title={apply_mode.description} placement="bottom">
                        <div className="sponsor-card__tooltip-content">
                            <IconComponent
                                className="sponsor-card__tooltip-icon"
                                key={apply_mode.pk}
                                title={apply_mode.name}
                            />
                        </div>
                    </Tooltip>
                </div>
            );
        };

        return (
            <>
                {icons}
                {applyModeIcon()}
            </>
        );
    };

    return (
        <ProTooltip
            customText="This information is only available to Pro members."
            condition={!obscured}
        >
            <div
                className={`sponsor-card ${obscured ? "sponsor-card--obscured" : ""}`}
                onClick={() => obscured && toggleMemberFeatureShowResultsModal()}
                role="presentation"
            >
                <div className="sponsor-card__wrap">
                    <div className="sponsor-card__wrap-item">
                        {image && (
                            <BlurredImage condition={!obscured}>
                                <img
                                    src={image}
                                    alt={name}
                                    onLoad={() => setImageLoaded(true)}
                                    className={`sponsor-card__image ${imageLoaded ? "hasLoaded" : ""}`}
                                />
                            </BlurredImage>
                        )}
                        {logo && (
                            <div className="sponsor-card__logo">
                                <BlurredImage condition={!obscured} blurPercentage={10}>
                                    <img src={logo} alt={name} />
                                </BlurredImage>
                            </div>
                        )}
                    </div>
                    <div className="sponsor-card__wrap-item">
                        <div className="sponsor-card__content-wrap">
                            <div className="sponsor-card__top">
                                <Typography.Title level={3} className="sponsor-card__title">
                                    {obscured ? obscureData(name, "text") : name}
                                </Typography.Title>
                                <div className="sponsor-card__icons-wrap">{renderIcons()}</div>
                            </div>
                        </div>
                        <div className="sponsor-card__description">
                            <Typography.Paragraph>
                                {obscured ? obscureData(description, "text") : description}
                            </Typography.Paragraph>
                        </div>
                        <div className="sponsor-card__actions">
                            <div className="sponsor-card__actions-wrap">
                                <Button
                                    type="default"
                                    className="ant-btn-primary-outline"
                                    onClick={() => {
                                        if (UserModule.isPremiumUser()) {
                                            Object.assign(document.createElement("a"), {
                                                target: "_blank",
                                                href: `${apply_url}`,
                                            }).click();
                                            asyncSponsorsTracking(pk);
                                        }
                                    }}
                                >
                                    Apply now
                                </Button>
                            </div>
                            <div className="sponsor-card__actions-wrap">
                                <div className="sponsor-card__broken-link">
                                    <Tooltip title="Report broken link" placement="bottom">
                                        <Popconfirm
                                            title="Report broken link"
                                            description="Do you really want to report this link as broken?"
                                            onConfirm={() => reportLink(pk)}
                                            okText="Report"
                                            cancelText="Cancel"
                                            okButtonProps={{
                                                loading: disabledBrokenLink(),
                                                danger: true,
                                            }}
                                        >
                                            <Button
                                                disabled={disabledBrokenLink()}
                                                className="ant-custom-btn-icon"
                                            >
                                                <BrokenLinkOutlined
                                                    style={{ width: "1.5rem", height: "1.5rem" }}
                                                />
                                            </Button>
                                        </Popconfirm>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ProTooltip>
    );
};

export default SponsorCard;
