import { ReactElement } from "react";
import { Typography } from "antd";
import "app/views/misc/forbidden_page/forbidden.scss";

const ForbiddenPage = (): ReactElement => (
    <div className="forbidden-page">
        <div className="forbidden-page__info">
            <Typography.Text>You don&apos;t have permissions to view this page</Typography.Text>
        </div>
    </div>
);

export default ForbiddenPage;
