import { useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { _isEmpty } from "app/utils/helpers";
import STORAGE_CONSTANTS from "app/constants/storage";
import Storage from "app/utils/storage/local";
import URL from "app/constants/route_urls";
import LoadingScreen from "app/components/elements/loading_screen/loading_screen";
import userSlice from "app/store/user/user.slice";
import racesSlice from "app/store/races/races.slice";

const RaceImport = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(location?.search);

    const { user } = userSlice((state) => state);
    const { importAsyncRace } = racesSlice((state) => state);

    // Get the `url` param
    const memoizedUrlParam = query.get("url");
    const memoizedThenParam = query.get("then");

    // Import Race
    const importRaceWithCallback = useCallback(async () => {
        if (!memoizedUrlParam) return;

        const response = await importAsyncRace({ url: memoizedUrlParam, fromPageImport: true });

        if (response) navigate(URL.RACE_ADD, { state: { then: memoizedThenParam } });
        else {
            navigate(
                `${URL.UPCOMING_RACES}?raceImportErrorMessage=${response.payload}&raceUrl=${memoizedUrlParam}&then=${memoizedThenParam}`
            );
        }
    }, [importAsyncRace, memoizedUrlParam, navigate, memoizedThenParam]);

    useEffect(() => {
        if (!_isEmpty(user)) {
            if (!memoizedUrlParam) {
                navigate(`${URL.UPCOMING_RACES}?toggleModal=true`);
                Storage.set(STORAGE_CONSTANTS.importRaceModalVisible, "visible");
                return;
            }

            importRaceWithCallback();
        }
    }, [memoizedUrlParam, navigate, user, importRaceWithCallback]);

    return <LoadingScreen type="layout" />;
};

export default RaceImport;
