import { ReactElement, useState, useEffect } from "react";
import { useNavigate, useMatch } from "react-router-dom";
import { Button, Input, Typography } from "antd";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { tryCatch } from "app/utils/helpers/functional_utilities";
import { UserNewPasswordSchema, NewPasswordsType } from "app/lib/validation_schemas/auth.schema";
import { AxiosServiceError } from "app/utils/api/axios/service_error";
import { _isEmpty } from "app/utils/helpers";
import Dialog from "app/components/elements/dialog/dialog";
import userSlice from "app/store/user/user.slice";
import Storage from "app/utils/storage/local";
import STORAGE_CONSTANTS from "app/constants/storage";
import logo from "assets/logo_full.svg";
import URL from "app/constants/route_urls";
import API from "app/utils/api/axios";
import API_URL from "app/constants/api_urls";
import "app/views/public/auth/auth.scss";
import FormItemWrapper from "app/components/elements/form/form_item_wrapper";

function ResetPasswordConfirm(): ReactElement {
    const match = useMatch(URL.VERIFY_PASSWORD_RESET);
    const navigate = useNavigate();
    const { isAuthenticated } = userSlice((state) => state);

    const [params, setParams] = useState<{ uid: string; token: string }>({ uid: "", token: "" });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [apiErrors, setApiErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        if (match && !_isEmpty(match.params)) {
            if ("uid" in match.params && "token" in match.params) {
                setParams({ uid: match.params.uid as string, token: match.params.token as string });
            } else navigate(URL.LOGIN);
        }
    }, [match, navigate]);

    useEffect(() => {
        if (isAuthenticated) navigate(URL.DASHBOARD);
    }, [isAuthenticated, navigate]);

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(UserNewPasswordSchema),
        mode: "onBlur",
        reValidateMode: "onBlur",
        defaultValues: {
            newPassword1: "",
            newPassword2: "",
        },
    });

    const passwordResetAction = async ({
        newPassword1,
        newPassword2,
    }: NewPasswordsType): Promise<{ detail: string }> => {
        try {
            const { data } = await API.post(API_URL.PASSWORD_RESET_CONFIRM, {
                new_password1: newPassword1,
                new_password2: newPassword2,
                uid: params.uid,
                token: params.token,
            });
            return data;
        } catch (err) {
            throw new AxiosServiceError(err);
        }
    };

    const submitHandler = async (values: NewPasswordsType): Promise<void> => {
        setIsLoading(true);
        setApiErrors({});
        const [error, result] = await tryCatch(passwordResetAction)(values);

        if (error) {
            setIsLoading(false);
            setApiErrors(error?.fieldErrors);
            return;
        }

        Storage.set(STORAGE_CONSTANTS.passwordResetSuccessMessage, result?.detail as string);
        reset();
        setIsLoading(false);
        navigate(URL.LOGIN);
    };

    const displayNonFieldErrors = (): JSX.Element | undefined | null => {
        if (apiErrors && Object.keys(apiErrors).length !== 0) {
            if ("token" in apiErrors || "uid" in apiErrors) {
                return (
                    <Dialog
                        message="Something went wrong. Please use the link we sent you via email and try again"
                        type="error"
                    />
                );
            }
        }
        return null;
    };

    return (
        <div className="auth">
            <div className="auth-container">
                <div className="auth-wrap">
                    <div className="auth-wrap__header">
                        <a href="/" rel="noopener noreferrer">
                            <div className="auth-wrap__logo">
                                <img src={logo} alt="logo" />
                            </div>
                        </a>
                    </div>
                    <div className="auth-wrap__content">
                        {displayNonFieldErrors()}
                        <form onSubmit={handleSubmit(submitHandler)}>
                            <div className="auth-wrap__form-group">
                                <FormItemWrapper label="New password" error={errors.newPassword1}>
                                    <Controller
                                        name="newPassword1"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <Input.Password
                                                value={value || ""}
                                                placeholder="Enter your password"
                                                status={errors?.newPassword1 ? "error" : ""}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                            />
                                        )}
                                    />
                                </FormItemWrapper>
                            </div>
                            <div className="auth-wrap__form-group">
                                <FormItemWrapper
                                    label="Confirm new password"
                                    error={errors.newPassword2}
                                >
                                    <Controller
                                        name="newPassword2"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <Input.Password
                                                value={value || ""}
                                                placeholder="Confirm your password"
                                                status={errors?.newPassword2 ? "error" : ""}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                            />
                                        )}
                                    />
                                </FormItemWrapper>
                            </div>
                            <div className="auth-wrap__form-group--submit">
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    disabled={isLoading}
                                    loading={isLoading}
                                >
                                    Reset password
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPasswordConfirm;
