/* eslint-disable react/no-unstable-nested-components */
import { Table, Typography } from "antd";
import { useState } from "react";
import { ChevronDownOutlined } from "assets/icons/icons";
import { BudgetType, BudgetItemsGroupType } from "app/types/budget/budget.types";
import { formatCurrency } from "app/utils/helpers/format_currency";
import userSlice from "app/store/user/user.slice";
import { useCanAccessBB } from "app/hooks/useCanAccessBudgetBuilder";
import { obscureData } from "app/utils/helpers/obscure_data";
import ObscuredBBWrapper from "app/components/modules/budget/obscured_bb_wrapper/obscured_bb_wrapper";
import { groupObjectsByCategory } from "../table_utils";
import { variance_columns } from "./variance_columns";
import VarianceTableSummary from "./variance_table_summary";
import VarianceTableHeader from "./variance_table_header";
import "app/components/modules/budget/budget_sheet/budget_sheet.scss";

const VarianceSheet = ({ data }: { data: BudgetType | undefined }) => {
    const { user } = userSlice((state) => state);
    const [openGroups, setOpenGroups] = useState<string[]>([]);

    const canAccessBB = useCanAccessBB();
    const renderPremiumValue = (val: string) => (canAccessBB ? val : obscureData(val, "currency"));

    const totals = {
        revenue: {
            budgeted: data?.items
                .filter((i) => i.category?.type === "revenue")
                .reduce((acc, item) => acc + (item.total_budg ? +item.total_budg : 0), 0),
            realized: data?.items
                .filter((i) => i.category?.type === "revenue")
                .reduce((acc, item) => acc + (item.total_proj ? +item.total_proj : 0), 0),
            variance: data?.variance_revenues,
            variance_pct: data?.variance_pct_revenues,
        },
        cost: {
            budgeted: data?.items
                .filter((i) => i.category?.type === "cost")
                .reduce((acc, item) => acc + (item.total_budg ? +item.total_budg : 0), 0),
            realized: data?.items
                .filter((i) => i.category?.type === "cost")
                .reduce((acc, item) => acc + (item.total_proj ? +item.total_proj : 0), 0),
            variance: data?.variance_costs,
            variance_pct: data?.variance_pct_costs,
        },
        all: {
            budgeted: data?.items.reduce(
                (acc, item) => acc + (item.total_budg ? +item.total_budg : 0),
                0
            ),
            realized: data?.items.reduce(
                (acc, item) => acc + (item.total_proj ? +item.total_proj : 0),
                0
            ),
            variance: data?.variance,
        },
    };

    const renderBudgetGroupTable = (group: BudgetItemsGroupType) => {
        const isOpen = !openGroups.includes(group.name);

        return (
            <div key={group.name} className="budget-sheet__group-wrapper">
                <Table
                    className="budget-sheet__meta-table budget-sheet__header-table"
                    columns={variance_columns({ with: user })}
                    showHeader={false}
                    summary={() => (
                        <VarianceTableSummary
                            items={{
                                0: (
                                    <Typography.Text className="budget-sheet__row-title">
                                        {group.name}{" "}
                                        <button
                                            type="button"
                                            onClick={() => {
                                                if (openGroups.includes(group.name)) {
                                                    setOpenGroups(
                                                        openGroups.filter(
                                                            (name) => name !== group.name
                                                        )
                                                    );
                                                } else {
                                                    setOpenGroups([...openGroups, group.name]);
                                                }
                                            }}
                                            className={isOpen ? "active" : ""}
                                        >
                                            <ChevronDownOutlined />
                                        </button>
                                    </Typography.Text>
                                ),
                            }}
                        />
                    )}
                />

                <Table
                    className={`budget-sheet__group-table ${isOpen ? "active" : ""}`}
                    columns={variance_columns({ with: user, canAccessBB })}
                    showHeader={false}
                    dataSource={group.items}
                    pagination={false}
                    rowKey={(record) => record?.pk}
                    size="small"
                    onRow={(record) => ({
                        className: "budget-sheet__row",
                        style: {
                            cursor: "default",
                        },
                    })}
                />
            </div>
        );
    };

    const renderBudgeSheetByType = (type: "revenue" | "cost") => {
        const objects = data?.items.filter((item) => item.category?.type === type) || [];
        const editableObjects = objects.filter((item) => item.editable);
        const groups = groupObjectsByCategory(editableObjects);

        return (
            <>
                <VarianceTableHeader type={type} />

                {groups.map((group) => renderBudgetGroupTable(group))}

                <Table
                    className="budget-sheet__meta-table budget-sheet__meta-table--summary"
                    columns={variance_columns({ with: user })}
                    showHeader={false}
                    summary={() => {
                        const totalVariance = totals[type].variance;

                        return (
                            <VarianceTableSummary
                                items={{
                                    0: (
                                        <span className="budget-sheet__meta-table-total">
                                            Total
                                        </span>
                                    ),
                                    2: (
                                        <span className="budget-sheet__meta-table-total">
                                            {formatCurrency(
                                                totals[type].budgeted,
                                                user?.location?.ccy,
                                                0
                                            )}
                                        </span>
                                    ),
                                    3: (
                                        <span className="budget-sheet__meta-table-total">
                                            <ObscuredBBWrapper>
                                                {renderPremiumValue(
                                                    formatCurrency(
                                                        totals[type].realized,
                                                        user?.location?.ccy,
                                                        0
                                                    )
                                                )}
                                            </ObscuredBBWrapper>
                                        </span>
                                    ),
                                    4: (
                                        <span className="budget-sheet__meta-table-total">
                                            <ObscuredBBWrapper>
                                                {!!totalVariance && (totalVariance > 0 ? "+" : "")}
                                                {renderPremiumValue(
                                                    totalVariance
                                                        ? formatCurrency(
                                                              totals[type].variance,
                                                              user?.location?.ccy,
                                                              0
                                                          )
                                                        : ""
                                                )}
                                            </ObscuredBBWrapper>
                                        </span>
                                    ),
                                    5: totals[type].variance_pct && (
                                        <span className="budget-sheet__meta-table-total">
                                            <ObscuredBBWrapper>
                                                {!!totalVariance && (totalVariance > 0 ? "+" : "")}
                                                {renderPremiumValue(totals[type].variance_pct)}
                                            </ObscuredBBWrapper>
                                        </span>
                                    ),
                                }}
                            />
                        );
                    }}
                />
            </>
        );
    };

    return (
        <div className="budget-sheet">
            <div className="budget-sheet__revenues table__wrap">
                {renderBudgeSheetByType("revenue")}
            </div>
            <div className="budget-sheet__costs table__wrap">{renderBudgeSheetByType("cost")}</div>

            <Table
                className="budget-sheet__meta-table budget-sheet__meta-table--collective"
                columns={variance_columns({ with: user })}
                showHeader={false}
                summary={() => (
                    <VarianceTableSummary
                        items={{
                            0: <span className="budget-sheet__meta-table-total">Total</span>,
                            2: (
                                <span className="budget-sheet__meta-table-total">
                                    {formatCurrency(totals.all.budgeted, user?.location?.ccy, 0)}
                                </span>
                            ),
                            3: (
                                <ObscuredBBWrapper>
                                    <span className="budget-sheet__meta-table-total">
                                        {renderPremiumValue(
                                            formatCurrency(
                                                totals.all.realized,
                                                user?.location?.ccy,
                                                0
                                            )
                                        )}
                                    </span>
                                </ObscuredBBWrapper>
                            ),
                            4: (
                                <ObscuredBBWrapper>
                                    <span className="budget-sheet__meta-table-total">
                                        {!!totals.all.variance &&
                                            (totals.all.variance > 0 ? "+" : "")}
                                        {renderPremiumValue(
                                            formatCurrency(
                                                totals.all.variance,
                                                user?.location?.ccy,
                                                0
                                            )
                                        )}
                                    </span>
                                </ObscuredBBWrapper>
                            ),
                        }}
                    />
                )}
            />
        </div>
    );
};

export default VarianceSheet;
