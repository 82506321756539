import React from "react";
import { Typography } from "antd";
import { IDialog } from "./index.types";
import "app/components/elements/dialog/dialog.scss";

function Dialog({ message, type }: IDialog): JSX.Element {
    return (
        <div className={`dialog-wrap dialog-wrap--${type}`}>
            <Typography.Text>{message}</Typography.Text>
        </div>
    );
}

export default Dialog;
