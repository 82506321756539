import { ReactElement, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { message, Button, Tooltip, Popconfirm, Typography } from "antd";
import {
    CheckOutlined,
    ChevronRightOutlined,
    BinOutlined,
    PenOutlined,
    CopyOutlined,
} from "assets/icons/icons";
import { useRacesWidget } from "app/hooks/useRacesWidget";
import { RaceType } from "app/types/races/race.types";
import { DateUtils } from "app/utils/helpers";
import { listingReportActionNavigate } from "app/components/elements/cards/race_card/listing_report_action";
import { useDeleteRace } from "app/utils/api/mutations/races.mutation";
import URL from "app/constants/route_urls";
import userSlice from "app/store/user/user.slice";
import racesSlice from "app/store/races/races.slice";
import Storage from "app/utils/storage/local";
import STORAGE_CONSTANTS from "app/constants/storage";
import "app/components/elements/cards/race_card/race_card.scss";

const RaceCard = ({
    pk,
    name,
    display_date,
    city_state,
    listed,
    logo,
    type,
}: RaceType): ReactElement => {
    const imageRef = useRef<HTMLImageElement>(null);
    const listRef = useRef<HTMLUListElement>(null);
    const cardRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const location = useLocation();

    const { user } = userSlice((state) => state);
    const { currentRace, setCurrentRace } = racesSlice((state) => state);

    const [loading, setLoading] = useState<boolean>(false);

    const { mutateAsync: deleteRace, isPending: isDeleteLoading } = useDeleteRace({
        onSuccess: () => {
            message.success({
                content: `${name} has been deleted`,
                duration: 4,
            });
        },
        onError: () => {
            message.error({
                content: "Something went wrong while deleting your race. Please try again.",
                onClose: () => cardRef.current?.classList.remove("isDeleting"),
            });
        },
    });

    const { handleSelectRaceString } = useRacesWidget();

    const isActive = pk === currentRace.pk;

    const raceListingStatus = (): JSX.Element | null => {
        if (listed)
            return (
                <li
                    className="race-card__info-item-list-item isAnchor"
                    onClick={() =>
                        listingReportActionNavigate(
                            pk,
                            name,
                            type,
                            navigate,
                            URL.REPORTS,
                            user,
                            setCurrentRace
                        )
                    }
                    role="presentation"
                >
                    <Typography.Text>View listing report</Typography.Text>
                    <span
                        onClick={() =>
                            listingReportActionNavigate(
                                pk,
                                name,
                                type,
                                navigate,
                                URL.REPORTS,
                                user,
                                setCurrentRace
                            )
                        }
                        role="presentation"
                    >
                        <CheckOutlined className="check-icon" />
                    </span>
                </li>
            );
        if (type === "upcoming" && !listed)
            return (
                <li
                    className="race-card__info-item-list-item isAnchor"
                    onClick={() =>
                        listingReportActionNavigate(
                            pk,
                            name,
                            type,
                            navigate,
                            URL.REPORTS,
                            user,
                            setCurrentRace
                        )
                    }
                    role="presentation"
                >
                    <Typography.Text>Promote on race calendars</Typography.Text>
                    <span>
                        <ChevronRightOutlined />
                    </span>
                </li>
            );
        if (type === "past" && listed)
            return (
                <span>
                    <CheckOutlined className="check-icon" />
                </span>
            );
        return null;
    };

    const handleSelectRace = (): void => {
        handleSelectRaceString(
            JSON.stringify({
                pk,
                name,
                type: type as "past" | "upcoming",
            })
        );
    };

    const currentRaceSelection = (): JSX.Element | null => {
        if (!isActive) {
            return (
                <Tooltip title="Make this your current working race" placement="bottom">
                    <Button
                        className="ant-btn-primary-outline"
                        size="middle"
                        type="default"
                        onClick={handleSelectRace}
                    >
                        Select
                    </Button>
                </Tooltip>
            );
        }
        if (isActive) {
            return (
                <Button
                    className="ant-custom-btn-outline-green pointer-events-none"
                    icon={<CheckOutlined />}
                >
                    Selected
                </Button>
            );
        }
        return null;
    };

    const renderRaceInfoStart = (): JSX.Element => {
        const daysUntilStart = DateUtils.daysUntil(display_date);
        const dayOrDays = daysUntilStart > 1 ? `${daysUntilStart} days` : `${daysUntilStart} day`;
        return <Typography.Text>{dayOrDays} to go</Typography.Text>;
    };

    return (
        <div ref={cardRef} className={`race-card ${isActive ? "isActive" : ""}`}>
            <div className="race-card__top">
                <div className="race-card__logo">
                    {logo && (
                        <Link
                            to={`${URL.RACES}${pk}/edit/`}
                            className={type === "past" ? "disabled-link" : ""}
                        >
                            <img
                                src={logo}
                                alt={logo}
                                loading="lazy"
                                onLoad={() => setLoading(true)}
                                ref={imageRef}
                                className={`load-image ${loading ? "image-loaded" : ""}`}
                            />
                        </Link>
                    )}
                </div>

                <div className="race-card__title">
                    <Typography.Title level={3} className={`${isActive ? "isActive" : ""}`}>
                        {name}
                    </Typography.Title>
                </div>
                {type === "upcoming" && (
                    <div className="race-card__race_start-info">{renderRaceInfoStart()}</div>
                )}
            </div>

            <div className="race-card__info">
                <div className="race-card__info-item first-row">
                    <ul className="race-card__info-item-list">
                        <li className="race-card__info-item-list-item">
                            <Typography.Text>Date:</Typography.Text>
                            <Typography.Text type="secondary">{display_date}</Typography.Text>
                        </li>
                        <li className="race-card__info-item-list-item">
                            <Typography.Text>Location:</Typography.Text>
                            <Typography.Text type="secondary">{city_state}</Typography.Text>
                        </li>
                    </ul>
                </div>

                <div
                    className="race-card__info-item second-row"
                    ref={(node) => {
                        if (listRef && listRef?.current?.hasChildNodes())
                            node?.classList.add("hasChildren");
                    }}
                >
                    <ul ref={listRef} className="race-card__info-item-list">
                        {type === "upcoming" && (
                            <li
                                style={{ cursor: "pointer" }}
                                className="race-card__info-item-list-item isAnchor"
                                role="presentation"
                                onClick={() => {
                                    handleSelectRace();
                                    navigate(URL.BUDGET_BUILDER);
                                }}
                            >
                                <Typography.Text>Build a race budget</Typography.Text>
                                <span>
                                    <ChevronRightOutlined />
                                </span>
                            </li>
                        )}
                        {raceListingStatus()}
                        {type === "upcoming" && (
                            <li
                                style={{ cursor: "pointer" }}
                                className="race-card__info-item-list-item isAnchor"
                                onClick={() =>
                                    listingReportActionNavigate(
                                        pk,
                                        name,
                                        type,
                                        navigate,
                                        URL.SPONSORS,
                                        user,
                                        setCurrentRace
                                    )
                                }
                                role="presentation"
                            >
                                <Typography.Text>Find sponsors</Typography.Text>
                                <span>
                                    <ChevronRightOutlined />
                                </span>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
            <div className="race-card__cta">
                <div className="race-card__cta-item">{currentRaceSelection()}</div>
                <div className="race-card__cta-item">
                    <Tooltip title="Copy race" placement="bottom">
                        <Button
                            className="ant-custom-btn-icon"
                            onClick={() => navigate(`${URL.RACES}${pk}/copy/`)}
                        >
                            <CopyOutlined />
                        </Button>
                    </Tooltip>
                    {type === "upcoming" && (
                        <Tooltip title="Edit race" placement="bottom">
                            <Button
                                className="ant-custom-btn-icon"
                                onClick={() => {
                                    Storage.set(
                                        STORAGE_CONSTANTS.editRaceRedirect,
                                        location?.pathname || URL.RACES
                                    );
                                    navigate(`${URL.RACES}${pk}/edit/`);
                                }}
                            >
                                <PenOutlined />
                            </Button>
                        </Tooltip>
                    )}
                    <Tooltip title="Delete race" placement="bottom">
                        <Popconfirm
                            title="Delete race"
                            description=" Are you sure you want to delete this race?"
                            onConfirm={async () => {
                                await deleteRace({ racePk: pk });
                            }}
                            okText="Delete"
                            cancelText="Cancel"
                            okButtonProps={{ loading: isDeleteLoading, danger: true }}
                        >
                            <Tooltip
                                title={listed && "You cannot delete a listed race."}
                                placement="bottom"
                            >
                                <Button className="ant-custom-btn-icon" disabled={listed} danger>
                                    <BinOutlined />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};

export default RaceCard;
