import { IUser } from "app/store/types/user.types";
import userSlice from "app/store/user/user.slice";
import User from "app/utils/user";

export const useCanAccessBB = () => {
    const { user } = userSlice((state) => state);
    const UserModule = User(user as IUser);

    return Boolean(UserModule.isPremiumUser());
};
