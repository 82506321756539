// errorFormatters.ts
export type ErrorMessageReturnType<T extends string> = {
    name: T;
    type: string;
    message: string;
};

const formatErrorMessages = <T extends string>(
    errors: Record<T, string>
): ErrorMessageReturnType<T>[] =>
    (Object.keys(errors) as T[]).map((key) => ({
        name: key as T,
        type: "manual",
        message: errors[key as T],
    }));

export { formatErrorMessages };
