import { ReactElement, useEffect } from "react";
import { Typography } from "antd";
import { useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";
import URL from "app/constants/route_urls";
import PUBLIC_SITE_URL from "app/constants/public_site_urls";
import userSlice from "app/store/user/user.slice";
import "app/views/misc/error_page/error_page.scss";

type ErrorTypes = {
    errorCode?: "404" | "500";
};

function ErrorPage(props: ErrorTypes): ReactElement {
    const location = useLocation();
    const { flushUser, isAuthenticated } = userSlice((state) => state);

    const { errorCode = "500" } = props;

    useEffect(() => {
        if (errorCode === "404") {
            document.title = "Not Found";
        }
    }, [errorCode]);

    if (import.meta.env.VITE_SENTRY_RELEASE && import.meta.env.PROD) {
        if (errorCode === "404")
            Sentry.captureMessage(
                `User tried to access a route that doesn't exist. Route: ${location.pathname}`
            );
    }

    const renderErrorInfo = (): JSX.Element | null => {
        switch (errorCode) {
            case "404":
                return (
                    <div className="error__info">
                        <Typography.Text>
                            The page you requested could not be found.
                        </Typography.Text>
                    </div>
                );
            case "500":
                return (
                    <div className="error__info">
                        <Typography.Text>
                            Something went wrong. Our team have been notified and are looking into
                            the error.
                        </Typography.Text>
                        <div className="error__cta">
                            <button
                                type="button"
                                onClick={() => {
                                    flushUser();
                                    window.location.href = URL.LOGIN;
                                }}
                                className="link"
                            >
                                Retry operation
                            </button>
                            <span className="error__span-divider">or</span>
                            <button
                                type="button"
                                onClick={() => {
                                    window.location.href = PUBLIC_SITE_URL.CONTACT;
                                }}
                                className="link"
                            >
                                Contact us
                            </button>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className={`error ${isAuthenticated ? "" : "error--public"}`}>
            <div>{renderErrorInfo()}</div>
        </div>
    );
}

export default ErrorPage;
