import { useState, ReactElement } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useLocation } from "react-router-dom";
import { Button, Input, Typography } from "antd";
import { tryCatch } from "app/utils/helpers/functional_utilities";
import { NonFieldErrorType } from "app/utils/api/axios/service_error";
import { UserSignUpSchema } from "app/lib/validation_schemas/auth.schema";
import { _isEmpty } from "app/utils/helpers";
import { IUserLocation } from "app/store/types/user.types";
import { ErrorMessageReturnType, formatErrorMessages } from "app/utils/helpers/error_formatter";
import Dialog from "app/components/elements/dialog/dialog";
import GoogleAuthLogin from "app/components/modules/google_auth/google_auth";
import FacebookAuthLogin from "app/components/modules/facebook_auth/facebook_auth";
import logo from "assets/logo_full.svg";
import URL from "app/constants/route_urls";
import Select from "app/components/elements/form/select/select";
import userSlice, { SOCIAL_PROVIDERS } from "app/store/user/user.slice";
import useUserLocations from "app/hooks/useUserLocations";
import "app/views/protected/user/profile/profile.scss";
import { SpinnerOutlined } from "assets/icons/icons";
import FormItemWrapper from "app/components/elements/form/form_item_wrapper";

type SelectOptions = { value: string; name: string; icon: string };

function Register(): ReactElement {
    const location = useLocation();
    const { userLocations } = useUserLocations();
    const [isGoogleLoading, setIsGoogleLoading] = useState<boolean>(false);
    const [isFacebookLoading, setIsFacebookLoading] = useState<boolean>(false);
    const [isRegisterLoading, setIsRegisterLoading] = useState<boolean>(false);
    const [nonFieldErrors, setNonFieldErrors] = useState<NonFieldErrorType>([]);
    const [serverErrors, setServerErrors] = useState<string>("");

    const { registerUser, socialLogin } = userSlice((state) => state);

    const {
        handleSubmit,
        setValue,
        setError,
        clearErrors,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(UserSignUpSchema),
        mode: "onBlur",
        reValidateMode: "onBlur",
        defaultValues: {
            username: "",
            location: "",
            email: "",
            password1: "",
        },
    });

    type FieldNameTypes = "username" | "email" | "location" | "password1";
    const submitHandler = async (values: { [K in FieldNameTypes]: string }): Promise<void> => {
        setNonFieldErrors([]);
        setServerErrors("");
        setIsRegisterLoading(true);

        const { username, email, location, password1 }: { [key: string]: string } = values;
        const [error] = await tryCatch(registerUser)(username, email, password1, location);

        if (error) {
            if (error.statusCode > 404) {
                setServerErrors("Something went wrong. Please try again.");
                return;
            }

            if (error?.fieldErrors) {
                const formattedErrors = formatErrorMessages<FieldNameTypes>(error.fieldErrors);
                formattedErrors.forEach(
                    ({ name, type, message }: ErrorMessageReturnType<FieldNameTypes>) =>
                        setError(name, { type, message })
                );
            }

            setIsRegisterLoading(false);
            setNonFieldErrors(error.nonFieldErrors);

            return;
        }

        reset();
        setIsRegisterLoading(false);
    };

    const displayNonFieldErrors = (): JSX.Element[] | undefined | null => {
        if (!_isEmpty(nonFieldErrors)) {
            return nonFieldErrors.map((error: string) => (
                <Dialog message={error} key={error} type="error" />
            ));
        }
        return null;
    };

    const socialHandlers = {
        [SOCIAL_PROVIDERS.GOOGLE]: () => setIsGoogleLoading(!isGoogleLoading),
        [SOCIAL_PROVIDERS.FACEBOOK]: () => setIsFacebookLoading(!isFacebookLoading),
    };

    // Social login function
    const socialLoginHandler = async (
        socialToken: string,
        provider: SOCIAL_PROVIDERS.GOOGLE | SOCIAL_PROVIDERS.FACEBOOK
    ): Promise<void> => {
        setNonFieldErrors([]);
        socialHandlers[provider]();

        const [error] = await tryCatch(socialLogin)(socialToken, provider);
        if (error) socialHandlers[provider]();

        socialHandlers[provider]();
        reset();
    };

    const selectOptions = (): SelectOptions[] | boolean =>
        !_isEmpty(userLocations) &&
        userLocations !== undefined &&
        userLocations?.reduce((acc: SelectOptions[], item: IUserLocation | undefined) => {
            if (item) acc.push({ value: item?.name, name: item?.name, icon: item?.ccy });
            return acc;
        }, [] as SelectOptions[]);

    return (
        <div className="auth">
            <div className="auth-container">
                <div className="auth-wrap">
                    <div className="auth-wrap__header">
                        <a href="/" rel="noopener noreferrer">
                            <div className="auth-wrap__logo">
                                <img src={logo} alt="logo" />
                            </div>
                        </a>
                    </div>
                    <div className="auth-wrap__content">
                        {!_isEmpty(serverErrors) && <Dialog type="error" message={serverErrors} />}
                        {displayNonFieldErrors()}
                        <div className="auth-wrap__social-group">
                            <div className="auth-wrap__social-group-item">
                                <GoogleAuthLogin
                                    text="Continue with Google"
                                    isLoading={isGoogleLoading}
                                    onError={() =>
                                        setNonFieldErrors([
                                            "Something went wrong. If the problem persists, please contact us.",
                                        ])
                                    }
                                    tokenHandler={(token: string) => {
                                        setNonFieldErrors([]);
                                        socialLoginHandler(token, SOCIAL_PROVIDERS.GOOGLE);
                                    }}
                                />
                            </div>
                            <div className="auth-wrap__social-group-item">
                                {isGoogleLoading && <SpinnerOutlined />}
                            </div>
                        </div>
                        <div className="auth-wrap__social-group">
                            <div className="auth-wrap__social-group-item">
                                <FacebookAuthLogin
                                    text="Continue with Facebook"
                                    isLoading={isFacebookLoading}
                                    onError={(err: string) => {
                                        setNonFieldErrors([err]);
                                    }}
                                    tokenHandler={(token: string) => {
                                        setNonFieldErrors([]);
                                        socialLoginHandler(token, SOCIAL_PROVIDERS.FACEBOOK);
                                    }}
                                />
                            </div>
                            <div className="auth-wrap__social-group-item">
                                {isFacebookLoading && <SpinnerOutlined />}
                            </div>
                        </div>
                        <div className="auth-wrap__divider">
                            <Typography.Text>Or sign up with e-mail</Typography.Text>
                        </div>
                        <form onSubmit={handleSubmit(submitHandler)}>
                            <div className="auth-wrap__form-group">
                                <FormItemWrapper label="Username" error={errors.username}>
                                    <Controller
                                        name="username"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <Input
                                                type="text"
                                                value={value || ""}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                placeholder="e.g. Run for Hunger 5K"
                                                status={errors?.username ? "error" : ""}
                                            />
                                        )}
                                    />
                                </FormItemWrapper>
                            </div>
                            <div className="auth-wrap__form-group">
                                <FormItemWrapper label="Email" error={errors.email}>
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <Input
                                                type="email"
                                                value={value || ""}
                                                placeholder="e.g. john@runforthehunger5k.com"
                                                status={errors?.email ? "error" : ""}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                            />
                                        )}
                                    />
                                </FormItemWrapper>
                            </div>
                            <div className="auth-wrap__form-group">
                                <FormItemWrapper label="Location" error={errors.location}>
                                    <Controller
                                        name="location"
                                        control={control}
                                        render={({ field: { onBlur, value } }) => (
                                            <Select
                                                value={value || ""}
                                                onChange={(l) => {
                                                    setValue("location", l);
                                                    clearErrors("location");
                                                }}
                                                onBlur={onBlur}
                                                error={Boolean(errors?.location)}
                                                options={selectOptions()}
                                            />
                                        )}
                                    />
                                </FormItemWrapper>
                            </div>
                            <div className="auth-wrap__form-group">
                                <FormItemWrapper label="Password" error={errors.password1}>
                                    <Controller
                                        name="password1"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <Input.Password
                                                type="text"
                                                value={value || ""}
                                                status={errors?.password1 ? "error" : ""}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                            />
                                        )}
                                    />
                                </FormItemWrapper>
                            </div>
                            <div className="auth-wrap__form-group--submit">
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    loading={isRegisterLoading}
                                    disabled={
                                        isRegisterLoading || isFacebookLoading || isGoogleLoading
                                    }
                                >
                                    Sign up
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="auth__linking">
                    <Typography.Text>
                        Already have an account?{" "}
                        <Link
                            to={URL.LOGIN + (location?.search || "")}
                            state={location.state}
                            className="ant-link"
                        >
                            Sign in here
                        </Link>
                    </Typography.Text>
                </div>
            </div>
        </div>
    );
}

export default Register;
