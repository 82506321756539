import { ReactElement, useEffect, useRef } from "react";
import { useParams, Navigate, Params, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { IUser } from "app/store/types/user.types";
import { isNumeric, _isEmpty } from "app/utils/helpers";
import AddEditRace from "app/components/templates/add_edit_race/add_edit_race";
import PageWithLoader from "app/hoc/page_with_loader/page_with_loader";
import URL from "app/constants/route_urls";
import { RaceDetailsType } from "app/types/races/race.types";
import { defaultRaceData } from "app/components/templates/add_edit_race/constants";
import userSlice from "app/store/user/user.slice";
import { useGetRaceDetails, useGetRaceFormData } from "app/utils/api/queries/races.query";
import racesSlice from "app/store/races/races.slice";
import { QUERY_KEYS } from "app/utils/api/queries/constants";

/**
 * TODO An improvement, only refetch form data if the location changed
 */

const RaceDetails = (): ReactElement => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const params = useParams<Readonly<Params<string>>>();
    const raceMode = useRef<string>(params && params.id ? "edit" : "add");
    const isEditingRace = !!(params?.id && isNumeric(params?.id) && raceMode.current === "edit");
    const {
        data: raceDetail,
        isLoading: isRaceDetailLoading,
        error,
        isError,
    } = useGetRaceDetails(
        { pk: params.id! },
        {
            enabled: isEditingRace,
        }
    );

    // Redux Store
    const { user } = userSlice((state) => state);
    const raceFormData = useGetRaceFormData({});
    const { importedRace } = racesSlice((state) => state);

    // Handle page title
    useEffect(() => {
        if (raceMode.current === "edit" && !_isEmpty(raceDetail))
            document.title = `Edit ${raceDetail?.name}`;
        if (raceMode.current === "add" && !_isEmpty(importedRace))
            document.title = `Add ${importedRace?.race?.name}`;
    }, [importedRace, importedRace?.race?.name, raceDetail, raceDetail?.name]);

    // Reidirect if not found
    useEffect(() => {
        if (isError && error?.response.data.detail === "Not found.") navigate("/404");
    }, [error, isError, navigate]);

    // On component un-mount clear race detail
    useEffect(
        () => () => {
            if (params.id) {
                queryClient.removeQueries({
                    queryKey: [QUERY_KEYS.RACE_DETAILS, params.id],
                });
            }
        },
        [params.id, queryClient]
    );

    // Memoized Race Data object depending on race mode
    const memoizedRaceData = (): RaceDetailsType => {
        // TODO Check typescript conditional types and add the correct type
        // TODO Build a combined Types from both import and details
        const race = raceMode.current === "edit" && raceDetail ? raceDetail : importedRace.race;
        return defaultRaceData(race, raceMode, user as IUser);
    };

    // Display content depending on certain conditions
    const renderRaceDetailsByType = (): JSX.Element => {
        if (params.id && !isNumeric(params.id)) return <Navigate to={URL.RACES} />;
        if (params?.id && isNumeric(params.id))
            return (
                <AddEditRace
                    mode={raceMode.current as "edit"}
                    user={user as IUser}
                    formData={raceFormData.data || {}}
                    raceData={memoizedRaceData()}
                />
            );

        return <Navigate to={URL.RACES} />;
    };

    return (
        <PageWithLoader
            isLoading={
                raceFormData.isLoading ||
                (isEditingRace && isRaceDetailLoading) ||
                _isEmpty(user) ||
                _isEmpty(raceFormData.data)
            }
        >
            {renderRaceDetailsByType()}
        </PageWithLoader>
    );
};

export default RaceDetails;
