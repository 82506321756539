/* eslint-disable react/no-danger */
import { ChangeEvent, ReactElement, useEffect, useRef } from "react";
import { Button, Input, Modal, Select, Typography } from "antd";
import { _isEmpty } from "app/utils/helpers";
import { Option } from "app/components/elements/form/select/select.types";
import { WidgetRaceType } from "app/types/races/race.types";
import Dialog from "app/components/elements/dialog/dialog";
import useClaimOffer from "app/views/protected/plan/offers/useClaimOffer";
import { claimOfferValidation } from "app/lib/validation_schemas/claim_offer_validation.schema";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormItemWrapper from "app/components/elements/form/form_item_wrapper";
import TextArea from "antd/es/input/TextArea";
import { PhoneOutlined } from "assets";
import { ChevronDownOutlined } from "assets/icons/icons";

const { Title } = Typography;

type FormInputsType = {
    message: string;
    phone_number: string;
    race: {
        name: string;
        value: {
            name: string;
            pk: number;
        };
    } | null;
};

const VendorOffersModal = ({
    pk,
    title,
    sponsor,
    content,
    claimMode,
    isVisible,
    claimInstructions,
    footerVisible,
    cta,
    widgetRaces,
    conditions,
    closeModal,
    onSuccess,
}: {
    pk: number;
    title: string;
    cta: string;
    content: string;
    sponsor: string;
    isVisible: boolean;
    claimMode: "quote" | "instructions";
    claimInstructions: string;
    footerVisible: boolean;
    conditions: string[];
    widgetRaces: Partial<WidgetRaceType>[];
    onSuccess: (success: string) => void;
    closeModal: () => void;
}): ReactElement => {
    const { isLoading, success, error, handler, clearClaimOffer } = useClaimOffer(pk);

    const {
        handleSubmit,
        register,
        watch,
        setValue,
        trigger,
        control,
        reset,
        formState: { errors, isDirty },
    } = useForm({
        resolver: yupResolver(claimOfferValidation),
        mode: "all",
        reValidateMode: "onChange",
        defaultValues: {
            message: "",
            phone_number: "",
            race: null,
        },
    });

    const onFieldChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValue(event.target.name as keyof FormInputsType, event.target.value);
        trigger(event.target.name as keyof FormInputsType);
    };

    const claimOfferAction = async (formData: FormInputsType) => {
        const response = await handler({
            content: formData.message,
            author_phone: formData.phone_number,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            race: formData.race ? { pk: formData.race!.value.pk } : null,
            source: "dashboard_offers",
        });

        if (response) {
            closeModal();
            onSuccess("Your offer claim has been submitted");
        }
    };

    const checkForUpcomingRace = (): boolean | Option =>
        widgetRaces?.some((race) => race.groupLabel === "upcoming");

    const renderSelectOptions = () =>
        !_isEmpty(widgetRaces) &&
        widgetRaces
            .filter((race) => race.groupLabel === "upcoming")
            .map((race) => ({ name: race.name, value: { pk: race.pk, name: race.name } }));

    const selectOptions = renderSelectOptions();

    const renderConditionsList = (conditions: string[]): JSX.Element[] =>
        conditions?.map((condition: string) => <li key={condition}>{condition}</li>);

    const renderClaimModeContent = (): JSX.Element => {
        if (claimMode === "quote") {
            return (
                <form onSubmit={handleSubmit(claimOfferAction)}>
                    <div className="vendor-offers-modal__claim">
                        <div className="vendor-offers-modal__form-group">
                            <FormItemWrapper
                                label={`Your message to ${sponsor}`}
                                error={errors.message}
                                required
                            >
                                <TextArea
                                    rows={4}
                                    status={errors.message ? "error" : ""}
                                    value={watch("message")}
                                    {...register("message")}
                                    onChange={onFieldChange}
                                />
                            </FormItemWrapper>
                        </div>

                        <div className="vendor-offers-modal__form-group">
                            <FormItemWrapper
                                label="Your phone number"
                                error={errors.phone_number}
                                required
                            >
                                <Input
                                    type="text"
                                    status={errors.phone_number ? "error" : ""}
                                    prefix={<PhoneOutlined />}
                                    value={watch("phone_number")}
                                    {...register("phone_number")}
                                    onChange={onFieldChange}
                                />
                            </FormItemWrapper>
                        </div>
                        {checkForUpcomingRace() && (
                            <div className="vendor-offers-modal__form-group">
                                <FormItemWrapper
                                    label="Race this claim request relates to"
                                    error={errors.race?.root}
                                >
                                    <Controller
                                        name="race"
                                        control={control}
                                        render={({ field: { onChange, value, ...rest } }) => (
                                            <Select
                                                suffixIcon={<ChevronDownOutlined />}
                                                data-hj-allow
                                                status={`${errors.race ? "error" : ""}`}
                                                style={{ width: "100%" }}
                                                options={
                                                    Array.isArray(selectOptions)
                                                        ? selectOptions.map((option) => ({
                                                              label: option.name,
                                                              value: JSON.stringify(option),
                                                          }))
                                                        : undefined
                                                }
                                                onChange={(value) => {
                                                    onChange(
                                                        JSON.parse(value as unknown as string)
                                                    );
                                                }}
                                                value={
                                                    watch("race")
                                                        ? JSON.stringify(watch("race"))
                                                        : null
                                                }
                                                {...rest}
                                            />
                                        )}
                                    />
                                </FormItemWrapper>
                            </div>
                        )}
                    </div>
                </form>
            );
        }
        return (
            <div className="vendor-offers-modal__instructions-mode vendor-offers-modal__claim">
                <Title level={4}>To claim this offer:</Title>
                <div
                    className="vendor-offers-modal__html-content"
                    dangerouslySetInnerHTML={{ __html: claimInstructions }}
                />
            </div>
        );
    };

    // Close modal
    const closeModalHandler = (): void => {
        if (watch("message") || watch("phone_number") || watch("race")) {
            // eslint-disable-next-line no-alert
            const confirm = window.confirm(
                "Are you sure you want to close this modal? Any unsaved changes will be lost."
            );

            if (!confirm) {
                return;
            }
        }

        clearClaimOffer();
        reset();
        closeModal();
    };

    const dialogRef = useRef<HTMLDivElement>(null);

    // scroll to the dialog message upon success or error
    useEffect(() => {
        if (!_isEmpty(success) || !_isEmpty(error)) {
            dialogRef.current?.scrollIntoView();
        }
    }, [success, error]);

    return (
        <Modal
            open={isVisible}
            onCancel={closeModalHandler}
            title={title}
            destroyOnClose
            footer={
                footerVisible ? (
                    <Button
                        className="responsive-cta"
                        type="primary"
                        size="middle"
                        onClick={handleSubmit(claimOfferAction)}
                        loading={isLoading}
                    >
                        {cta || "Claim offer"}
                    </Button>
                ) : null
            }
            width={480}
            centered
        >
            <div ref={dialogRef}>
                {!_isEmpty(error) && (
                    <div className="vendor-offers-modal__dialog">
                        <Dialog type="error" message={error} />
                    </div>
                )}
            </div>
            <div className="vendor-offers-modal">
                <div
                    className="vendor-offers-modal__content"
                    dangerouslySetInnerHTML={{ __html: content }}
                />
                {conditions?.length !== 0 && (
                    <ul className="vendor-offers-modal__conditions">
                        {renderConditionsList(conditions)}
                    </ul>
                )}
                <div className="vendor-offers-modal__form-content">{renderClaimModeContent()}</div>
            </div>
        </Modal>
    );
};

export default VendorOffersModal;
