import { ReactNode } from "react";
import { TooltipPlacement } from "antd/es/tooltip";
import ObscuredWrapper from "app/components/elements/wrappers/obscured_wrapper";
import { useCanAccessBB } from "app/hooks/useCanAccessBudgetBuilder";

const ObscuredBBWrapper = ({
    children,
    placement,
}: {
    children: ReactNode;
    placement?: TooltipPlacement;
}) => {
    const canAccessBB = useCanAccessBB();

    return (
        <ObscuredWrapper placement={placement} condition={canAccessBB}>
            {children}
        </ObscuredWrapper>
    );
};

export default ObscuredBBWrapper;
