/* eslint-disable react/no-unstable-nested-components */
import { TableColumnsType, Tooltip } from "antd";
import { BudgetItemType } from "app/types/budget/budget.types";
import { IUser } from "app/store/types/user.types";
import "app/components/modules/budget/budget_sheet/budget_sheet.scss";
import { formatCurrency } from "app/utils/helpers/format_currency";
import { formatNumber } from "app/utils/helpers/format_number";
import { obscureData } from "app/utils/helpers/obscure_data";
import { getVarianceBgColor } from "../table_utils";
import { HELP_LINKS } from "../constants";
import ObscuredBBWrapper from "app/components/modules/budget/obscured_bb_wrapper/obscured_bb_wrapper";

export const variance_columns = ({
    with: user,
    type,
    canAccessBB,
}: {
    with: Partial<IUser>;
    type?: "revenue" | "cost";
    canAccessBB?: boolean;
}): TableColumnsType<BudgetItemType> => {
    const renderPremiumValue = (val: string) => (canAccessBB ? val : obscureData(val, "currency"));

    return [
        {
            title: " ",
            dataIndex: ["category", "name"],
            key: "name",
            render: (val, record) =>
                record.category?.name === "Other" ? record.name_override || "Other" : val,
        },
        {
            title: () => (
                <Tooltip
                    title={
                        <span>
                            The event this item applies to.{" "}
                            <a
                                className="link-inline"
                                href={HELP_LINKS.varianceView.event}
                                target="learnMoreFrame"
                            >
                                Learn more
                            </a>
                        </span>
                    }
                    placement="top"
                >
                    <span className="tooltip-underline">Event</span>
                </Tooltip>
            ),
            dataIndex: ["event", "name"],
            key: "event",
            ellipsis: true,
            render: (val) => val || "All events",
        },
        {
            title: () => (
                <Tooltip
                    title={
                        <span>
                            The amount you had budgeted for this item.{" "}
                            <a
                                className="link-inline"
                                href={
                                    type === "cost"
                                        ? HELP_LINKS.varianceView.budgetedCost
                                        : HELP_LINKS.varianceView.budgetedRevenue
                                }
                                target="learnMoreFrame"
                            >
                                Learn more
                            </a>
                        </span>
                    }
                    placement="top"
                >
                    <span className="tooltip-underline">Budgeted {type}</span>
                </Tooltip>
            ),
            dataIndex: "total_budg",
            key: "total_budg",
            width: 160,
            align: "right",
            render: (val) => formatCurrency(Math.round(val), user?.location?.ccy, 0),
        },
        {
            title: () => (
                <Tooltip
                    title={
                        <span>
                            The actual amount you ended up spending/earning on this item.{" "}
                            <a
                                className="link-inline"
                                href={
                                    type === "cost"
                                        ? HELP_LINKS.varianceView.actualCost
                                        : HELP_LINKS.varianceView.actualRevenue
                                }
                                target="learnMoreFrame"
                            >
                                Learn more
                            </a>
                        </span>
                    }
                    placement="top"
                >
                    <span className="tooltip-underline">Actual {type}</span>
                </Tooltip>
            ),
            dataIndex: "total_proj",
            key: "total_proj",
            width: 160,
            align: "right",
            render: (val, record) => (
                <ObscuredBBWrapper placement="topRight">
                    {renderPremiumValue(
                        val && record.actualized ? formatCurrency(val, user?.location?.ccy, 0) : "-"
                    )}
                </ObscuredBBWrapper>
            ),
        },
        {
            title: () => (
                <Tooltip
                    title={
                        <span>
                            The amount you ended up going over/under your budget for this item.{" "}
                            <a
                                className="link-inline"
                                href={HELP_LINKS.varianceView.variance}
                                target="learnMoreFrame"
                            >
                                Learn more
                            </a>
                        </span>
                    }
                    placement="top"
                >
                    <span className="tooltip-underline">Variance</span>
                </Tooltip>
            ),
            dataIndex: "variance",
            key: "variance",
            width: 169.5,
            align: "right",
            onCell: (item) => ({
                style: {
                    backgroundColor:
                        item?.variance_pct && canAccessBB
                            ? getVarianceBgColor(item.variance_pct)
                            : "",
                    color: "#191A19",
                },
            }),
            render: (val) => (
                <ObscuredBBWrapper>
                    {renderPremiumValue(
                        typeof val === "number"
                            ? (val > 0 ? "+" : "") +
                                  formatCurrency(Math.round(val), user?.location?.ccy, 0)
                            : "-"
                    )}
                </ObscuredBBWrapper>
            ),
        },
        {
            title: () => (
                <Tooltip
                    title={
                        <span>
                            The percent amount you ended up going over/under your budget for this
                            item.{" "}
                            <a
                                className="link-inline"
                                href={HELP_LINKS.varianceView.percentageVariance}
                                target="learnMoreFrame"
                            >
                                Learn more
                            </a>
                        </span>
                    }
                    placement="top"
                >
                    <span className="tooltip-underline">% Variance</span>
                </Tooltip>
            ),
            dataIndex: "variance_pct",
            key: "variance_pct",
            width: 169.5,
            align: "right",
            onCell: (item) => ({
                style: {
                    backgroundColor:
                        item?.variance_pct && canAccessBB
                            ? getVarianceBgColor(item.variance_pct)
                            : "",
                    color: "#191A19",
                    paddingRight: 16,
                },
            }),
            render: (val, record) => (
                <ObscuredBBWrapper>
                    {renderPremiumValue(
                        val
                            ? (record.variance && record.variance > 0 && !Number.isNaN(+val)
                                  ? "+"
                                  : "") + val
                            : "-"
                    )}
                </ObscuredBBWrapper>
            ),
        },
    ];
};
