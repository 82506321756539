/* eslint-disable import/no-extraneous-dependencies */
import { produce } from "immer";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import API from "app/utils/api/axios";
import { RaceImportType } from "app/types/races/race.types";
import API_URL from "app/constants/api_urls";
import { CurrentRaceType } from "./races.types";

export interface IRacesSlice {
    races: {
        upcoming: {
            page: number;
            location: string;
            currentPage: number;
        };

        past: {
            page: number;
            location: string;
            currentPage: number;
        };
    };
    importedRace: {
        isLoading: boolean;
        error: string;
        race: Partial<RaceImportType> | undefined;
    };
    currentRace: Partial<CurrentRaceType>;
    updatePageNumber: (type: "upcoming" | "past", newPage: number) => void;
    setCurrentRace: (race: Partial<CurrentRaceType>) => void;
    importAsyncRace: (payload: { url: string; fromPageImport?: boolean }) => any;
    clearImportedRace: () => void;
    displayImportRaceError: (error: string) => void;
    clearRaceImportError: () => void;
    flushRacesStore: () => void;
}

const initialState = {
    races: {
        upcoming: {
            page: 1,
            location: "",
            currentPage: 1,
        },
        past: {
            page: 1,
            location: "",
            currentPage: 1,
        },
    },
    currentRace: {},
    importedRace: {
        isLoading: false,
        error: "",
        race: {},
    },
};

const racesSlice = create<IRacesSlice>()(
    devtools(
        (set) => ({
            ...initialState,
            updatePageNumber: (type: "upcoming" | "past", newPage: number) => {
                set(
                    produce((state: IRacesSlice) => {
                        state.races[type].currentPage = newPage;
                    }),
                    false,
                    "racesSlice/updatePageNumber"
                );
            },
            setCurrentRace: (race: Partial<CurrentRaceType>) => {
                set(
                    produce((state: IRacesSlice) => {
                        state.currentRace = race;
                    }),
                    false,
                    "racesSlice/setCurrentRace"
                );
            },
            importAsyncRace: async (payload: { url: string; fromPageImport?: boolean }) => {
                const { url, fromPageImport } = payload;

                try {
                    set(
                        produce((state: IRacesSlice) => {
                            state.importedRace.isLoading = true;
                        }),
                        false,
                        "racesSlice/importAsyncRace"
                    );

                    const { data }: any = await API.get<RaceImportType>(`${API_URL.IMPORT_RACE}`, {
                        params: { url },
                    });

                    if (Reflect.has(data, "non_field_errors")) {
                        return set(
                            produce((state: IRacesSlice) => {
                                state.importedRace.error = data?.non_field_errors;
                                state.importedRace.isLoading = false;
                            }),
                            false,
                            "racesSlice/importAsyncRace"
                        );
                    }

                    const race = {
                        ...data,
                        events:
                            data.events &&
                            data.events.map((ev: any) => ({
                                ...ev,
                                entry_fee: ev.entry_fee || "",
                            })),
                    };

                    set(
                        produce((state: IRacesSlice) => {
                            state.importedRace.race = race;
                            state.importedRace.isLoading = false;
                            state.importedRace.error = "";
                        }),
                        false,
                        "racesSlice/importAsyncRace"
                    );

                    return race;
                } catch (error) {
                    set(
                        produce((state: IRacesSlice) => {
                            state.importedRace.isLoading = false;
                        }),
                        false,
                        "racesSlice/importAsyncRace"
                    );

                    if (error.response?.status === 409) return error.response.data.pk;

                    return set(
                        produce((state: IRacesSlice) => {
                            state.importedRace.error = fromPageImport
                                ? ""
                                : error.response.data.non_field_errors;
                            state.importedRace.isLoading = false;
                        }),
                        false,
                        "racesSlice/importAsyncRace"
                    );
                }
            },
            clearImportedRace: (): void => {
                set(
                    produce((state: IRacesSlice) => {
                        state.importedRace.race = {};
                    })
                );
            },
            displayImportRaceError: (error: string) => {
                set(
                    produce((state: IRacesSlice) => {
                        state.importedRace.error = error;
                        state.importedRace.isLoading = false;
                    }),
                    false,
                    "racesSlice/displayImportRaceError"
                );
            },
            clearRaceImportError: () => {
                set(
                    produce((state: IRacesSlice) => {
                        state.importedRace.error = "";
                        state.importedRace.isLoading = false;
                    }),
                    false,
                    "racesSlice/clearRaceImportError"
                );
            },
            flushRacesStore: () => {
                set(
                    produce((state: IRacesSlice) => {
                        state.races = initialState.races;
                        state.currentRace = initialState.currentRace;
                    }),
                    false,
                    "racesSlice/flushRacesStore"
                );
            },
        }),
        { name: "Races slice", enabled: !import.meta.env.PROD }
    )
);

export default racesSlice;
