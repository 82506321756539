import { ReactElement, useState, useMemo, useEffect, useCallback } from "react";
import { Typography, Switch } from "antd";
import { useParams } from "react-router-dom";
import { DocumentType } from "app/types/documents/document.types";
import { useGetDocuments } from "app/utils/api/queries/documents.query";
import PageWithLoader from "app/hoc/page_with_loader/page_with_loader";
import DocumentCard from "app/components/elements/cards/document_card/document_card";
import userSlice from "app/store/user/user.slice";
import "app/views/protected/plan/documents/documents.scss";

const Documents = (): ReactElement => {
    const params = useParams();
    const { data, isLoading, isError } = useGetDocuments();
    const { user } = userSlice((state) => state);
    const [isChecked, setIsChecked] = useState<boolean>(true);
    const documentFromParams = data?.find((document) => document.pk === +(params.pk ?? ""));

    const isCountryMatching = useCallback(
        (document: DocumentType) =>
            document.countries.some((country) => country.name === user?.location?.name),
        [user?.location?.name]
    );

    const totalDocumentsLength: number | undefined = data?.length;
    const regionalDocumentsLength = data?.filter(isCountryMatching)?.length;

    const prepareDocuments = useMemo(
        () =>
            isChecked
                ? data?.filter((document: DocumentType) => isCountryMatching(document))
                : data,
        [isChecked, data, isCountryMatching]
    );

    // Check if document's country is matching user's location and set isChecked to false if it is not
    useEffect(() => {
        if (params.pk && documentFromParams) {
            if (!isCountryMatching(documentFromParams)) setIsChecked(false);
        }
    }, [documentFromParams, isCountryMatching, params.pk]);

    const renderDocument = (): JSX.Element[] | JSX.Element | null => {
        if (prepareDocuments && prepareDocuments.length > 0) {
            const groupedDocuments = prepareDocuments.reduce(
                (acc: { [key: string]: DocumentType[] }, item: DocumentType) => {
                    acc[item.category.name] = acc[item.category.name] || [];
                    acc[item.category.name].push(item);
                    return acc;
                },
                {}
            );

            return Object.keys(groupedDocuments).map((groupTitle: string) => (
                <div
                    className={`documents-templates__group ${
                        params.category === groupTitle.toLocaleLowerCase().split(" ").join("-")
                            ? "isActive"
                            : ""
                    }`}
                    key={groupTitle}
                >
                    <div
                        className="documents-templates__heading-wrap"
                        ref={
                            params.category === groupTitle.toLocaleLowerCase().split(" ").join("-")
                                ? (el) => {
                                      if (el)
                                          el.scrollIntoView({
                                              block: "start",
                                              behavior: "smooth",
                                          });
                                  }
                                : null
                        }
                    >
                        <Typography.Title level={4}>
                            <span>{groupTitle}</span>
                        </Typography.Title>
                    </div>
                    <div className="documents-templates__group-wrap">
                        {groupedDocuments[groupTitle].map((document: DocumentType) => (
                            <DocumentCard
                                key={document.pk}
                                pk={document.pk}
                                title={document.title}
                                description={document.description}
                                download_link={document.download_link}
                                format={document.format}
                                post={document.post}
                                format_display={document.format_display}
                                category={document.category}
                                publisher={document.publisher}
                                countries={document.countries}
                            />
                        ))}
                    </div>
                </div>
            ));
        }

        return null;
    };

    return (
        <PageWithLoader isLoading={isLoading} error={isError}>
            <div className="documents-templates" />
            <div className="documents-templates__heading page__header">
                <div>
                    <Typography.Title level={1}>Documents & Templates</Typography.Title>
                    <Typography.Text>
                        A list of event planning documents and templates.
                    </Typography.Text>
                </div>
            </div>
            <div className="page__toolbar">
                <div className="documents-templates__numbers-info">
                    {isChecked ? (
                        <Typography.Text strong>
                            <strong>{regionalDocumentsLength}</strong> documents in your region
                        </Typography.Text>
                    ) : (
                        <Typography.Text strong>
                            <strong>{totalDocumentsLength}</strong> documents
                        </Typography.Text>
                    )}
                </div>
                <div className="documents-templates__switch">
                    <Typography.Text>Show {user?.location?.adjective} docs only</Typography.Text>
                    <Switch
                        size="small"
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                    />
                </div>
            </div>
            <div className="documents-templates__container">{renderDocument()}</div>
        </PageWithLoader>
    );
};
export default Documents;
