import { useEffect, useCallback } from "react";
import userSlice from "../store/user/user.slice";

const useUserLocations = () => {
    const { userLocations, getUserLocations } = userSlice((state) => state);

    const getUserLocationsWithCallback = useCallback(() => getUserLocations(), []);

    useEffect(() => {
        getUserLocationsWithCallback();
    }, [getUserLocationsWithCallback]);

    return { userLocations };
};

export default useUserLocations;
