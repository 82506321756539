/* eslint-disable react/no-danger */
import { ReactElement, useEffect, useState } from "react";
import { message, Modal } from "antd";
import {
    BudgetItemFormModes,
    BudgetItemType,
    ExtendBudgetItemType,
} from "app/types/budget/budget.types";
import { useGetBudget, useGetBudgetFormData } from "app/utils/api/queries/budget.query";
import { budgetItemValidationSchema } from "app/lib/validation_schemas/budget_item_validation.schema";
import { useUpdateBudgetItem } from "app/utils/api/mutations/budget.mutation";
import { _isEmpty } from "app/utils/helpers";
import "app/components/modules/budget/add_edit_budget/add_edit_budget_item.scss";
import racesSlice from "app/store/races/races.slice";
import { useCanAccessBB } from "app/hooks/useCanAccessBudgetBuilder";
import userSlice from "app/store/user/user.slice";
import { useForm } from "react-hook-form";
import { ErrorMessageReturnType, formatErrorMessages } from "app/utils/helpers/error_formatter";
import { tryCatch } from "app/utils/helpers/functional_utilities";
import { yupResolver } from "@hookform/resolvers/yup";
import { ItemActions, ItemActionsProvider } from "./item_actions";
import { ItemFields, ItemFieldsProvider } from "./item_fields";

const AddEditBudgetItemModal = ({
    item,
    isVisible,
    closeModalHandler,
    formMode,
    type,
}: {
    item:
        | Partial<
              BudgetItemType & {
                  quantity_override: number | null;
              }
          >
        | undefined;
    isVisible: boolean;
    formMode: "add" | "edit";
    type: "cost" | "revenue";
    closeModalHandler: () => void;
}): ReactElement => {
    const { currentRace } = racesSlice((state) => state);
    const { data: budget } = useGetBudget(currentRace.pk!);
    const { data: formData } = useGetBudgetFormData();
    const [addMode, setAddMode] = useState<"search" | "select">("search");
    const { user } = userSlice();

    const {
        mutateAsync: updateBudgetItem,
        isPending: isLoading,
        isSuccess,
        status,
        variables,
        reset: resetMutation,
    } = useUpdateBudgetItem({
        onSuccess: () => {
            closeModalHandler();
            setAddMode("search");
        },

        onError: (error) => {
            if (error.status === 500)
                message.error(error?.response?.data?.message || "Something went wrong");
        },
    });

    const { mutate: removeBudgetItem, isPending: isRemoving } = useUpdateBudgetItem({
        onSuccess: () => {
            closeModalHandler();
            setAddMode("search");
        },

        onError: (error) => {
            message.error(error?.response?.data?.message || "Something went wrong");
        },
    });

    const mode: BudgetItemFormModes = {
        isSearchMode: formMode === "add" && addMode === "search",
        isSelectMode: formMode === "add" && addMode === "select",
        isAddMode: formMode === "add",
        isEditMode: formMode === "edit",
    };

    const handleRemoveItem = async (item: BudgetItemType) => {
        await removeBudgetItem({
            racePk: currentRace.pk!,
            item: { pk: item.pk },
        });
    };

    const canAccessBB = useCanAccessBB();

    const formContext = useForm<ExtendBudgetItemType>({
        resolver: yupResolver<any>(budgetItemValidationSchema),
        mode: "onTouched",
        reValidateMode: "onChange",
        defaultValues: {
            virtual_type: type,
            virtual_group: "",
            virtual_search_key: "",
        },
    });

    const {
        handleSubmit,
        watch,
        setError,
        formState: { errors },
        reset,
    } = formContext;

    const submitBudgetItem = async (values: ExtendBudgetItemType) => {
        const updatedData = Object.keys(values).reduce((acc, key) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (item && values[key] !== item[key]) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                acc[key] = values[key];
            }

            return acc;
        }, {} as ExtendBudgetItemType);

        if (!_isEmpty(updatedData)) {
            const [error, result] = await tryCatch(updateBudgetItem)({
                racePk: currentRace.pk!,
                item: {
                    ...updatedData,
                    pk: item?.pk,
                    event: updatedData.event?.pk ? { pk: updatedData.event.pk } : null,
                    category: updatedData.category ? { pk: updatedData.category?.pk } : undefined,
                },
            });

            if (error) {
                if (error?.fieldErrors) {
                    const formattedErrors = formatErrorMessages<keyof BudgetItemType>(
                        error.fieldErrors.items[0]
                    );
                    formattedErrors.forEach(
                        ({ name, type, message }: ErrorMessageReturnType<keyof BudgetItemType>) =>
                            setError(name, { type, message })
                    );
                }
            }
        }
    };

    // Auto populate form data
    useEffect(() => {
        if (item) {
            reset({
                ...item,
                virtual_type: item?.category?.type || type,
                virtual_group: item?.category?.group || "",
                virtual_search_key: "",
            });
        }
    }, [item, reset, type]);

    return (
        <Modal
            open={isVisible}
            onCancel={() => {
                setAddMode("search");
                closeModalHandler();
            }}
            title={`${mode.isEditMode ? item?.category?.name : `Add new ${type} item`}`}
            width={480}
            className="add-edit-budget__modal"
            destroyOnClose
            footer={null}
            centered
            afterClose={() => {
                if (isSuccess) {
                    if (mode.isEditMode)
                        message.success(
                            `${budget?.items.find((i) => i.pk === variables.item.pk)?.category?.name} item updated successfully`
                        );

                    if (mode.isAddMode)
                        message.success(
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            `${variables.item.virtual_search_key.name} item added successfully`
                        );

                    resetMutation();
                }
            }}
        >
            <ItemActionsProvider
                isLoading={isLoading}
                isRemoving={isRemoving}
                handleRemoveItem={handleRemoveItem}
                item={item}
                formContext={formContext}
                setAddMode={setAddMode}
                type={type}
            >
                <ItemFieldsProvider
                    budget={budget}
                    formContext={formContext}
                    item={item}
                    canAccessBB={canAccessBB}
                    mode={mode}
                    type={type}
                    formData={formData}
                    user={user}
                >
                    <form
                        className="add-edit-budget__form"
                        onSubmit={handleSubmit(submitBudgetItem)}
                    >
                        {mode.isSearchMode && (
                            <>
                                <ItemFields.SearchField />

                                <div className="ant-modal-footer">
                                    <ItemActions.AddOtherItem />
                                    <ItemActions.AddItem />
                                </div>
                            </>
                        )}

                        {(mode.isSelectMode || mode.isEditMode) && (
                            <>
                                <ItemFields.VirtualGroupField />

                                <ItemFields.ItemTypeField />

                                {watch("category")?.name === "Other" && (
                                    <ItemFields.ItemNameField />
                                )}

                                <div className="add-edit-budget__row">
                                    {(!watch("category") || watch("category")?.items_editable) && (
                                        <>
                                            <ItemFields.BudgetedPriceField />

                                            <ItemFields.ActualPriceField />
                                        </>
                                    )}
                                </div>

                                {(!watch("category") || watch("category")?.items_editable) && (
                                    <ItemFields.QuantityTypeField />
                                )}

                                {watch("quantity_type") === "custom" ? (
                                    <ItemFields.QuantityOverrideField />
                                ) : (
                                    <ItemFields.EventField />
                                )}

                                <div>
                                    <ItemActions.Save />
                                    {mode.isEditMode && <ItemActions.Delete />}
                                </div>
                            </>
                        )}
                    </form>
                </ItemFieldsProvider>
            </ItemActionsProvider>
        </Modal>
    );
};

export default AddEditBudgetItemModal;
