import React, { ReactNode, ReactElement, useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";
import userSlice from "app/store/user/user.slice";
import Storage from "app/utils/storage/local";
import { useClearQueryParam } from "app/hooks/useMutateQuery";
import URL from "app/constants/route_urls";
import { _isEmpty } from "app/utils/helpers";

const ProtectedRoute = ({
    children,
    title,
}: {
    children: ReactNode;
    title?: string;
}): ReactElement | null => {
    const { isAuthenticated, user } = userSlice((state) => state);
    const token = Storage.get("access_token");
    const location = useLocation();
    const query = new URLSearchParams(location?.search);
    const redirectTo = query.get("redirectTo");
    const clearRedirectTo = useClearQueryParam("redirectTo");

    useEffect(() => {
        if (title) document.title = title;
    }, [location, title]);

    if (isAuthenticated && !_isEmpty(user) && !user?.location_confirmed) {
        return (
            <Navigate
                to={URL.LOCATION_CONFIRM}
                state={{ from: location.pathname, search: location.search }}
            />
        );
    }

    if (isAuthenticated || token) {
        if (redirectTo) clearRedirectTo();
        return React.isValidElement(children) ? children : null;
    }

    return (
        <Navigate
            to={redirectTo ? `/${redirectTo}` : URL.LOGIN}
            state={{ from: location.pathname, search: location.search }}
            replace
        />
    );
};

export default ProtectedRoute;
