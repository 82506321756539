import { ReactElement, useState } from "react";
import { Button, Typography } from "antd";
import { useParams } from "react-router-dom";
import { DocumentType } from "app/types/documents/document.types";
import API from "app/utils/api/axios";
import "app/components/elements/cards/document_card/document_card.scss";

const { Title } = Typography;

const DocumentCard = ({
    pk,
    description,
    download_link,
    format,
    post,
    title,
    format_display,
    publisher,
}: DocumentType): ReactElement => {
    const [imageLoaded, setImageLoaded] = useState<boolean>(false);
    const params = useParams();
    const pkFromParams = params.pk ? +params.pk : undefined;
    const [scrolledToActive, setScrolledToActive] = useState<boolean>(false);

    const asyncSponsorsTracking = async (): Promise<void> => {
        await API.get(`/api/documents/${pk}/open/`);
    };

    return (
        <div
            className={`documents-templates-card ${pkFromParams === pk ? "isActive" : ""}`}
            ref={
                pkFromParams === pk
                    ? (el) => {
                          if (el && !scrolledToActive)
                              el.scrollIntoView({
                                  block: "center",
                                  behavior: "smooth",
                              });
                          setScrolledToActive(true);
                      }
                    : null
            }
        >
            <div className="documents-templates-card__top">
                <div className="documents-templates-card__sub">
                    <Typography.Text
                        className={`documents-templates-card__publisher ${
                            !publisher?.name ? "documents-templates-card__publisher--disabled" : ""
                        }`}
                    >
                        {publisher?.name || "N/A"}
                    </Typography.Text>
                </div>

                <div className="documents-templates-card__type">
                    {format && (
                        <img
                            className={`load-image ${imageLoaded && "image-loaded"}`}
                            src={format && `/assets/documents/${format}.png`}
                            onLoad={() => setImageLoaded(true)}
                            alt="document"
                        />
                    )}
                    {format_display}
                </div>
            </div>
            <div className="documents-templates-card__info">
                <div className="documents-templates-card__heading">
                    <Title level={3}>{title}</Title>
                </div>
                <Typography.Text>{description}</Typography.Text>
            </div>
            <div className="documents-templates-card__cta">
                <Button
                    className="ant-btn-primary-outline"
                    type="default"
                    onClick={() => {
                        asyncSponsorsTracking();
                        const anchor = Object.assign(document.createElement("a") as HTMLElement, {
                            target: "_blank",
                            href: `${download_link}`,
                            rel: "noopener noreferrer",
                            download: true,
                        });
                        anchor.click();
                    }}
                >
                    Download
                </Button>
                {post?.url && (
                    <Typography.Text
                        className="link documents-templates-card__link"
                        role="presentation"
                        onClick={() => {
                            const anchor = Object.assign(
                                document.createElement("a") as HTMLElement,
                                {
                                    target: "_blank",
                                    href: `${post.url}`,
                                    rel: "noreferrer",
                                }
                            );
                            anchor.click();
                        }}
                    >
                        Related post
                    </Typography.Text>
                )}
            </div>
        </div>
    );
};

export default DocumentCard;
