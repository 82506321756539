import { Button, Dropdown, InputNumber, Tooltip, Typography } from "antd";
import { DoubleArrowUpOutlined } from "assets/icons/icons";
import { addDays, format, isAfter, isBefore, isSameDay, parseISO, subDays } from "date-fns";
import { BudgetEventInfoType } from "app/types/budget/budget.types";
import userSlice from "app/store/user/user.slice";
import MISC_CONSTANTS from "app/constants/misc";
import { BinOutlined, DatepickerOutlined, DotsOutlined, ThinPlusOutlined } from "assets";
import ProTooltip from "app/components/elements/wrappers/pro_tooltip";
import User from "app/utils/user";
import { IUser } from "app/store/types/user.types";
import { useCanAccessBB } from "app/hooks/useCanAccessBudgetBuilder";
import { Controller, UseFieldArrayInsert, UseFormReturn, UseFormSetValue } from "react-hook-form";
import { _isEmpty, DateUtils } from "app/utils/helpers";
import DatePicker from "app/components/elements/form/datepicker";
import FormItemWrapper from "app/components/elements/form/form_item_wrapper";
import { useEffect } from "react";

const EventNode = ({
    node,
    index,
    setValue,
    remove,
    insert,
    formContext,
}: {
    node: BudgetEventInfoType["nodes"][0];
    index: number;
    setValue: UseFormSetValue<BudgetEventInfoType>;
    remove: (index: number) => void;
    insert: UseFieldArrayInsert<BudgetEventInfoType, "nodes">;
    formContext: UseFormReturn<BudgetEventInfoType, any, undefined>;
}) => {
    const {
        control,
        watch,
        getValues,
        formState: { errors },
    } = formContext;
    const allNodes = watch("nodes");

    const { user } = userSlice((state) => state);

    const canAccessBB = useCanAccessBB();

    const startDate = allNodes[index + 1] ? addDays(new Date(allNodes[index + 1].date), 1) : null;
    const defaultNextEndDate = format(subDays(new Date(allNodes[index].date), 1), "yyyy-MM-dd");

    const formattedStartDate = startDate ? format(startDate, MISC_CONSTANTS.dateFormat) : "";
    const formattedEndDate = format(new Date(node?.date), MISC_CONSTANTS.dateFormat);

    const isFirst = index === 0;
    const isLast = index === allNodes.length - 1;

    const nextDate = new Date(allNodes[index + 1]?.date);
    const prevDate = new Date(allNodes[index - 1]?.date);

    const disabledDate = (current: Date) => {
        if (current) {
            return isSameDay(current, prevDate) || isAfter(current, prevDate);
        }

        return true;
    };

    const StartDateElement = (
        <Tooltip
            title="The event start date generates automatically according to your end periods"
            getPopupContainer={(triggerNode) => triggerNode.offsetParent as HTMLElement}
        >
            <span className="edit-event-modal__date-readonly">{formattedStartDate}</span>
        </Tooltip>
    );

    const EndDateElement = (
        <Tooltip
            title="This date is set to race date and cannot be changed."
            getPopupContainer={(triggerNode) => triggerNode.offsetParent as HTMLElement}
        >
            <span className="edit-event-modal__date-readonly edit-event-modal__date-readonly--end-date">
                {formattedEndDate}
            </span>
        </Tooltip>
    );

    const SelectDateField = (
        <Controller
            control={control}
            name={`nodes.${index}.date`}
            render={({ field: { onChange, value, name, ...rest } }) => {
                const date = watch("nodes")[index]?.date;

                return (
                    <DatePicker
                        value={date ? parseISO(date) : undefined}
                        style={{ width: "100%" }}
                        suffixIcon={null}
                        disabledDate={disabledDate}
                        format={
                            user?.location?.language === "en-us"
                                ? MISC_CONSTANTS.dateFormat
                                : "D MMMM YYYY"
                        }
                        status={errors?.nodes?.[index]?.date ? "error" : ""}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }}
                        onChange={(newDate, dateString) => {
                            if (_isEmpty(dateString)) {
                                setValue(name, "");
                                return;
                            }
                            if (typeof dateString === "string") {
                                setValue(name, DateUtils.formatDateRegex(dateString));
                            }
                        }}
                        getPopupContainer={(triggerNode) => triggerNode.offsetParent as HTMLElement}
                        {...rest}
                    />
                );
            }}
        />
    );

    const CalendarIcon = <DatepickerOutlined className="edit-event-modal__date-icon" />;

    const ChevronIcon = <DoubleArrowUpOutlined className="edit-event-modal__chevron-icon" />;

    const renderPeriodField = () => {
        // Is the only node -> Show up to last date (uneditable)
        // Up to + last date disabled
        if (isFirst && isLast) {
            return (
                <div className="edit-event-modal__date-field">
                    {CalendarIcon}
                    <span className="edit-event-modal__date-dimmed">Dates up to</span>
                    {EndDateElement}
                </div>
            );
        }

        // Is the first node but not the only one -> Show first date + last date (uneditable)
        // first date + last date disabled
        if (isFirst && !isLast) {
            return (
                <div className="edit-event-modal__date-field">
                    {CalendarIcon}
                    {StartDateElement}
                    {ChevronIcon}
                    {EndDateElement}
                </div>
            );
        }

        // Is the last node but not the only one -> Show up to last date (editable)
        // Up to + last date enabled
        if (!isFirst && isLast) {
            return (
                <div className="edit-event-modal__date-field">
                    {CalendarIcon}
                    <span className="edit-event-modal__date-dimmed edit-event-modal__date-dimmed--last ">
                        Dates up to
                    </span>

                    <span className="hidden">{ChevronIcon}</span>

                    {SelectDateField}
                </div>
            );
        }

        // Is any node but the first and last one -> show start date and last date (ediable)
        return (
            <div className="edit-event-modal__date-field">
                {CalendarIcon}
                {StartDateElement}
                {ChevronIcon}
                {SelectDateField}
            </div>
        );
    };

    const handleDeleteNode = () => {
        const newPrevNodeParticipantsProj =
            node.participants_proj + allNodes[index - 1].participants_proj;

        const newPrevNodeParticipantsCurr =
            node.participants_curr + allNodes[index - 1].participants_curr;

        remove(index);
        setValue(`nodes.${index - 1}.participants_proj`, newPrevNodeParticipantsProj);
        setValue(`nodes.${index - 1}.participants_curr`, newPrevNodeParticipantsCurr);
    };

    // Automatically update every subsequent date to be at least one date before the previous date
    useEffect(() => {
        const subscription = watch((value) => {
            const allNodes = value.nodes as BudgetEventInfoType["nodes"];
            let i = 0;

            while (allNodes[i + 1]) {
                if (!isBefore(new Date(allNodes[i + 1].date), new Date(allNodes[i].date))) {
                    setValue(
                        `nodes.${i + 1}.date`,
                        format(
                            subDays(new Date(allNodes[i].date), 1),
                            MISC_CONSTANTS.fieldDateFormat
                        )
                    );
                }
                i += 1;
            }
        });

        return () => subscription.unsubscribe();
    }, [setValue, watch]);

    const checkRegParticipantsDisabled = () => {
        // startDate is in the past
        if (startDate && isAfter(new Date(), startDate)) return false;

        // no startDate (dates up to)
        if (!startDate) return false;

        // startDate in the past and endDate in the future
        if (isAfter(startDate, new Date()) && isBefore(new Date(node.date), new Date()))
            return false;

        // startDate in the future
        if (isBefore(startDate, new Date())) return false;

        return true;
    };

    const regParticipantsDisabled = checkRegParticipantsDisabled();

    return (
        <div className="edit-event-modal__node">
            <div className="edit-event-modal__period">
                {index === 0 && (
                    <Tooltip
                        title="The start and end date for a given registration period."
                        placement="topLeft"
                    >
                        <Typography.Text className="ant-label" strong>
                            <span className="tooltip-underline">Period</span>{" "}
                            <span className="ant-label-required">*</span>
                        </Typography.Text>
                    </Tooltip>
                )}

                <div className="edit-event-modal__date-field-wrapper">{renderPeriodField()}</div>
            </div>

            <FormItemWrapper
                label={index === 0 ? "Entry fee" : undefined}
                required
                tooltipText="The entry fee for a given registration period."
                tooltipPlacement="top"
            >
                <Controller
                    name={`nodes.${index}.entry_fee`}
                    control={control}
                    render={({ field: { onChange, ...rest } }) => (
                        <InputNumber
                            controls={false}
                            status={errors?.nodes?.[index]?.entry_fee ? "error" : ""}
                            style={{ width: "100%" }}
                            prefix={user?.location?.ccy_symbol}
                            min="0"
                            className={`eventinputs-entry-fee eventinput edit-event-modal__number-field ${
                                errors?.nodes?.[index]?.hasOwnProperty("entry_fee")
                                    ? "hasError"
                                    : ""
                            }`}
                            onChange={(valNum) => {
                                if (valNum === null) return;

                                onChange(valNum);
                            }}
                            {...rest}
                        />
                    )}
                />
            </FormItemWrapper>

            <ProTooltip
                customText="Tracking of registered participants is only available to Pro members."
                custom_cta={null}
                condition={canAccessBB}
            >
                <div>
                    <FormItemWrapper
                        label={index === 0 ? "Reg. participants" : undefined}
                        required
                        tooltipText="The number of people currently registered in a given registration period."
                        tooltipPlacement="top"
                    >
                        <Controller
                            name={`nodes.${index}.participants_curr`}
                            control={control}
                            render={({ field: { onChange, ...rest } }) => (
                                <InputNumber
                                    controls={false}
                                    status={
                                        errors?.nodes?.[index]?.participants_curr ? "error" : ""
                                    }
                                    style={{ width: "100%" }}
                                    min={0}
                                    className="edit-event-modal__number-field"
                                    onChange={(valNum) => {
                                        if (valNum === null) return;

                                        onChange(valNum);
                                    }}
                                    disabled={regParticipantsDisabled || !canAccessBB}
                                    {...rest}
                                />
                            )}
                        />
                    </FormItemWrapper>
                </div>
            </ProTooltip>

            <FormItemWrapper
                label={index === 0 ? "Proj. participants" : undefined}
                required
                tooltipText="The number of people expected to have registered by the end of a given pricing period."
                tooltipPlacement="top"
                error={errors.participants_proj}
            >
                <Controller
                    name={`nodes.${index}.participants_proj`}
                    control={control}
                    render={({ field: { onChange, ...rest } }) => (
                        <InputNumber
                            controls={false}
                            status={errors?.nodes?.[index]?.participants_proj ? "error" : ""}
                            style={{ width: "100%" }}
                            min={1}
                            className="edit-event-modal__number-field"
                            onChange={(valNum) => {
                                if (valNum === null) return;

                                onChange(valNum);
                            }}
                            {...rest}
                        />
                    )}
                />
            </FormItemWrapper>

            <Dropdown
                menu={{
                    items: [
                        {
                            key: "1",
                            label: (
                                <ProTooltip
                                    customText="Multiple pricing periods are only available to Pro members."
                                    custom_cta={null}
                                    condition={canAccessBB}
                                >
                                    <div className="edit-event-modal__dots-dropdown-item">
                                        <ThinPlusOutlined />
                                        Add period
                                    </div>
                                </ProTooltip>
                            ),
                            disabled: !canAccessBB,
                            onClick: () => {
                                if (canAccessBB) {
                                    insert(index + 1, {
                                        date: defaultNextEndDate,
                                        temp_pk: Math.random(),
                                        participants_curr: allNodes[index].participants_curr,
                                        participants_proj: allNodes[index].participants_proj,
                                        entry_fee: allNodes[index].entry_fee,
                                    });

                                    setValue(`nodes.${index}.participants_curr`, 0);
                                    setValue(`nodes.${index}.participants_proj`, 0);
                                }
                            },
                        },
                        {
                            key: "2",
                            label: (
                                <div className="edit-event-modal__dots-dropdown-item edit-event-modal__dots-dropdown-item--delete">
                                    <BinOutlined />
                                    Delete period
                                </div>
                            ),
                            className: isFirst ? "d-none" : "",
                            onClick: handleDeleteNode,
                        },
                    ],
                }}
                placement="bottomLeft"
                trigger={["click"]}
                autoAdjustOverflow={false}
                getPopupContainer={(triggerNode) => triggerNode.offsetParent as HTMLElement}
                rootClassName="edit-event-modal__dots-dropdown"
            >
                <Button className="edit-event-modal__dots-btn" disabled={isFirst && !isLast}>
                    <DotsOutlined />
                </Button>
            </Dropdown>
        </div>
    );
};

export default EventNode;
