/* eslint-disable react/no-unstable-nested-components */
import { Progress, Table, TableColumnsType, Tooltip } from "antd";
import { useMemo, useState } from "react";
import { BudgetEventInfoType } from "app/types/budget/budget.types";
import { useGetBudget } from "app/utils/api/queries/budget.query";
import { formatCurrency } from "app/utils/helpers/format_currency";
import { formatNumber } from "app/utils/helpers/format_number";
import userSlice from "app/store/user/user.slice";
import racesSlice from "app/store/races/races.slice";
import "app/components/modules/budget/event_info_table/event_info.scss";
import User from "app/utils/user";
import { IUser } from "app/store/types/user.types";
import { useCanAccessBB } from "app/hooks/useCanAccessBudgetBuilder";
import { obscureData } from "app/utils/helpers/obscure_data";
import EditEventModal from "../edit_event_modal/edit_event_modal";
import { HELP_LINKS } from "../constants";
import ObscuredBBWrapper from "app/components/modules/budget/obscured_bb_wrapper/obscured_bb_wrapper";

const EventInfoTable = () => {
    const { currentRace } = racesSlice((state) => state);
    const { data: budget } = useGetBudget(currentRace.pk!);
    const { user } = userSlice((state) => state);
    const UserModule = User(user as IUser);

    const canAccessBB = useCanAccessBB();

    const [editingEventPk, setEditingEventPk] = useState<number | null>(null);

    const editingEvent = budget?.events.find((e) => e.pk === editingEventPk);

    const renderPremiumValue = (val: string) => (canAccessBB ? val : obscureData(val, "currency"));

    const columns: TableColumnsType<BudgetEventInfoType> = [
        {
            title: "Event name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: () => (
                <Tooltip
                    title={
                        <span>
                            The weighted average entry fee for the event.{" "}
                            <a
                                className="link-inline"
                                href={HELP_LINKS.eventSummaryTable.entryFee}
                                target="learnMoreFrame"
                            >
                                Learn more
                            </a>
                        </span>
                    }
                    placement="top"
                >
                    <span className="tooltip-underline">Entry fee</span>
                </Tooltip>
            ),
            align: "right",
            width: 100,
            dataIndex: "avg_entry_fee",
            key: "avg_entry_fee",
            render: (val, record) => formatCurrency(val, user?.location?.ccy, 0),
        },
        {
            title: () => (
                <Tooltip
                    title={
                        <span>
                            The number of people currently registered for the event.{" "}
                            <a
                                className="link-inline"
                                href={HELP_LINKS.eventSummaryTable.regParticipants}
                                target="learnMoreFrame"
                            >
                                Learn more
                            </a>
                        </span>
                    }
                    placement="top"
                >
                    <span className="tooltip-underline">Reg. participants</span>
                </Tooltip>
            ),
            align: "right",
            className: "budget-sheet__current-cell-wrap budget-sheet__border-left-cell",
            width: 150,
            key: "participants_curr",
            dataIndex: "participants_curr",
            render: (val, record) => (
                <div className="budget-event-table__flex">
                    <ObscuredBBWrapper>{renderPremiumValue(formatNumber(val))}</ObscuredBBWrapper>
                </div>
            ),
        },
        {
            title: () => (
                <Tooltip
                    title={
                        <span>
                            The number of people expected to have registered for the event by race
                            day.{" "}
                            <a
                                className="link-inline"
                                href={HELP_LINKS.eventSummaryTable.projParticipants}
                                target="learnMoreFrame"
                            >
                                Learn more
                            </a>
                        </span>
                    }
                    placement="top"
                >
                    <span className="tooltip-underline">Proj. participants</span>
                </Tooltip>
            ),
            align: "right",
            className:
                "budget-sheet__proj-cell-wrap  budget-sheet__border-right-cell budget-sheet__border-left-cell",
            width: 160,
            key: "participants_proj",
            dataIndex: "participants_proj",
            render: (val, record) => formatNumber(val),
        },
        // placeholder column to add space
        {
            title: " ",
            width: 10,
        },
        {
            title: () => (
                <Tooltip
                    title={
                        <span>
                            The percentage of projected participants on race day that have already
                            registered for the event.{" "}
                            <a
                                className="link-inline"
                                href={HELP_LINKS.eventSummaryTable.percentageFull}
                                target="learnMoreFrame"
                            >
                                Learn more
                            </a>
                        </span>
                    }
                    placement="top"
                >
                    <span className="tooltip-underline">% Full</span>
                </Tooltip>
            ),
            dataIndex: "pct_full",
            key: "pct_full",
            width: "12%",
            render: (val, record) => (
                <ObscuredBBWrapper>
                    <Progress
                        strokeColor={canAccessBB ? "#1BC537" : "#E8E8E8"}
                        trailColor="#E8E8E8"
                        // *10 / 10 to round to 1 decimal
                        percent={Math.round(val * 100)}
                        format={() => renderPremiumValue(`${Math.round(record.pct_full * 100)}%`)}
                        size="small"
                        style={{ fontSize: 14 }}
                    />
                </ObscuredBBWrapper>
            ),
        },
        {
            title: () => (
                <Tooltip
                    title={
                        <span>
                            Participation Breakeven: The minimum number of participants required to
                            ensure a profitable event.{" "}
                            <a
                                className="link-inline"
                                href={HELP_LINKS.eventSummaryTable.pb}
                                target="learnMoreFrame"
                            >
                                Learn more
                            </a>
                        </span>
                    }
                    placement="top"
                >
                    <span className="tooltip-underline">PB</span>
                </Tooltip>
            ),
            dataIndex: "pb",
            key: "pb",
            align: "right",
            width: "6.5%",
            render: (val, record) => (
                <div className="budget-event-table__flex">
                    <ObscuredBBWrapper>
                        {renderPremiumValue(val ? formatNumber(val) : "-")}
                    </ObscuredBBWrapper>
                </div>
            ),
        },
        {
            title: () => (
                <Tooltip
                    title={
                        <span>
                            Race Day Participants Estimate: An estimate of the number of
                            participants expected on race day based on the number of currently
                            registered participants.{" "}
                            <a
                                className="link-inline"
                                href={HELP_LINKS.eventSummaryTable.pprd}
                                target="learnMoreFrame"
                            >
                                Learn more
                            </a>
                        </span>
                    }
                    placement="top"
                >
                    <span className="tooltip-underline">RDPE</span>
                </Tooltip>
            ),
            dataIndex: "rdpe",
            key: "rdpe",
            align: "right",
            width: "8.5%",
            render: (val, record) => (
                <div className="budget-event-table__flex">
                    <ObscuredBBWrapper>
                        {renderPremiumValue(val != null ? formatNumber(val) : "")}
                    </ObscuredBBWrapper>
                </div>
            ),
        },
        {
            title: () => (
                <Tooltip
                    title={
                        <span>
                            Participant Profit Margin: The net profit made from each additional
                            participant registration.{" "}
                            <a
                                className="link-inline"
                                href={HELP_LINKS.eventSummaryTable.pip}
                                target="learnMoreFrame"
                            >
                                Learn more
                            </a>
                        </span>
                    }
                    placement="top"
                >
                    <span className="tooltip-underline">PPM</span>
                </Tooltip>
            ),
            dataIndex: "ppm",
            key: "ppm",
            align: "right",
            width: "7.5%",
            render: (val, record) => (
                <div className="budget-event-table__flex">
                    <ObscuredBBWrapper>
                        {renderPremiumValue(formatCurrency(val, user?.location?.ccy, 0))}
                    </ObscuredBBWrapper>
                </div>
            ),
        },
        {
            title: () => (
                <Tooltip
                    title={
                        <span>
                            Profit & Loss: The profit or loss for a specific event.{" "}
                            <a
                                className="link-inline"
                                href={HELP_LINKS.eventSummaryTable.pl}
                                target="learnMoreFrame"
                            >
                                Learn more
                            </a>
                        </span>
                    }
                    placement="top"
                >
                    <span className="tooltip-underline">P&L</span>
                </Tooltip>
            ),
            dataIndex: "pnl",
            key: "pnl",
            align: "right",
            width: "9.5%",
            render: (val) => formatCurrency(val, user?.location?.ccy, 0),
        },
    ];

    const summaryData = useMemo(() => {
        if (budget && budget.events.length > 0) {
            const participantsCurrSum = budget.events.reduce(
                (sum, event) =>
                    sum + event.nodes.reduce((sum, node) => sum + node.participants_curr, 0),
                0
            );
            const participantsProjSum = budget.events.reduce(
                (sum, event) =>
                    sum + event.nodes.reduce((sum, node) => sum + node.participants_proj, 0),
                0
            );
            const pnlSum = budget.events.reduce((sum, event) => sum + event.pnl, 0);

            return {
                participantsCurrSum,
                participantsProjSum,
                pnlSum,
            };
        }
        return {};
    }, [budget]);

    return (
        <div className="budget-sheet table__wrap">
            <Table
                className="budget-event-table"
                columns={columns}
                dataSource={budget?.events}
                pagination={false}
                rowKey="pk"
                onRow={(record) => ({
                    onClick: () => setEditingEventPk(record?.pk),
                    className: "table__clickable-row",
                })}
                tableLayout="fixed"
            />

            <Table
                className="budget-sheet__meta-table budget-sheet__meta-table--summary"
                columns={columns}
                showHeader={false}
                tableLayout="fixed"
                summary={() => (
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>
                            <span className="budget-sheet__meta-table-total">Total</span>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} />
                        <Table.Summary.Cell
                            index={2}
                            className="budget-event-info-item__sum-cell budget-sheet__border-bottom-left-cell"
                            align="right"
                        >
                            <ObscuredBBWrapper placement="topRight">
                                <span className="budget-sheet__meta-table-total">
                                    {renderPremiumValue(
                                        formatNumber(summaryData.participantsCurrSum)
                                    )}
                                </span>
                            </ObscuredBBWrapper>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell
                            index={3}
                            className="budget-event-info-item__sum-cell budget-sheet__border-bottom-right-cell budget-sheet__border-left-cell"
                            align="right"
                        >
                            <span className="budget-sheet__meta-table-total">
                                {formatNumber(summaryData.participantsProjSum)}
                            </span>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={4} />
                        <Table.Summary.Cell index={5} />
                        <Table.Summary.Cell index={6} />
                        <Table.Summary.Cell index={7} />
                        <Table.Summary.Cell index={8} />
                        <Table.Summary.Cell index={9} align="right">
                            <span className="budget-sheet__meta-table-total">
                                {formatCurrency(summaryData.pnlSum || 0, user?.location?.ccy, 0)}
                            </span>
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                )}
            />

            <EditEventModal
                event={editingEvent}
                isVisible={!!editingEventPk}
                closeModalHandler={() => setEditingEventPk(null)}
                racePk={currentRace.pk!}
                otherEvents={budget?.events.filter((e) => e.pk !== editingEventPk)}
            />
        </div>
    );
};

export default EventInfoTable;
