import { ChangeEvent, ReactElement } from "react";
import { Button, Typography, Tooltip, Input, InputNumber, Select, TimePicker } from "antd";

import { RaceFormDataTypes } from "app/store/races/races.types";
import userSlice from "app/store/user/user.slice";
import "app/components/templates/add_edit_race/add_edit_race.scss";
import { BinOutlined } from "assets";
import FormItemWrapper from "app/components/elements/form/form_item_wrapper";
import { UseFormReturn, Controller } from "react-hook-form";
import { RaceDetailsType } from "app/types/races/race.types";
import { useSelectOptions } from "app/hooks/useSelectField";
import dayjs from "dayjs";
import { ChevronDownOutlined } from "assets/icons/icons";

// TODO -> Correct item type
const RaceEvent = ({
    event,
    index,
    distance_units,
    participants,
    remove,
    isLastItem,
    formContext,
    onSelectFieldChange,
}: {
    event: RaceDetailsType["events"][0];
    index: number;
    participants: RaceFormDataTypes["participants"];
    distance_units: RaceFormDataTypes["distance_units"];
    remove: () => void;
    isLastItem: boolean;
    formContext: UseFormReturn<RaceDetailsType, any, undefined>;
    onSelectFieldChange: (name: string, value: string | number) => void;
}): ReactElement => {
    const { user } = userSlice((state) => state);
    const {
        control,
        formState: { errors },
    } = formContext;

    const distanceUnitOptions = useSelectOptions(distance_units);
    const participantsOptions = useSelectOptions(participants);

    return (
        <div className="add-edit-race__event-item " key={event.pk || event.id}>
            <div className="add-edit-race__event-input add-edit-race__event-name">
                <FormItemWrapper label="Event name" error={errors?.events?.[index]?.name} required>
                    <Controller
                        name={`events.${index}.name`}
                        control={control}
                        render={({ field: { ...rest } }) => (
                            <Input
                                type="text"
                                className="eventinputs-event-name eventinput"
                                status={errors?.events?.[index]?.name ? "error" : ""}
                                {...rest}
                            />
                        )}
                    />
                </FormItemWrapper>
            </div>
            <div className="add-edit-race__event-input add-edit-race__event-distance">
                <Typography.Text className="ant-label" strong>
                    Event distance <span className="ant-label-required">*</span>
                </Typography.Text>
                <div className="add-edit-race__content-group">
                    <FormItemWrapper error={errors?.events?.[index]?.distance} required>
                        <Controller
                            name={`events.${index}.distance`}
                            control={control}
                            render={({ field: { ...rest } }) => (
                                <InputNumber
                                    status={errors?.events?.[index]?.distance ? "error" : ""}
                                    className="eventinputs-distance eventinput add-edit-race__event-distance-input"
                                    style={{ width: "100%" }}
                                    {...rest}
                                />
                            )}
                        />
                    </FormItemWrapper>

                    <FormItemWrapper error={errors?.events?.[index]?.distance_units} required>
                        <Controller
                            name={`events.${index}.distance_units`}
                            control={control}
                            render={({ field: { onChange, ...rest } }) => (
                                <Select
                                    {...rest}
                                    status={errors?.events?.[index]?.distance_units ? "error" : ""}
                                    options={distanceUnitOptions}
                                    optionFilterProp="label"
                                    className="eventinputs-unit eventinput"
                                    showSearch
                                    suffixIcon={<ChevronDownOutlined />}
                                    style={{ width: "100%" }}
                                    onChange={(value) =>
                                        onSelectFieldChange(`events.${index}.distance_units`, value)
                                    }
                                />
                            )}
                        />
                    </FormItemWrapper>
                </div>
            </div>

            <div className="add-edit-race__event-input add-edit-race__participants">
                <FormItemWrapper
                    error={errors?.events?.[index]?.participants}
                    required
                    label="Participants"
                >
                    <Controller
                        name={`events.${index}.participants`}
                        control={control}
                        render={({ field: { value, onChange, ...rest } }) => (
                            <Select
                                {...rest}
                                value={
                                    participantsOptions.find((p) => p.value === value?.toString())
                                        ?.label
                                }
                                options={participantsOptions}
                                status={errors?.events?.[index]?.participants ? "error" : ""}
                                suffixIcon={<ChevronDownOutlined />}
                                className="eventinputs-participants eventinput"
                                style={{ width: "100%" }}
                                onChange={(value) => {
                                    onSelectFieldChange(`events.${index}.participants`, +value);
                                }}
                            />
                        )}
                    />
                </FormItemWrapper>
            </div>
            <div className="add-edit-race__event-input add-edit-race__start-time">
                <FormItemWrapper
                    error={errors?.events?.[index]?.start_time}
                    required
                    label="Start time"
                >
                    <Controller
                        name={`events.${index}.start_time`}
                        control={control}
                        render={({ field: { value, onChange, ...rest } }) => {
                            const format = ["HH:mm", "HH", "h:mm"];
                            return (
                                <TimePicker
                                    format={format}
                                    suffixIcon={null}
                                    showNow={false}
                                    placement="topLeft"
                                    needConfirm={false}
                                    placeholder="00:00"
                                    status={errors?.events?.[index]?.start_time ? "error" : ""}
                                    popupClassName="d-none"
                                    className="time-picker"
                                    style={{ width: "100%" }}
                                    {...rest}
                                    value={value ? dayjs(value, format) : null}
                                    onChange={(time, timeString) => {
                                        if (typeof timeString === "string") {
                                            onChange(timeString);
                                        }
                                    }}
                                />
                            );
                        }}
                    />
                </FormItemWrapper>
            </div>
            <div className="add-edit-race__event-input add-edit-race__entry-fee">
                <FormItemWrapper
                    error={errors?.events?.[index]?.entry_fee}
                    required
                    label="Entry fee"
                >
                    <Controller
                        name={`events.${index}.entry_fee`}
                        control={control}
                        render={({ field: { ...rest } }) => (
                            <InputNumber
                                status={errors?.events?.[index]?.entry_fee ? "error" : ""}
                                className={`eventinputs-entry-fee eventinput ${
                                    errors?.events?.[index]?.hasOwnProperty("entry_fee")
                                        ? "hasError"
                                        : ""
                                }`}
                                style={{ width: "100%" }}
                                prefix={user?.location?.ccy_symbol}
                                {...rest}
                            />
                        )}
                    />
                </FormItemWrapper>
            </div>
            {!isLastItem && (
                <div className="add-edit-race__delete-event">
                    <Tooltip title="Delete this event" placement="bottom">
                        <Button type="text" onClick={remove} className="ant-custom-btn-icon" danger>
                            <BinOutlined />
                        </Button>
                    </Tooltip>
                </div>
            )}
        </div>
    );
};
export default RaceEvent;
