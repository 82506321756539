import { ReactElement, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Button, Input, Typography, Tooltip, message, Upload, UploadFile, UploadProps } from "antd";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { profileValidationSchema } from "app/lib/validation_schemas/profile.schema";
import { _isEmpty, imageValidator } from "app/utils/helpers";
import { Prompt } from "app/hooks";
import { ErrorMessageReturnType, formatErrorMessages } from "app/utils/helpers/error_formatter";
import Select from "app/components/elements/form/select/select";
import PageWithLoader from "app/hoc/page_with_loader/page_with_loader";
import userSlice, { UpdateUserType } from "app/store/user/user.slice";
import { IUserProfile } from "app/types";
import { tryCatch } from "app/utils/helpers/functional_utilities";
import { PlusOutlined } from "assets";
import { getBase64 } from "app/utils/helpers/getBase64";
import PUBLIC_SITE_URL from "app/constants/public_site_urls";
import "app/views/protected/user/profile/profile.scss";
import FormItemWrapper from "app/components/elements/form/form_item_wrapper";

enum VerificationMessages {
    "success" = "Thank you! Your email address has been verified.",
    "error" = "Something went wrong. Please use the verification link we sent you and try again.",
}

function Profile(): ReactElement {
    const location = useLocation();
    const { updateUser, user, isLoading } = userSlice((state) => state);
    const [messageApi, contextHolder] = message.useMessage();
    const [searchParams, setSearchParams] = useSearchParams();

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [userIsUpdating, setUserIsUpdating] = useState<boolean>(false);

    const urlParams = new URLSearchParams(location.search);
    const isEmailVerified = urlParams.get("verified");
    const toastMessage =
        isEmailVerified === "true"
            ? VerificationMessages["success"]
            : VerificationMessages["error"];

    const {
        handleSubmit,
        control,
        reset,
        setError,
        formState: { errors, isDirty },
    } = useForm({
        resolver: yupResolver(profileValidationSchema),
        mode: "onBlur",
        reValidateMode: "onBlur",
        defaultValues: {
            username: "",
            first_name: "",
            last_name: "",
        },
    });

    useEffect(() => {
        if (isEmailVerified !== null) {
            const emailVerified = isEmailVerified === "true" ? "success" : "error";

            messageApi[emailVerified](toastMessage);
            searchParams.delete("verified");
            setSearchParams(searchParams);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (user?.pk) {
            reset({
                username: user?.username || "",
                first_name: user?.first_name || "",
                last_name: user?.last_name || "",
            });
        }
    }, [user?.pk]);

    useEffect(() => {
        if (user?.image) {
            setFileList([{ url: user?.image, uid: "1", name: "" }]);
        }
    }, [user?.image]);

    type PickedUserProfileType = Pick<IUserProfile, "username" | "first_name" | "last_name">;
    type FieldNameTypes = keyof PickedUserProfileType;
    const submitHandler = async ({
        username,
        first_name,
        last_name,
    }: Pick<PickedUserProfileType, "username" | "first_name" | "last_name">): Promise<void> => {
        setUserIsUpdating(true);
        const prepareUser: Partial<UpdateUserType> = {};

        if (username) prepareUser.username = username;
        if (first_name) prepareUser.first_name = first_name;
        if (last_name) prepareUser.last_name = last_name;

        if (fileList.length > 0 && fileList[0].url && fileList[0].url !== user?.image) {
            prepareUser.image = { base64: fileList[0].url };
        }

        if (user?.pk) {
            const [error, result] = await tryCatch(updateUser)(user?.pk, prepareUser);

            if (error) {
                if (error?.fieldErrors) {
                    const formattedErrors = formatErrorMessages<FieldNameTypes>(error.fieldErrors);
                    formattedErrors.forEach(
                        ({ name, type, message }: ErrorMessageReturnType<FieldNameTypes>) =>
                            setError(name, { type, message })
                    );
                }

                setUserIsUpdating(false);
                messageApi.error(
                    "Something went wrong while updating your profile details. Please try again."
                );

                return;
            }

            reset({
                username: result?.username,
                first_name: result?.first_name,
                last_name: result?.last_name,
            });

            setUserIsUpdating(false);
            messageApi.success("Your profile has been updated");
        }
    };

    const handleAvatarChange: UploadProps["beforeUpload"] = async (file): Promise<boolean> => {
        const [isValid, errorMessage] = imageValidator(file);
        if (!isValid) {
            messageApi.error(errorMessage);
            return false;
        }

        const fileUrl = await getBase64(file);
        const prepareFile = {
            uid: file.uid,
            name: file.name,
            url: fileUrl,
        };
        setFileList([prepareFile]);

        return false;
    };

    return (
        <>
            {contextHolder}
            <Prompt
                when={isDirty}
                message="Are you sure you want to leave this page? Any unsaved changes will be lost."
                beforeUnload
            />
            <PageWithLoader isLoading={_isEmpty(user) && isLoading}>
                <div className="profile">
                    <div className="profile__wrap">
                        <div className="profile__top">
                            <Typography.Title level={3}>Profile</Typography.Title>
                            <Typography.Paragraph>
                                Edit your profile information.
                            </Typography.Paragraph>
                        </div>
                        <form onSubmit={handleSubmit(submitHandler)}>
                            <div className="profile__content">
                                <div className="profile__avatar-wrap">
                                    <div className="profile__avatar-wrap-item">
                                        <Typography.Title level={4}>Profile image</Typography.Title>
                                    </div>
                                    <div className="profile__avatar-wrap-item upload-dragger-wrap">
                                        <Upload
                                            accept="image/png, image/jpeg, image/jpg"
                                            maxCount={1}
                                            listType="picture-card"
                                            multiple={false}
                                            fileList={fileList}
                                            beforeUpload={handleAvatarChange}
                                            showUploadList={{
                                                showPreviewIcon: false,
                                                showRemoveIcon: false,
                                            }}
                                        >
                                            <button
                                                className="business-listing__upload-btn"
                                                type="button"
                                            >
                                                <PlusOutlined />
                                                <div style={{ marginTop: 8 }}>Upload</div>
                                            </button>
                                        </Upload>
                                        <Typography.Text style={{ marginBlockStart: 7 }}>
                                            Max file size: 2mb | Accepted: .jpg, .jpeg, .png
                                        </Typography.Text>
                                    </div>
                                </div>
                                <div className="profile__inputs-wrap">
                                    <div className="profile__inputs-grp">
                                        <div className="profile__inputs-grp-item">
                                            <FormItemWrapper
                                                label="Username"
                                                error={errors.username}
                                            >
                                                <Controller
                                                    name="username"
                                                    control={control}
                                                    render={({
                                                        field: { onChange, onBlur, value },
                                                    }) => (
                                                        <Input
                                                            type="text"
                                                            value={value || ""}
                                                            status={errors?.username ? "error" : ""}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                        />
                                                    )}
                                                />
                                            </FormItemWrapper>
                                        </div>
                                        <div className="profile__inputs-grp-item">
                                            <Typography.Text className="ant-label" strong>
                                                Email
                                            </Typography.Text>
                                            <Tooltip
                                                title={
                                                    <span>
                                                        To change this field, please{" "}
                                                        <a
                                                            href={PUBLIC_SITE_URL.CONTACT}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="link-inline"
                                                        >
                                                            contact us
                                                        </a>
                                                    </span>
                                                }
                                                placement="bottom"
                                            >
                                                <Input
                                                    type="email"
                                                    name="email"
                                                    value={user.email}
                                                    disabled
                                                    readOnly
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className="profile__inputs-grp">
                                        <div className="profile__inputs-grp-item">
                                            <FormItemWrapper
                                                label="First name"
                                                error={errors.first_name}
                                            >
                                                <Controller
                                                    name="first_name"
                                                    control={control}
                                                    render={({
                                                        field: { onChange, onBlur, value },
                                                    }) => (
                                                        <Input
                                                            type="text"
                                                            value={value || ""}
                                                            status={
                                                                errors?.first_name ? "error" : ""
                                                            }
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                        />
                                                    )}
                                                />
                                            </FormItemWrapper>
                                        </div>
                                        <div className="profile__inputs-grp-item">
                                            <FormItemWrapper
                                                label="Last name"
                                                error={errors.last_name}
                                            >
                                                <Controller
                                                    name="last_name"
                                                    control={control}
                                                    render={({
                                                        field: { onChange, onBlur, value },
                                                    }) => (
                                                        <Input
                                                            type="text"
                                                            value={value || ""}
                                                            status={
                                                                errors?.last_name ? "error" : ""
                                                            }
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                        />
                                                    )}
                                                />
                                            </FormItemWrapper>
                                        </div>
                                    </div>
                                    <div className="profile__inputs-grp">
                                        <div className="profile__inputs-grp-item region">
                                            <Typography.Text className="ant-label" strong>
                                                Region
                                            </Typography.Text>
                                            <Tooltip
                                                title={
                                                    <span>
                                                        To change this field, please{" "}
                                                        <a
                                                            href={PUBLIC_SITE_URL.CONTACT}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="link-inline"
                                                        >
                                                            contact us
                                                        </a>
                                                    </span>
                                                }
                                                placement="bottom"
                                            >
                                                <span>
                                                    <Select
                                                        name="user_locations"
                                                        value={user?.location?.name || ""}
                                                        readonly
                                                    />
                                                </span>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="profile__bottom">
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    loading={userIsUpdating}
                                    disabled={userIsUpdating}
                                    className="responsive-cta"
                                >
                                    Save changes
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </PageWithLoader>
        </>
    );
}

export default Profile;
