import { ReactElement } from "react";
import { createPortal } from "react-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { CheckOutlined } from "assets/icons/icons";
import { Button, Modal, Typography } from "antd";
import { timeout } from "app/utils/helpers";
import listingsSlice from "app/store/listings/listings.slice";
import URL from "app/constants/route_urls";
import Storage from "app/utils/storage/local";
import STORAGE_CONSTANTS from "app/constants/storage";
import PUBLIC_SITE_URL from "app/constants/public_site_urls";
import modalsSlice from "app/store/modals/modals.slice";
import racesSlice from "app/store/races/races.slice";
import ImportRaceModal from "./modals/race_import_modal";
import "app/components/modules/global_modals/global_modals.scss";

const GlobalModals = (): ReactElement => {
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location?.search);

    const { importAsyncRace, importedRace, clearRaceImportError } = racesSlice((state) => state);
    const { listing } = listingsSlice((state) => state);

    const {
        modals,
        toggleMembersProgramModal,
        toggleNoListingCreditsModal,
        toggleImportRaceModal,
        toggleMemberFeatureVendorOffersModal,
        toggleMemberFeatureShowResultsModal,
        toggleMemberFeatureListReportModal,
        toggleNoListingCreditsListReportModal,
        toggleBusinessDashboardModal,
        toggleListingsUpgradeModal,
    } = modalsSlice((state) => state);

    const renderBusinessDashboardModalWithPortal = () =>
        modals.businessDashboardModal?.isVisible &&
        createPortal(
            <Modal
                open={modals.businessDashboardModal?.isVisible || false}
                onCancel={toggleBusinessDashboardModal}
                title="Vendor dashboard"
                centered
                width={480}
                footer={[
                    <Button
                        key="1"
                        className="responsive-cta"
                        type="default"
                        size="middle"
                        onClick={toggleBusinessDashboardModal}
                    >
                        Cancel
                    </Button>,
                    <Button
                        key="2"
                        className="responsive-cta"
                        type="primary"
                        size="middle"
                        onClick={() => {
                            toggleBusinessDashboardModal();
                            navigate(URL.BUSINESS_LISTINGS);
                        }}
                    >
                        Proceed
                    </Button>,
                ]}
            >
                <div className="global-modal-content">
                    <Typography.Paragraph>
                        You are about to switch to the vendor dashboard view.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        If you do not own a vendor business (race timing, race supplies, insurance
                        etc) you probably don't want to do that.
                    </Typography.Paragraph>
                </div>
            </Modal>,
            document.getElementById("modal-root") as HTMLElement
        );

    const renderMembersProgramModalWithPortal = () =>
        modals.membersProgram?.isVisible &&
        createPortal(
            <Modal
                open={modals.membersProgram?.isVisible || false}
                onCancel={toggleMembersProgramModal}
                title="Get more with Pro"
                centered
                width={480}
                footer={
                    <Button
                        className="responsive-cta"
                        type="primary"
                        size="middle"
                        style={{ width: "100%" }}
                        onClick={() => {
                            navigate(URL.MEMBERS_JOIN);
                            toggleMembersProgramModal();
                            Storage.set(
                                STORAGE_CONSTANTS.paymentRedirect,
                                location.pathname || URL.DASHBOARD
                            );
                        }}
                    >
                        Upgrade now
                    </Button>
                }
            >
                <div className="global-modal-content">
                    <Typography.Text>
                        Upgrade to our{" "}
                        <a
                            href={PUBLIC_SITE_URL.JOIN}
                            target="_blank"
                            rel="noreferrer"
                            className="link"
                        >
                            Pro member plan
                        </a>{" "}
                        today and get:
                    </Typography.Text>
                    <ul className="global-modal-content__list">
                        <li className="global-modal-content__list-item">
                            <CheckOutlined />
                            Your races promoted on the most popular race calendars
                        </li>
                        <li className="global-modal-content__list-item">
                            <CheckOutlined />
                            Unlimited leads to online sponsorship programs
                        </li>
                        <li className="global-modal-content__list-item">
                            <CheckOutlined />
                            Access to exclusive Pro vendor offers
                        </li>
                    </ul>
                </div>
            </Modal>,
            document.getElementById("modal-root") as HTMLElement
        );

    const renderMemberFeatureVendorOffersModalWithPortal = () =>
        modals.memberFeatureVendorOffersModal?.isVisible &&
        createPortal(
            <Modal
                open={modals.memberFeatureVendorOffersModal?.isVisible}
                onCancel={toggleMemberFeatureVendorOffersModal}
                title="Pro plan feature"
                centered
                width={480}
                footer={
                    <Button
                        className="responsive-cta"
                        type="primary"
                        size="middle"
                        onClick={() => {
                            navigate(URL.MEMBERS_JOIN);
                            toggleMemberFeatureVendorOffersModal();
                            Storage.set(
                                STORAGE_CONSTANTS.paymentRedirect,
                                location.pathname || URL.DASHBOARD
                            );
                        }}
                    >
                        Upgrade to Pro
                    </Button>
                }
            >
                <div className="global-modal-content">
                    <Typography.Text>This offer is only available to Pro members.</Typography.Text>
                    <br />
                    <Typography.Text>
                        Join our Pro member plan and get access to all our Pro offers, as well as a
                        ton of other{" "}
                        <a
                            href={PUBLIC_SITE_URL.JOIN}
                            target="_blank"
                            rel="noreferrer"
                            className="link"
                        >
                            great benefits
                        </a>
                        .
                    </Typography.Text>
                </div>
            </Modal>,
            document.getElementById("modal-root") as HTMLElement
        );

    const renderMemberFeatureShowResultsModalWithPortal = () =>
        modals.memberFeatureShowResultsModal?.isVisible &&
        createPortal(
            <Modal
                open={modals.memberFeatureShowResultsModal?.isVisible}
                onCancel={toggleMemberFeatureShowResultsModal}
                title="Pro plan feature"
                centered
                width={480}
                footer={
                    <Button
                        className="responsive-cta"
                        type="primary"
                        size="middle"
                        style={{ width: "100%" }}
                        onClick={() => {
                            navigate(URL.MEMBERS_JOIN);
                            toggleMemberFeatureShowResultsModal();
                            Storage.set(
                                STORAGE_CONSTANTS.paymentRedirect,
                                location.pathname || URL.DASHBOARD
                            );
                        }}
                    >
                        Upgrade to Pro
                    </Button>
                }
            >
                <div className="global-modal-content">
                    <Typography.Text>
                        This Sponsor Finder lead is only available to Pro members.
                    </Typography.Text>
                    <br />
                    <Typography.Text>
                        Join our Pro plan and get unlimited Sponsor Finder leads, as well as a ton
                        of other{" "}
                        <a
                            href={PUBLIC_SITE_URL.JOIN}
                            target="_blank"
                            rel="noreferrer"
                            className="link"
                        >
                            great benefits
                        </a>
                        .
                    </Typography.Text>
                </div>
            </Modal>,
            document.getElementById("modal-root") as HTMLElement
        );

    const renderMemberFeatureListReportModalWithPortal = () =>
        modals.memberFeatureListReportModal?.isVisible &&
        createPortal(
            <Modal
                open={modals.memberFeatureListReportModal?.isVisible}
                onCancel={toggleMemberFeatureListReportModal}
                title="You have no promo credits"
                centered
                width={480}
                destroyOnClose
                className="promo-credits-modal"
                footer={
                    <>
                        <Button
                            className="responsive-cta"
                            type="primary"
                            size="middle"
                            style={{ width: "100%" }}
                            onClick={(): void => {
                                navigate(URL.MEMBERS_JOIN);
                                toggleMemberFeatureListReportModal();
                                Storage.set(
                                    STORAGE_CONSTANTS.paymentRedirect,
                                    location.pathname || URL.DASHBOARD
                                );
                            }}
                        >
                            Upgrade to Pro
                        </Button>

                        <Button
                            className="responsive-cta ant-btn-primary-outline"
                            type="default"
                            size="middle"
                            style={{ width: "100%" }}
                            onClick={(): void => {
                                navigate(URL.LISTING_CREDITS, {
                                    state: { listingSubmit: true, default_credits: 1 },
                                });
                                toggleMemberFeatureListReportModal();
                                Storage.set(
                                    STORAGE_CONSTANTS.paymentRedirect,
                                    location.pathname || URL.DASHBOARD
                                );
                            }}
                        >
                            List this race only
                        </Button>
                    </>
                }
            >
                <div className="global-modal-content">
                    <Typography.Text>
                        To get three races promoted on race calendars at our cheapest rate, consider
                        upgrading to our{" "}
                        <a
                            href={PUBLIC_SITE_URL.JOIN}
                            target="_blank"
                            rel="noreferrer"
                            className="link"
                        >
                            Pro member plan
                        </a>
                    </Typography.Text>
                    <br />
                    <Typography.Text>
                        Otherwise, click to purchase a single promo credit for this race.
                    </Typography.Text>
                </div>
            </Modal>,
            document.getElementById("modal-root") as HTMLElement
        );

    const renderNoListingCreditsModalWithPortal = () =>
        modals.noListingCredits?.isVisible &&
        createPortal(
            <Modal
                open={modals.noListingCredits?.isVisible || false}
                onCancel={toggleNoListingCreditsModal}
                title="You're out of promo credits"
                centered
                destroyOnClose
                width={480}
                footer={
                    <Button
                        className="responsive-cta"
                        type="primary"
                        size="middle"
                        onClick={() => {
                            navigate(URL.LISTING_CREDITS);
                            toggleNoListingCreditsModal();
                            Storage.set(
                                STORAGE_CONSTANTS.paymentRedirect,
                                location.pathname || URL.DASHBOARD
                            );
                        }}
                    >
                        Purchase credits
                    </Button>
                }
            >
                <div className="global-modal-content">
                    <Typography.Text>
                        To get this race listed on race calendars, you&apos;ll have to purchase
                        additional promo credits.
                    </Typography.Text>
                </div>
            </Modal>,
            document.getElementById("modal-root") as HTMLElement
        );

    const renderNoListingCreditsListReportModalWithPortal = () =>
        modals.noListingCreditsListReportModal?.isVisible &&
        createPortal(
            <Modal
                open={modals.noListingCreditsListReportModal?.isVisible || false}
                onCancel={toggleNoListingCreditsListReportModal}
                title="You've run out of promo credits 😞"
                centered
                width={480}
                destroyOnClose
                footer={
                    <Button
                        className="responsive-cta"
                        type="primary"
                        size="middle"
                        onClick={() => {
                            navigate(URL.LISTING_CREDITS);
                            toggleNoListingCreditsListReportModal();
                            toggleNoListingCreditsModal();
                            Storage.set(
                                STORAGE_CONSTANTS.paymentRedirect,
                                location.pathname || URL.DASHBOARD
                            );
                        }}
                    >
                        Purchase credits
                    </Button>
                }
            >
                <div className="global-modal-content">
                    <Typography.Text>
                        To get this race listed on race calendars, you&apos;ll have to purchase
                        additional promo credits.
                    </Typography.Text>
                </div>
            </Modal>,
            document.getElementById("modal-root") as HTMLElement
        );

    const renderRaceModalWithPortal = () =>
        modals.importRaceModal?.isVisible &&
        createPortal(
            <ImportRaceModal
                navigate={navigate}
                visible={modals.importRaceModal?.isVisible}
                isLoading={importedRace.isLoading}
                closeModal={() => {
                    toggleImportRaceModal();
                    clearRaceImportError();
                }}
                currentLocation={location.pathname}
                isDisabled={importedRace.isLoading}
                error={importedRace.error}
                importRace={async (url: string) => {
                    const response = await importAsyncRace({ url });
                    if (response) {
                        toggleImportRaceModal();

                        if (typeof response === "number") {
                            timeout(
                                () =>
                                    navigate(`${URL.RACES}${response}/edit/`, {
                                        state: { then: query.get("then") },
                                    }),
                                500
                            );
                        } else {
                            timeout(
                                () =>
                                    navigate(URL.RACE_ADD, {
                                        state: { then: query.get("then") },
                                    }),
                                500
                            );
                        }
                    }

                    return false;
                }}
            />,
            document.getElementById("modal-root")!
        );

    const renderListingsModalWithPortal = () =>
        modals.listingsUpgradeModal?.isVisible &&
        createPortal(
            <Modal
                title="Get more with premium"
                open={modals.listingsUpgradeModal.isVisible}
                onCancel={toggleListingsUpgradeModal}
                width={480}
                centered
                footer={
                    <Button
                        className="responsive-cta"
                        type="primary"
                        size="middle"
                        onClick={() => {
                            Storage.set(
                                STORAGE_CONSTANTS.listingPaymentRedirect,
                                location.pathname || URL.BUSINESS_LISTINGS
                            );
                            navigate(`${URL.LISTING_UPGRADE}/${listing?.uuid}/`, {
                                state: { listing },
                            });
                            toggleListingsUpgradeModal();
                        }}
                    >
                        Upgrade now
                    </Button>
                }
            >
                <div className="global-modal-content">
                    <Typography.Text>
                        Upgrade your business to our{" "}
                        <a
                            href={`${PUBLIC_SITE_URL.BUSINESS_LISTINGS}`}
                            target="_blank"
                            rel="noreferrer"
                            className="link"
                        >
                            Premium listing plan
                        </a>{" "}
                        today and get:
                    </Typography.Text>
                    <ul className="global-modal-content__list">
                        <li className="global-modal-content__list-item">
                            <CheckOutlined />
                            Priority visibility in our directory in your category
                        </li>
                        <li className="global-modal-content__list-item">
                            <CheckOutlined />
                            Customer quote requests sent directly to your email
                        </li>
                        <li className="global-modal-content__list-item">
                            <CheckOutlined />
                            Free press releases for your business
                        </li>
                    </ul>
                </div>
            </Modal>,
            document.getElementById("modal-root")!
        );
    return (
        <>
            {renderMembersProgramModalWithPortal()}
            {renderNoListingCreditsModalWithPortal()}
            {renderMemberFeatureVendorOffersModalWithPortal()}
            {renderMemberFeatureShowResultsModalWithPortal()}
            {renderMemberFeatureListReportModalWithPortal()}
            {renderNoListingCreditsListReportModalWithPortal()}
            {renderRaceModalWithPortal()}
            {renderBusinessDashboardModalWithPortal()}
            {renderListingsModalWithPortal()}
        </>
    );
};

export default GlobalModals;
