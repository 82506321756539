import { ChangeEvent, ReactElement } from "react";
import { message, Input, Typography, Button } from "antd";
import {
    MarketerFormSchema,
    RaceMarketerFormType,
} from "app/lib/validation_schemas/marketer.schema";
import { IUser } from "app/store/types/user.types";
import { useHasListingCredits } from "app/hooks/useHasListingCredits";
import { EmailOutlined, PhoneOutlined } from "assets";
import { usePostOrganisationDetails } from "app/utils/api/mutations/race_marketer.mutation";
import Storage from "app/utils/storage/local";
import STORAGE_CONSTANTS from "app/constants/storage";
import User from "app/utils/user";
import userSlice from "app/store/user/user.slice";
import modalsSlice from "app/store/modals/modals.slice";
import "app/components/templates/race_marketer/race_marketer.scss";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

type FormTouchedFields = "organisation" | "org_fname" | "org_lname" | "org_email" | "org_phone";
type FormTouchedTypes = { [field in FormTouchedFields]: boolean };

const getStorageValues = (value: string): string => {
    const storageValues = Storage.get(STORAGE_CONSTANTS.postOrganizationValues);
    if (storageValues) {
        const parsedStorageValues = JSON.parse(storageValues);
        return parsedStorageValues[value] || "";
    }

    return "";
};

const DraftReportForm = ({
    pk,
    closeModal,
}: {
    pk: number;
    closeModal: () => void;
}): ReactElement => {
    const { user, getUser } = userSlice((state) => state);
    const UserModule = User(user as IUser);
    const { toggleMemberFeatureListReportModal } = modalsSlice((state) => state);
    const { mutateAsync: postOrganisationDetails, isPending } = usePostOrganisationDetails({
        onError: () => message.error("Something went wrong. Please try again."),
    });

    const userHasListingCredits = useHasListingCredits();

    const {
        handleSubmit,
        register,
        watch,
        setValue,
        trigger,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(MarketerFormSchema),
        mode: "all",
        reValidateMode: "onChange",
        defaultValues: {
            organisation: getStorageValues("organisation"),
            org_fname: getStorageValues("org_fname"),
            org_lname: getStorageValues("org_lname"),
            org_email: getStorageValues("org_email"),
            org_phone: getStorageValues("org_phone"),
        },
    });

    const submit = async (args: RaceMarketerFormType) => {
        Storage.set(STORAGE_CONSTANTS.postOrganizationValues, JSON.stringify(args));
        if (!userHasListingCredits) {
            // Check if user has member status
            if (!UserModule.isPremiumUser()) {
                toggleMemberFeatureListReportModal();
                return;
            }
        }

        postOrganisationDetails({ pk, args }).then(() => {
            window.scrollTo(0, 0);
            Storage.set(STORAGE_CONSTANTS.postOrganizationValues, JSON.stringify(args));
            reset();
            getUser();
            closeModal();
            message.success("Your race has been queued for listing");
        });
    };

    const onFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.name as keyof RaceMarketerFormType, event.target.value);
        trigger(event.target.name as keyof RaceMarketerFormType);
    };

    return (
        <div className="race-marketer__draft-report-form">
            <form onSubmit={handleSubmit(submit)} className="race-marketer__draft-report-form-wrap">
                <div className="race-marketer__draft-report-form-group half">
                    <Typography.Text className="ant-label">Race contact first name</Typography.Text>
                    <Input
                        type="text"
                        status={errors.org_fname ? "error" : ""}
                        autoComplete="off"
                        data-hj-allow
                        value={watch("org_fname")}
                        {...register("org_fname")}
                        onChange={onFieldChange}
                    />
                    {errors.org_fname && (
                        <span className="race-marketer__span-error">
                            {errors.org_fname.message}
                        </span>
                    )}
                </div>
                <div className="race-marketer__draft-report-form-group half">
                    <Typography.Text className="ant-label">Race contact last name</Typography.Text>
                    <Input
                        type="text"
                        status={errors.org_lname ? "error" : ""}
                        autoComplete="off"
                        data-hj-allow
                        value={watch("org_lname")}
                        {...register("org_lname")}
                        onChange={onFieldChange}
                    />
                    {errors.org_lname && (
                        <span className="race-marketer__span-error">
                            {errors.org_lname.message}
                        </span>
                    )}
                </div>
                <div className="race-marketer__draft-report-form-group full">
                    <Typography.Text className="ant-label">
                        Name of your organization
                    </Typography.Text>
                    <Input
                        type="text"
                        status={errors.organisation ? "error" : ""}
                        autoComplete="off"
                        data-hj-allow
                        value={watch("organisation")}
                        {...register("organisation")}
                        onChange={onFieldChange}
                    />
                    {errors.organisation && (
                        <span className="race-marketer__span-error">
                            {errors.organisation.message}
                        </span>
                    )}
                </div>

                <div className="race-marketer__draft-report-form-group half">
                    <Typography.Text className="ant-label">Race contact email</Typography.Text>
                    <Input
                        type="email"
                        status={errors.org_email ? "error" : ""}
                        autoComplete="off"
                        prefix={<EmailOutlined />}
                        data-hj-allow
                        value={watch("org_email")}
                        {...register("org_email")}
                        onChange={onFieldChange}
                    />
                    {errors.org_email && (
                        <span className="race-marketer__span-error">
                            {errors.org_email.message}
                        </span>
                    )}
                </div>
                <div className="race-marketer__draft-report-form-group half">
                    <Typography.Text className="ant-label">Race contact phone</Typography.Text>
                    <Input
                        type="text"
                        status={errors.org_phone ? "error" : ""}
                        autoComplete="off"
                        data-hj-allow
                        prefix={<PhoneOutlined />}
                        value={watch("org_phone")}
                        {...register("org_phone")}
                        onChange={onFieldChange}
                    />
                    {errors.org_phone && (
                        <span className="race-marketer__span-error">
                            {errors.org_phone.message}
                        </span>
                    )}
                </div>
                <div className="race-marketer__draft-report-form-group full submit">
                    <Button type="primary" htmlType="submit" loading={isPending}>
                        List race
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default DraftReportForm;
