import { ReactElement, useEffect, useRef, useMemo } from "react";
import { Button, Empty, Select, Skeleton, theme, Tooltip, Tag } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { ChevronDownOutlined } from "assets/icons/icons";
import { _isEmpty } from "app/utils/helpers";
import { useVendorListings } from "app/utils/api/queries";
import { PenOutlined, PlusOutlined, WarningOutlined } from "assets";
import { ListingStatus, ListingType } from "app/types";
import userSlice from "app/store/user/user.slice";
import STORAGE_CONSTANTS from "app/constants/storage";
import Storage from "app/utils/storage/local";
import listingsSlice from "app/store/listings/listings.slice";
import URL from "app/constants/route_urls";
import "./listing_select.scss";

// TODO -> Change ANTD Theme so the SELECT Component has active state set to green instead to blue
// TODO -> Create Zustand slice to have indication which listing is selected
const ListingSelect = (): ReactElement => {
    const runOnceRef = useRef<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const user = userSlice((state) => state.user);
    const { token } = theme.useToken();
    const { data, isLoading, error, isError } = useVendorListings();
    const { setCurrentListing, currentListingSelection, setListing } = listingsSlice(
        (state) => state
    );
    const currentListing = Storage.get(`${STORAGE_CONSTANTS.currentListing}.${user?.email}`);
    const listing =
        data !== undefined
            ? data?.find((listing) => listing.pk === currentListingSelection)
            : undefined;

    const hasCurrentListing = useMemo(
        () => data?.find((listing) => listing.pk === Number(currentListing)),
        [currentListing]
    );

    useEffect(() => {
        if (!hasCurrentListing && currentListing)
            Storage.remove(`${STORAGE_CONSTANTS.currentListing}.${user?.email}`);
    }, [hasCurrentListing, currentListing, user?.email]);

    const listingFromParam = queryParams.get("listing");

    // TODO -> Rework the logic in the useEffect
    useEffect(() => {
        if (listingFromParam) {
            setCurrentListing(+listingFromParam);
            runOnceRef.current = true;
        } else if (currentListing && !runOnceRef.current) {
            setCurrentListing(+currentListing);
            runOnceRef.current = true;
        } else if (!currentListing && data?.length) {
            setCurrentListing(data?.at(0)?.pk as number);
            runOnceRef.current = true;
        }
    }, [listingFromParam, currentListing, setCurrentListing, data?.length, data]);

    useEffect(() => {
        if (!isLoading && listing) setListing(listing);
    }, [isLoading, listing, setListing]);

    const renderRaceSelectContent = (): JSX.Element => {
        if (isLoading)
            return <Skeleton paragraph={{ rows: 1, width: "100%" }} title={false} active loading />;

        return (
            <>
                <div className="top-navbar__listing-select-item navbar-select__item">
                    <Select
                        style={{ width: "100%" }}
                        status={isError ? "error" : ""}
                        popupClassName="navbar-select__selector"
                        value={
                            currentListing !== null && hasCurrentListing
                                ? +currentListing
                                : undefined
                        }
                        options={data
                            ?.sort((a, b) => {
                                const isAActive =
                                    a.status !== ListingStatus.SUSPENDED &&
                                    a.status !== ListingStatus.RETIRED;
                                const isBActive =
                                    b.status !== ListingStatus.SUSPENDED &&
                                    b.status !== ListingStatus.RETIRED;

                                // eslint-disable-next-line no-nested-ternary
                                return isAActive === isBActive ? 0 : isAActive ? -1 : 1;
                            })
                            .map((listing: ListingType) => {
                                const isListingDisabled =
                                    listing.status === ListingStatus.SUSPENDED ||
                                    listing.status === ListingStatus.RETIRED;
                                return {
                                    value: listing.pk,
                                    label: (
                                        <span>
                                            {listing.title}{" "}
                                            {isListingDisabled ? (
                                                <Tag style={{ marginInlineStart: 5 }}>Disabled</Tag>
                                            ) : null}
                                        </span>
                                    ),
                                    disabled: isListingDisabled,
                                    className: isListingDisabled ? "ant-disabled-option" : "",
                                };
                            })}
                        notFoundContent={
                            <Empty
                                image={Empty.PRESENTED_IMAGE_DEFAULT}
                                description="No listings found"
                            />
                        }
                        onChange={(pk: number) => {
                            setCurrentListing(pk);
                            Storage.set(
                                `${STORAGE_CONSTANTS.currentListing}.${user?.email}`,
                                pk.toString()
                            );
                        }}
                        suffixIcon={<ChevronDownOutlined />}
                    />
                </div>
                <div className="top-navbar__listing-select-item">
                    {isError && (
                        <Tooltip title={error?.message}>
                            <Button className="ant-custom-btn-icon" type="link" ghost>
                                <WarningOutlined
                                    style={{
                                        width: "1.8rem",
                                        height: "1.8rem",
                                        color: token.colorError,
                                    }}
                                />
                            </Button>
                        </Tooltip>
                    )}
                    {!_isEmpty(["1"]) && (
                        <Tooltip title="Edit listing" placement="bottom">
                            <Button
                                className="ant-custom-btn-icon-outline"
                                onClick={() =>
                                    navigate(
                                        `${URL.BUSINESS_LISTINGS}${currentListingSelection}/edit`
                                    )
                                }
                            >
                                <PenOutlined color="#090B0B" />
                            </Button>
                        </Tooltip>
                    )}
                    <Tooltip title="Add listing" placement="bottom">
                        <Button
                            className="ant-custom-btn-icon-solid-secondary"
                            onClick={() => navigate(URL.BUSINESS_LISTINGS_ADD)}
                        >
                            <PlusOutlined fontSize="1.1rem" />
                        </Button>
                    </Tooltip>
                </div>
            </>
        );
    };

    return (
        <div className="top-navbar__listing-select">
            <div className={`top-navbar__listing-select-wrap ${isLoading ? "isLoading" : ""}`}>
                {renderRaceSelectContent()}
            </div>
        </div>
    );
};

export default ListingSelect;
