import { ReactElement, useRef } from "react";
import { useListingClaim } from "app/utils/api/mutations/listing.mutation";
import { useParams, useNavigate } from "react-router-dom";
import URL from "app/constants/route_urls";
import PageWithLoader from "app/hoc/page_with_loader/page_with_loader";

const ListingClaim = (): ReactElement => {
    const { id } = useParams();
    const navigate = useNavigate();
    const hasMutated = useRef(false);
    const isEnabled = Boolean(id);

    const { mutateAsync, isError, isPending, isIdle, error } = useListingClaim(isEnabled);

    if (!hasMutated.current && id) {
        hasMutated.current = true;
        mutateAsync(id).then((response) => {
            if (response?.data?.pk)
                navigate(`${URL.BUSINESS_LISTINGS}${response.data.pk}/edit/?claimSuccess=true`);
        });
    }

    const errorMessage = Array.isArray(error?.response?.data)
        ? error?.response?.data?.at(0)
        : error?.message;

    return (
        <PageWithLoader
            isLoading={isPending || isIdle}
            error={isError}
            errorText={errorMessage}
            text="We’re processing your listing claim. This should only take a moment."
        >
            <div />
        </PageWithLoader>
    );
};

export default ListingClaim;
