import { useEffect, useState, ReactElement, useCallback } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Pagination } from "antd";
import { renderRacesContent } from "app/components/templates/races/races";
import { _isEmpty } from "app/utils/helpers";
import { useCompare } from "app/hooks";
import { useGetRaces } from "app/utils/api/queries/races.query";
import PageWithLoader from "app/hoc/page_with_loader/page_with_loader";
import modalsSlice from "app/store/modals/modals.slice";
import racesSlice from "app/store/races/races.slice";
import "app/components/templates/races/races.scss";

const UpcomingRaces = (): ReactElement => {
    const browserLocation = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isPaginated, setIsPaginated] = useState<boolean>(false);

    const {
        updatePageNumber,
        races: racesState,
        displayImportRaceError,
    } = racesSlice((state) => state);
    const { currentPage } = racesState.upcoming;

    const { data: upcomingRaces, isLoading } = useGetRaces({
        filter: "upcoming",
        page: currentPage,
    });

    const { page_size, results, count } = upcomingRaces ?? {};

    const queryParams = new URLSearchParams(browserLocation.search);
    const { toggleImportRaceModal } = modalsSlice((state) => state);

    const paginationHandler = useCallback(
        (page: number) => {
            window.scrollTo(0, 0);
            setIsPaginated(true);
            updatePageNumber("upcoming", page);
        },
        [updatePageNumber]
    );

    const didRacesChange = useCompare(results?.length);

    // go back to previous page when last item on the page is deleted
    useEffect(() => {
        if (didRacesChange && currentPage > 1 && _isEmpty(results)) {
            paginationHandler(currentPage - 1);
        }
    }, [results, currentPage, paginationHandler, didRacesChange]);

    const raceImportErrorMessage = queryParams.get("raceImportErrorMessage");
    const shouldToggleModal = queryParams.get("toggleModal");

    const dispatcher = useCallback(() => {
        toggleImportRaceModal();
        displayImportRaceError(raceImportErrorMessage as string);
        searchParams.delete("raceImportErrorMessage");
        searchParams.delete("raceUrl");
        setSearchParams(searchParams);
    }, [
        displayImportRaceError,
        raceImportErrorMessage,
        searchParams,
        setSearchParams,
        toggleImportRaceModal,
    ]);

    useEffect(() => {
        if (!_isEmpty(raceImportErrorMessage)) dispatcher();
        if (shouldToggleModal) {
            toggleImportRaceModal();
            searchParams.delete("toggleModal");
            setSearchParams(searchParams);
        }
    }, [
        dispatcher,
        raceImportErrorMessage,
        shouldToggleModal,
        searchParams,
        setSearchParams,
        toggleImportRaceModal,
    ]);

    const showPagination = !!count && !!page_size && count > page_size;

    return (
        <PageWithLoader isLoading={isLoading && !isPaginated}>
            <div className={`races__wrap ${isLoading ? "isLoading" : ""}`}>
                <div className={`races__inner-wrap ${results?.length === 0 ? "noRaces" : ""}`}>
                    {results && renderRacesContent(results, "upcoming", toggleImportRaceModal)}
                </div>
                {showPagination && (
                    <div className="races__pagination">
                        <Pagination
                            showTitle
                            onChange={(page: number) => paginationHandler(page)}
                            current={currentPage}
                            total={count}
                            pageSize={page_size}
                            showSizeChanger={false}
                        />
                    </div>
                )}
            </div>
        </PageWithLoader>
    );
};

export default UpcomingRaces;
