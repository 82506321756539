import { ReactNode } from "react";
import "app/components/elements/wrappers/wrappers.scss";
import { TooltipPlacement } from "antd/es/tooltip";
import userSlice from "app/store/user/user.slice";
import User from "app/utils/user";
import { IUser } from "app/store/types/user.types";
import ProTooltip from "./pro_tooltip";

const ObscuredWrapper = ({
    children,
    placement,
    custom_cta,
    condition,
    conditionOverride,
    style,
}: {
    children: ReactNode;
    placement?: TooltipPlacement;
    custom_cta?: ReactNode;
    condition?: boolean;
    conditionOverride?: boolean;
    style?: React.CSSProperties;
}) => {
    const { user } = userSlice((state) => state);
    const UserModule = User(user as IUser);

    if (condition || (!conditionOverride && UserModule.isPremiumUser())) return children;

    return (
        <ProTooltip placement={placement} custom_cta={custom_cta}>
            <div className="obscured__wrapper" style={style}>
                <div
                    className="obscured__content"
                    onCopy={(e) => {
                        e.preventDefault();
                        return false;
                    }}
                >
                    {children}
                </div>
            </div>
        </ProTooltip>
    );
};

export default ObscuredWrapper;
