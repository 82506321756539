import { useEffect, useState, ReactElement } from "react";
import { Radio, Button, Typography } from "antd";
import { Link, useLocation, Navigate } from "react-router-dom";
import { _isEmpty } from "app/utils/helpers";
import { tryCatch } from "app/utils/helpers/functional_utilities";
import Storage from "app/utils/storage/local";
import STORAGE_CONSTANTS from "app/constants/storage";
import Dialog from "app/components/elements/dialog/dialog";
import userSlice, { Location, UpdateUserType } from "app/store/user/user.slice";
import logo from "assets/logo_full.svg";
import LoadingScreen from "app/components/elements/loading_screen/loading_screen";
import URL from "app/constants/route_urls";
import "app/views/public/auth/auth.scss";

const LocationConfirm = (): ReactElement => {
    const location = useLocation();
    const { isAuthenticated, user, userLocations, getUser, updateUser } = userSlice(
        (state) => state
    );

    const token: string | null = Storage.get(STORAGE_CONSTANTS.accessToken);
    const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
    const [locationError, setLocationError] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (_isEmpty(user) && isAuthenticated) getUser();
    }, [user, token, isAuthenticated, getUser]);

    if (!isAuthenticated) return <Navigate to={URL.LOGIN} />;
    if (_isEmpty(user)) return <LoadingScreen type="layout" />;

    const confirmLocationHandler = async (): Promise<void> => {
        if (!selectedLocation) return;
        setIsLoading(true);
        setLocationError("");

        const payload: Partial<UpdateUserType> = {
            location: {
                name: selectedLocation,
            },
        };

        if (user && user.pk) {
            const [error] = await tryCatch(updateUser)(user.pk, payload);

            if (error) {
                setLocationError("Something went wrong. Please try again.");
                return;
            }
        }

        setIsLoading(false);
        Storage.set(STORAGE_CONSTANTS.loginSuccessMessage, `Welcome ${user?.greeting_name}`);
    };

    return (
        <div className="auth">
            <div className="auth-container">
                <div className="auth-wrap">
                    <div className="auth-wrap__header">
                        <a href="/" rel="noopener noreferrer">
                            <div className="auth-wrap__logo">
                                <img src={logo} alt="" />
                            </div>
                        </a>
                    </div>
                    <div className="auth-wrap__content">
                        {!_isEmpty(locationError) && (
                            <Dialog type="error" message={locationError} />
                        )}
                        <Typography.Text>Please select your location:</Typography.Text>
                        <div className="auth-wrap__location-select-wrap">
                            <Radio.Group
                                onChange={(event) => {
                                    setSelectedLocation(event.target.value as Location);
                                }}
                            >
                                <div className="auth__stack">
                                    {userLocations?.map((location) => (
                                        <Radio
                                            key={location?.name}
                                            className="auth__radio-grp"
                                            value={location?.name}
                                        >
                                            <img
                                                src={`/assets/${location?.ccy}.png`}
                                                alt={`${location?.abbr_name} flag`}
                                            />
                                            <span>{location?.name}</span>
                                        </Radio>
                                    ))}
                                </div>
                            </Radio.Group>
                        </div>

                        <div className="auth-wrap__form-group--submit">
                            <Button
                                type="primary"
                                disabled={!selectedLocation || isLoading}
                                loading={isLoading}
                                onClick={confirmLocationHandler}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="auth__linking">
                    <Typography.Text role="presentation">
                        <Link
                            to={URL.LOGOUT}
                            state={{ from: location?.state?.from }}
                            replace
                            className="link"
                        >
                            Sign in with a different account
                        </Link>
                    </Typography.Text>
                </div>
            </div>
        </div>
    );
};

export default LocationConfirm;
