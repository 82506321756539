import { useState } from "react";
import { Typography, Input, Modal, Button } from "antd";
import { useLocation, NavigateFunction } from "react-router-dom";
import { timeout, _isEmpty } from "app/utils/helpers";
import URL from "app/constants/route_urls";
import Dialog from "app/components/elements/dialog/dialog";
import Storage from "app/utils/storage/local";
import STORAGE_CONSTANTS from "app/constants/storage";
import racesSlice from "app/store/races/races.slice";

const { Text } = Typography;

const ImportRaceModal = ({
    navigate,
    isLoading,
    visible,
    isDisabled,
    error,
    currentLocation,
    importRace,
    closeModal,
}: {
    navigate: NavigateFunction;
    isLoading: boolean;
    visible: boolean;
    isDisabled: boolean;
    error: string;
    currentLocation: string;
    importRace: (arg: string) => Promise<boolean>;
    closeModal: () => void;
}): JSX.Element => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const raceUrl = queryParams.get("raceUrl");
    const [inputValue, setInputValue] = useState<string>(raceUrl || "");
    const { clearRaceImportError, displayImportRaceError } = racesSlice((state) => state);
    const renderDialogError = () => !_isEmpty(error) && <Dialog type="error" message={error} />;

    return (
        <Modal
            open={visible}
            onCancel={closeModal}
            title="Add a race"
            centered
            width={480}
            destroyOnClose
            footer={
                <>
                    <Button
                        className="responsive-cta ant-btn-primary-outline"
                        type="default"
                        size="middle"
                        onClick={(): void => {
                            closeModal();
                            setInputValue("");
                            timeout(() => navigate(URL.RACE_ADD), 500);
                            Storage.set(STORAGE_CONSTANTS.addRaceRedirect, currentLocation);
                        }}
                    >
                        Add race manually
                    </Button>

                    <Button
                        className="responsive-cta"
                        type="primary"
                        size="middle"
                        onClick={async () => {
                            const urlRegex =
                                // eslint-disable-next-line no-useless-escape
                                /(https?:\/\/)?([\da-z\.-]+)\.([a-z]{2,6})([\/\w\.-]*)*\/?/;

                            if (!inputValue) {
                                displayImportRaceError(
                                    "Please provide your race registration page in the input field below."
                                );
                                return;
                            }

                            if (!urlRegex.test(inputValue)) {
                                displayImportRaceError(
                                    "Please provide a valid registration URL for your race."
                                );
                                return;
                            }

                            if (await importRace(inputValue)) {
                                setInputValue("");
                            }

                            Storage.set(STORAGE_CONSTANTS.addRaceRedirect, currentLocation);
                        }}
                        loading={isLoading}
                        disabled={isDisabled}
                    >
                        Import race
                    </Button>
                </>
            }
        >
            <div className="race-import-modal">
                <div
                    className={`race-import-modal__error ${
                        !_isEmpty(error) ? "erroring" : "no-error"
                    }`}
                >
                    {renderDialogError()}
                </div>
                <div>
                    <Text className="race-import-modal__info">
                        Enter your registration page link below to import your race details directly
                        from your registration page:
                    </Text>
                </div>
                <div className="race-import-modal__input">
                    <Input
                        placeholder="Registration page link"
                        value={inputValue}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            clearRaceImportError();
                            setInputValue(event.target.value);
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ImportRaceModal;
