export const HELP_LINKS = {
    budgetView: {
        event: "https://help.racedirectorshq.com/en/articles/9342779#h_51aac0e6c0",
        price: "https://help.racedirectorshq.com/en/articles/9342779#h_896aead946",
        quantity: "https://help.racedirectorshq.com/en/articles/9342779#h_b53f41814e",
        total: "https://help.racedirectorshq.com/en/articles/9342779#h_cd28fe6a67",
        buildingBudget:
            "https://www.racedirectorshq.com/read/building-a-race-budget-with-budget-builder-967/",
    },
    varianceView: {
        event: "https://help.racedirectorshq.com/en/articles/9346070#h_0119291e66",
        budgetedRevenue: "https://help.racedirectorshq.com/en/articles/9346070#h_7f5858bfa3",
        budgetedCost: "https://help.racedirectorshq.com/en/articles/9346070#h_7f5858bfa3",
        actualRevenue: "https://help.racedirectorshq.com/en/articles/9346070#h_3bdbb039af",
        actualCost: "https://help.racedirectorshq.com/en/articles/9346070#h_3bdbb039af",
        variance: "https://help.racedirectorshq.com/en/articles/9346070#h_a9eae81c5c",
        percentageVariance: "https://help.racedirectorshq.com/en/articles/9346070#h_31892094ae",
    },
    budgetItemEditor: {
        revenueGroup: "https://help.racedirectorshq.com/en/articles/9344633#h_f299b752af",
        costGroup: "https://help.racedirectorshq.com/en/articles/9344633#h_f299b752af",
        itemType: "https://help.racedirectorshq.com/en/articles/9344633#h_81cfae93d4",
        budgetedPrice: "https://help.racedirectorshq.com/en/articles/9344633#h_4cd57c8340",
        actualPrice: "https://help.racedirectorshq.com/en/articles/9344633#h_bdbbc62c78",
        quantityType: "https://help.racedirectorshq.com/en/articles/9344633#h_add3aca83d",
        event: "https://help.racedirectorshq.com/en/articles/9344633#h_7f5f8576e8",
    },
    eventSummaryTable: {
        entryFee: "https://help.racedirectorshq.com/en/articles/9351613#h_5fb9165700",
        regParticipants: "https://help.racedirectorshq.com/en/articles/9351613#h_9d7bfc3fae",
        projParticipants: "https://help.racedirectorshq.com/en/articles/9351613#h_0c3ffbc713",
        percentageFull: "https://help.racedirectorshq.com/en/articles/9351613#h_daf9e9086c",
        pb: "https://help.racedirectorshq.com/en/articles/9351613#h_86a890d522",
        pprd: "https://help.racedirectorshq.com/en/articles/9351613#h_6f3736f19d",
        pip: "https://help.racedirectorshq.com/en/articles/9351613#h_6e0607757f",
        pl: "https://help.racedirectorshq.com/en/articles/9351613#h_f5c6c56bc5",
    },
    editEventModal: {
        regParticipantsError: "https://help.racedirectorshq.com/en/articles/9334761",
    },
};
