import { ReactElement, useEffect, useMemo, useState } from "react";
import { Button, Input, Typography } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { tryCatch } from "app/utils/helpers/functional_utilities";
import { AxiosServiceError } from "app/utils/api/axios/service_error";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import Dialog from "app/components/elements/dialog/dialog";
import API_URL from "app/constants/api_urls";
import URL from "app/constants/route_urls";
import { UserPasswordResetSchema } from "app/lib/validation_schemas/auth.schema";
import userSlice from "app/store/user/user.slice";
import { _isEmpty } from "app/utils/helpers";
import Storage from "app/utils/storage/local";
import STORAGE_CONSTANTS from "app/constants/storage";
import logo from "assets/logo_full.svg";
import API from "app/utils/api/axios";
import "app/views/public/auth/auth.scss";

function PasswordReset(): ReactElement {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const cancelAxios = useMemo(() => axios.CancelToken.source(), []);

    // Store
    const { isAuthenticated } = userSlice((state) => state);

    // Clean up
    useEffect(() => () => cancelAxios.cancel(), [cancelAxios]);

    useEffect(() => {
        if (isAuthenticated) navigate(URL.DASHBOARD);
    }, [isAuthenticated, navigate]);

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(UserPasswordResetSchema),
        mode: "onBlur",
        reValidateMode: "onBlur",
        defaultValues: {
            email: "",
        },
    });

    const passwordResetAction = async (email: string): Promise<{ data: { detail: string } }> => {
        try {
            const { data } = await API.post(
                API_URL.PASSWORD_RESET,
                { email },
                { cancelToken: cancelAxios.token }
            );
            return { data };
        } catch (err) {
            throw new AxiosServiceError(err);
        }
    };

    const submitHandler = async ({ email }: { email: string }) => {
        setError("");
        setIsLoading(true);

        const [error, result] = await tryCatch(passwordResetAction)(email);

        if (error) {
            setError("Something went wrong. Please try again");
            setIsLoading(true);
            return;
        }

        Storage.set(STORAGE_CONSTANTS.passwordResetMessage, result?.data.detail as string);
        Storage.remove("login_email");

        reset();
        navigate(URL.LOGIN);
    };

    // Display back-end errors
    const renderError = () => <Dialog type="error" message={error} />;

    return (
        <div className="auth">
            <div className="auth-container">
                <div className="auth-wrap">
                    <div className="auth-wrap__header">
                        <a href={import.meta.env.VITE_BASE_URL} rel="noopener noreferrer">
                            <div className="auth-wrap__logo">
                                <img src={logo} alt="RDHQ - Main Logo" />
                            </div>
                        </a>
                    </div>
                    <div className="auth-wrap__content">
                        {!_isEmpty(error) && renderError()}
                        <form onSubmit={handleSubmit(submitHandler)}>
                            <div className="auth-wrap__form-group">
                                <Typography.Paragraph>
                                    To reset your password, please enter your email below.
                                </Typography.Paragraph>
                                <Typography.Text className="ant-label" strong>
                                    Email
                                </Typography.Text>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <Input
                                            value={value || ""}
                                            placeholder="Your email address"
                                            status={errors?.email ? "error" : ""}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            disabled={isLoading}
                                        />
                                    )}
                                />
                                {errors?.email && (
                                    <Typography.Text className="ant-error-label">
                                        {errors.email.message}
                                    </Typography.Text>
                                )}
                            </div>
                            <div className="auth-wrap__form-group--submit">
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    loading={isLoading}
                                    disabled={isLoading}
                                >
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="auth__linking">
                    <Typography.Text>
                        Back to{" "}
                        <Link
                            to={URL.LOGIN}
                            state={{ from: location?.state?.from }}
                            className="link"
                        >
                            sign in
                        </Link>
                    </Typography.Text>
                </div>
            </div>
        </div>
    );
}

export default PasswordReset;
