/* eslint-disable react/no-danger */
import { ReactElement, useRef, useState, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { StarOutlined } from "assets/icons/icons";
import { Button, Tooltip, Typography, theme } from "antd";
import { VendorOfferType } from "app/types/vendors/vendors.types";
import { IUser } from "app/store/types/user.types";
import User from "app/utils/user";
import API from "app/utils/api/axios";
import "app/components/elements/cards/vendor_offers_card/vendor_offers_card.scss";

type OfferCardType = Omit<VendorOfferType, "claim_mode" | "claim_instructions"> & {
    mode: "quote" | "instructions";
    user: Partial<IUser>;
    toggleModal: (pk: number, mode: "quote" | "instructions" | "payments") => void;
};

const VendorOffersCard = ({
    pk,
    title,
    content,
    image,
    listing_url,
    premium,
    sponsor,
    mode,
    user,
    cta,
    cta_url,
    toggleModal,
}: OfferCardType): ReactElement => {
    const { token } = theme.useToken();
    const [loading, setLoading] = useState<boolean>(false);
    const [isModalFirstToggled, setIsModalFirstToggled] = useState<boolean>(true);
    const UserModule = User(user as IUser);
    const params = useParams();
    const cardRef = useRef<HTMLDivElement>(null);
    const isSelected = params.pk === pk.toString();

    const asyncOfferTracking = async (): Promise<void> => {
        await API.get(`/api/offers/${pk}/view/`);
    };

    const renderCheckListingUrl = (): JSX.Element =>
        listing_url ? (
            <a href={listing_url} target="_blank" rel="noreferrer" className="link">
                {sponsor}
            </a>
        ) : (
            <Typography.Text>{sponsor}</Typography.Text>
        );

    const renderClaimOfferButton = (): JSX.Element => {
        if (!UserModule.isPremiumUser() && premium)
            return (
                <Button
                    className="ant-btn-primary-outline"
                    type="default"
                    onClick={() => {
                        asyncOfferTracking();

                        return cta_url?.length
                            ? window.open(cta_url, "_blank")
                            : toggleModal(pk, "payments");
                    }}
                >
                    {cta || "Claim offer"}
                </Button>
            );
        return (
            <Button
                className="ant-btn-primary-outline"
                type="default"
                onClick={() => {
                    asyncOfferTracking();
                    return cta_url?.length ? window.open(cta_url, "_blank") : toggleModal(pk, mode);
                }}
            >
                {cta || "Claim offer"}
            </Button>
        );
    };

    useLayoutEffect(() => {
        if (isSelected && isModalFirstToggled) {
            cardRef.current?.focus();
            cardRef.current?.scrollIntoView({
                block: "center",
                behavior: "smooth",
            });

            if (!UserModule.isPremiumUser() && premium) {
                toggleModal(pk, "payments");
            } else if (cta_url?.length) {
                // do nothing
            } else {
                toggleModal(pk, mode);
            }

            setIsModalFirstToggled(false);
        }
    }, [isSelected, toggleModal, pk, mode, premium, UserModule, isModalFirstToggled, cta_url]);

    return (
        <div
            ref={cardRef}
            className={`offer-card ${isSelected ? "isActive" : ""}`}
            style={{ borderRadius: token.borderRadius }}
        >
            <div className="offer-card__logo" style={{ borderRadius: token.borderRadius }}>
                {image && (
                    <img
                        src={image}
                        alt={title}
                        loading="lazy"
                        onLoad={() => setLoading(true)}
                        className={`load-image ${loading ? "image-loaded" : ""}`}
                    />
                )}
                {premium && !UserModule.isPremiumUser() && (
                    <Tooltip title="This offer is only available to Pro members" placement="bottom">
                        <span className="offer-card__premium-badge">
                            <StarOutlined />
                            Pro offer
                        </span>
                    </Tooltip>
                )}
            </div>
            <div className="offer-card__info">
                <div className="offer-card__header">
                    <div className="offer-card__title-wrap">
                        <Typography.Title level={5} className="offer-card__subtitle">
                            {renderCheckListingUrl()}
                        </Typography.Title>
                        <Tooltip title={title} placement="bottom">
                            <Typography.Title level={5} className="offer-card__title">
                                {title}
                            </Typography.Title>
                        </Tooltip>
                    </div>
                    <div
                        className="offer-card__description"
                        dangerouslySetInnerHTML={{
                            __html: content,
                        }}
                    />
                </div>

                <div className="offer-card__actions">
                    <div className="offer-card__actions-wrap">{renderClaimOfferButton()}</div>
                </div>
            </div>
        </div>
    );
};

export default VendorOffersCard;
