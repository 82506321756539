import * as yup from "yup";

export const UserLoginSchema = yup.object().shape({
    email: yup
        .string()
        .required("This field is required")
        .email("Please enter a valid email address"),
    password: yup.string().required("This field is required").ensure(),
});

// Register types
export const UserSignUpSchema = yup.object().shape({
    username: yup
        .string()
        .required("This field is required.")
        .min(4, "Usernames should be at least 4 characters long.")
        .max(100, "Ensure this field has 100 character at most."),
    email: yup
        .string()
        .required("This field is required")
        .email("Please enter a valid email address"),
    password1: yup
        .string()
        .required("This field is required.")
        .min(6, "Passwords should be at least 8 characters long."),
    location: yup.string().ensure().required("This field is required."),
});

// Reset password types
export type ResetPasswordType = {
    email: string;
};
export interface IFormResetPassword {
    initialValues: {
        email: string;
    };
    validationSchema: yup.Schema<ResetPasswordType>;
    onSubmit: (arg: { email: string }) => void;
}

export const UserPasswordResetSchema = yup.object().shape({
    email: yup
        .string()
        .required("This field is required")
        .email("Please provide a valid email address"),
});

// Add new password
export type NewPasswordsType = {
    newPassword1: string;
    newPassword2: string;
};

export const UserNewPasswordSchema = yup.object().shape({
    newPassword1: yup
        .string()
        .required("This field is required")
        .ensure()
        .min(8, "Your password must contain at least 8 characters"),
    newPassword2: yup
        .string()
        .required("This field is required")
        .ensure()
        .min(8, "Your password must contain at least 8 characters")
        .oneOf([yup.ref("newPassword1"), ""], "Passwords must match"),
});
