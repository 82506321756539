import { Tooltip, Typography } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { ReactNode } from "react";
import { FieldError } from "react-hook-form";

const FormItemWrapper = ({
    label,
    tooltipText,
    tooltipPlacement = "right",
    required = false,
    children,
    error,
}: {
    label?: string | JSX.Element;
    tooltipText?: string | JSX.Element;
    tooltipPlacement?: TooltipPlacement;
    required?: boolean;
    children: ReactNode;
    error?: FieldError;
}) => (
    <div>
        {label && typeof label === "string" && (
            <Tooltip title={tooltipText} placement={tooltipPlacement}>
                <Typography.Text className="ant-label" strong>
                    <span className={`${tooltipText ? "tooltip-underline" : ""}`}>{label}</span>{" "}
                    {required ? <span className="ant-label-required">*</span> : null}
                </Typography.Text>
            </Tooltip>
        )}

        {label && typeof label !== "string" && label}

        {children}

        {error && <Typography.Text className="ant-error-label">{error.message}</Typography.Text>}
    </div>
);

export default FormItemWrapper;
