import { ReactElement, useRef, useState } from "react";
import { Button, Tooltip } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ExpandSidebarOutlined } from "assets/icons/icons";
import {
    SwitchOutlined,
    ProfileOutlined,
    GearOutlined,
    LogoutOutlined,
    QuestionOutlined,
} from "assets";
import { ListingType, ListingStatus } from "app/types";
import { useClickOutside } from "app/hooks";
import { IUser } from "app/store/types/user.types";
import { _isEmpty } from "app/utils/helpers";
import URL from "app/constants/route_urls";
import User from "app/utils/user";
import PUBLIC_SITE_URL from "app/constants/public_site_urls";
import modalsSlice from "app/store/modals/modals.slice";
import "app/components/modules/navigation/top_navigation/top_navigation.scss";

const TopNavigation = ({
    user,
    isNavbarToggled,
    selectionTool,
    listing,
    toggleNavbar,
}: {
    user: IUser;
    isNavbarToggled: boolean;
    selectionTool: JSX.Element;
    listing?: ListingType;
    toggleNavbar: () => void;
}): ReactElement => {
    const location = useLocation();
    const navigate = useNavigate();
    const isRouteActive = (link: string): boolean => location && location.pathname === link;
    const { toggleMembersProgramModal, toggleBusinessDashboardModal, toggleListingsUpgradeModal } =
        modalsSlice((state) => state);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [imageLoaded, setImageLoaded] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const userSettingsRef = useRef<HTMLDivElement>(null);
    const UserModule = User(user as IUser);
    const isBusinessDashboard = location?.pathname.includes("business");
    const dashboardOrBusinessText = isBusinessDashboard ? "RD view" : "vendor view";

    useClickOutside(userSettingsRef, () => {
        if (expanded) setExpanded(false);
    });

    const userDropdown = (): JSX.Element => (
        <div
            className={`top-navbar__user-settings-dropdown ${expanded ? "isToggled" : ""}`}
            ref={dropdownRef}
            style={{ height: expanded ? `${dropdownRef?.current?.scrollHeight}px` : 0 }}
        >
            <div className="top-navbar__user-settings-info">
                <p className="top-navbar__user-settings-name">{user.username}</p>
                <p className="top-navbar__user-settings-email">{user.email}</p>
            </div>

            <ul className={`top-navbar__user-settings-list ${expanded ? "isToggled" : ""}`}>
                <li className="top-navbar__user-settings-list-item">
                    <button
                        type="button"
                        className="link"
                        style={{ width: "100%" }}
                        onClick={() => {
                            if (!isBusinessDashboard) {
                                if (user.is_vendor) {
                                    navigate(URL.BUSINESS_LISTINGS);
                                } else {
                                    toggleBusinessDashboardModal();
                                }
                            } else {
                                navigate(URL.DASHBOARD);
                            }
                        }}
                    >
                        <SwitchOutlined />
                        <span>Switch to {dashboardOrBusinessText}</span>
                    </button>
                </li>

                <div className="top-navbar__seperating-line" />

                <li className="top-navbar__user-settings-list-item">
                    <Link
                        to={URL.PROFILE}
                        className={`link ${isRouteActive(URL.PROFILE) ? "isActive" : ""}`}
                    >
                        <ProfileOutlined />
                        <span>Profile</span>
                    </Link>
                </li>
                <li className="top-navbar__user-settings-list-item">
                    <Link
                        to={URL.ACCOUNT}
                        className={`link ${isRouteActive(URL.ACCOUNT) ? "isActive" : ""}`}
                    >
                        <GearOutlined />
                        <span>Account</span>
                    </Link>
                </li>
                <li className="top-navbar__user-settings-list-item">
                    <Link to={URL.LOGOUT} replace className="link">
                        <LogoutOutlined />
                        <span>Logout</span>
                    </Link>
                </li>
            </ul>
        </div>
    );

    return (
        user && (
            <header className="top-navbar">
                <div className={`top-navbar__header ${isNavbarToggled ? "isToggled" : ""}`}>
                    <div className={`top-navbar__hamburger ${isNavbarToggled && "isToggled"}`}>
                        <ExpandSidebarOutlined onClick={toggleNavbar} />
                    </div>
                    <div className="top-navbar__race-select">{selectionTool}</div>
                    {!_isEmpty(user) && (
                        <div className="top-navbar__item-wrap top-navbar__item-wrap--user-settings">
                            <div className="top-navbar__plan-status-wrap">
                                <div className="top-navbar__plan-status-wrap-item">
                                    {!isBusinessDashboard && !UserModule.isPremiumUser() && (
                                        <Button
                                            className="upgrade-plan-btn"
                                            type="default"
                                            size="middle"
                                            onClick={toggleMembersProgramModal}
                                        >
                                            Upgrade plan to Pro
                                        </Button>
                                    )}
                                    {isBusinessDashboard &&
                                        listing?.plan?.upgradeable &&
                                        listing?.status === ListingStatus.PUBLISHED && (
                                            <Button
                                                className="upgrade-plan-btn"
                                                type="default"
                                                size="middle"
                                                onClick={() => toggleListingsUpgradeModal()}
                                            >
                                                Upgrade to Premium
                                            </Button>
                                        )}
                                </div>
                            </div>
                            <div
                                className="top-navbar__user-settings"
                                onClick={() => setExpanded(!expanded)}
                                ref={userSettingsRef}
                                role="presentation"
                            >
                                <div className="top-navbar__user-settings-item">
                                    <Tooltip title="Help center" placement="bottom">
                                        <a
                                            href={PUBLIC_SITE_URL.HELP}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="no-border"
                                        >
                                            <QuestionOutlined style={{ fontSize: "2.2rem" }} />
                                        </a>
                                    </Tooltip>
                                </div>
                                <div className="top-navbar__user-settings-item">
                                    <span onClick={(e) => e.preventDefault()} role="presentation">
                                        {user.image && (
                                            <img
                                                className={`top-navbar__user-image ${
                                                    imageLoaded ? "hasLoaded" : ""
                                                }`}
                                                src={user.image}
                                                onLoad={() => setImageLoaded(true)}
                                                alt="user_image"
                                            />
                                        )}
                                    </span>
                                    {userDropdown()}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </header>
        )
    );
};

export default TopNavigation;
