import { ReactElement, useEffect, useRef } from "react";
import { useParams, useNavigate, Navigate, Params } from "react-router-dom";
import { IUser } from "app/store/types/user.types";
import { isNumeric, _isEmpty } from "app/utils/helpers";
import AddEditRace from "app/components/templates/add_edit_race/add_edit_race";
import PageWithLoader from "app/hoc/page_with_loader/page_with_loader";
import URL from "app/constants/route_urls";
import userSlice from "app/store/user/user.slice";
import { RaceDetailsType } from "app/types/races/race.types";
import { defaultRaceData } from "app/components/templates/add_edit_race/constants";
import { useGetRaceDetails, useGetRaceFormData } from "app/utils/api/queries/races.query";
import racesSlice from "app/store/races/races.slice";

const RaceAdd = (): ReactElement => {
    const params = useParams<Readonly<Params<string>>>();
    const raceMode = useRef<string>(params && params.id ? "copy" : "add");
    const navigate = useNavigate();

    const { user } = userSlice((state) => state);
    const { importedRace } = racesSlice((state) => state);
    const isFetchEnabled = !!(params?.id && isNumeric(params?.id));
    const {
        data: raceDetail,
        isLoading: isRaceDetailLoading,
        error,
        isError,
    } = useGetRaceDetails(
        { pk: params.id! },
        {
            enabled: isFetchEnabled,
        }
    );

    const raceFormData = useGetRaceFormData({});

    // Reidirect if not found
    useEffect(() => {
        if (isError && error?.response.data.detail === "Not found.") navigate("/404");
    }, [error, isError, navigate]);

    // Memoized Race Data object depending on race mode
    const prepareRaceData = (): RaceDetailsType => {
        const race =
            (raceMode.current === "edit" || raceMode.current === "copy") && raceDetail
                ? raceDetail
                : importedRace.race;
        return defaultRaceData(race, raceMode, user as IUser);
    };

    // Display content depending on certain conditions
    const renderRaceDetailsByType = (): JSX.Element => {
        if (params.id && !isNumeric(params.id)) return <Navigate to={URL.RACES} />;
        const restructureRaceData = {
            ...prepareRaceData(),
            events: prepareRaceData().events.map((event) => ({ ...event, pk: undefined })),
            start_date: raceMode.current === "copy" ? "" : prepareRaceData().start_date,
        };

        return (
            <AddEditRace
                mode={raceMode.current as "add" | "copy"}
                user={user as IUser}
                formData={raceFormData.data || {}}
                raceData={restructureRaceData as RaceDetailsType}
            />
        );
    };

    return (
        <PageWithLoader
            isLoading={
                raceFormData.isLoading ||
                (isFetchEnabled && isRaceDetailLoading) ||
                _isEmpty(user) ||
                _isEmpty(raceFormData.data)
            }
        >
            {renderRaceDetailsByType()}
        </PageWithLoader>
    );
};

export default RaceAdd;
