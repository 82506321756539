import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import API_URL from "app/constants/api_urls";
import API from "../axios";

const addReview = async (payload: {
    content: string;
    rating: number;
    listing: number;
}): Promise<any> => {
    const res = await API.post<any>(`${API_URL.REVIEWS}add/`, payload);
    return res.data;
};

export const useAddReview = ({
    onSuccess,
    onError,
}: {
    onSuccess?: () => void;
    onError?: (error: AxiosError<{ message: string }>) => void;
}) => {
    const queryClient = useQueryClient();

    return useMutation<
        any,
        AxiosError<{ message: string }>,
        { content: string; rating: number; listing: number }
    >({
        mutationFn: (payload) => addReview(payload),
        onSuccess: (data) => {
            if (onSuccess) onSuccess();

            queryClient.invalidateQueries({
                queryKey: ["listing_reviews", data.listing.toString(), data.author.pk],
            });
        },
        onError: (error) => {
            if (onError) {
                onError(error);
            }
        },
    });
};

const claimListing = async (listingId: string): Promise<any> => {
    const response = await API.patch(`${API_URL.BUSINESS_LISTINGS}${listingId}/claim/`);
    return response;
};

export const useListingClaim = (isEnabled?: boolean) => {
    const queryClient = useQueryClient();
    return useMutation<any, AxiosError<any>, any>({
        mutationFn: (listingId: string) => {
            if (!isEnabled) throw new Error("ID was not provided.");
            return claimListing(listingId);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ["vendor_listings"],
            });
        },
        onError: (error) => error,
    });
};
