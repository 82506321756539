import { ReactElement, ReactNode, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import Races from "app/components/templates/races/past_races/past_races";
import WithMyRaces from "app/components/templates/races/races";
import STORAGE_CONSTANTS from "app/constants/storage";
import Storage from "app/utils/storage/local";
import { _isEmpty } from "app/utils/helpers";
import racesSlice from "app/store/races/races.slice";
import { useGetRaces } from "app/utils/api/queries/races.query";
import { QUERY_KEYS } from "app/utils/api/queries/constants";
import { Typography } from "antd";

const PastRaceContent = (): JSX.Element => <Races />;

const PastRaces = (): ReactElement => {
    const PastRacesWithMyRaces = WithMyRaces(PastRaceContent);
    const queryClient = useQueryClient();
    const pastRacesState = racesSlice((state) => state.races);
    const { currentPage, location } = pastRacesState.past;

    const { data: races } = useGetRaces({
        filter: "past",
        page: currentPage,
    });

    const { count } = races ?? {};

    // If the user's location changes then re-fetch the races
    useEffect(() => {
        const userLocation = Storage.get(STORAGE_CONSTANTS.userLocation);
        const currentRaceLocation = location;

        if (!_isEmpty(currentRaceLocation)) {
            if (userLocation !== currentRaceLocation) {
                queryClient.invalidateQueries({
                    queryKey: [QUERY_KEYS.RACES, "past", currentPage],
                });
            }
        }
    }, [currentPage, location, queryClient]);

    return (
        <PastRacesWithMyRaces
            title="Past races"
            subTitle={(<Typography.Text>A list of your past races.</Typography.Text>) as ReactNode}
            count={
                count > 0 && (
                    <Typography.Text>
                        <strong>
                            <strong>{count}</strong> past race
                            {count > 1 ? "s" : ""}
                        </strong>
                    </Typography.Text>
                )
            }
        />
    );
};

export default PastRaces;
