import { ComponentType, ReactNode } from "react";
import { Typography, Button } from "antd";
import { PlusOutlined } from "assets";
import { RaceType } from "app/types/races/race.types";
import { _isEmpty } from "app/utils/helpers";
import RaceCard from "app/components/elements/cards/race_card/race_card";
import modalsSlice from "app/store/modals/modals.slice";
import "app/components/templates/races/races.scss";

const { Title, Text } = Typography;

export const renderRaceCards = (races: RaceType[], type: "past" | "upcoming"): JSX.Element[] =>
    races.map((race: RaceType) => (
        <RaceCard
            key={race.pk}
            pk={race.pk}
            name={race.name}
            logo={race.logo}
            city_state={race.city_state}
            display_date={race.display_date}
            listed={race.listed}
            type={type}
        />
    ));

export const renderNoRacesContent = (
    type: "past" | "upcoming",
    toggleModal: () => void
): JSX.Element => (
    <div className="races__empty-message">
        <Text>You have no {type} races to display.</Text>
        {type === "upcoming" && (
            <Text>
                <span role="presentation" className="link" onClick={toggleModal}>
                    Add a race
                </span>{" "}
                to start using your dashboard tools.
            </Text>
        )}
    </div>
);

export const renderRacesContent = (
    races: RaceType[],
    type: "past" | "upcoming",
    toggleModal: () => void
): JSX.Element[] | JSX.Element | null => {
    if (!_isEmpty(races)) return renderRaceCards(races, type);
    return renderNoRacesContent(type, toggleModal);
};

interface IMyRaces {
    title: string;
    subTitle?: string | ReactNode;
    count?: string | ReactNode;
}

const WithMyRaces =
    (WrappedComponent: ComponentType) =>
    ({ title, subTitle, count }: IMyRaces): JSX.Element => {
        const { toggleImportRaceModal } = modalsSlice((state) => state);
        return (
            <div className="races">
                <div className="races__top page__header">
                    <div className="races__top-header">
                        <Title level={1}>{title}</Title>
                        {subTitle && <Text>{subTitle}</Text>}
                    </div>
                    <Button type="primary" onClick={toggleImportRaceModal} icon={<PlusOutlined />}>
                        Add race
                    </Button>
                </div>
                {count && <div className="page__toolbar">{count}</div>}
                <div className="races__content">
                    <WrappedComponent />
                </div>
            </div>
        );
    };

export default WithMyRaces;
