import { useState, ReactElement, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SpinnerOutlined } from "assets/icons/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Input, Typography } from "antd";
import { LockOutlined } from "assets";
import { blurred, inputError } from "app/utils/validation";
import { tryCatch } from "app/utils/helpers/functional_utilities";
import { NonFieldErrorType } from "app/utils/api/axios/service_error";
import { UserLoginSchema } from "app/lib/validation_schemas/auth.schema";
import { _isEmpty, timeout } from "app/utils/helpers";
import userSlice, { SOCIAL_PROVIDERS } from "app/store/user/user.slice";
import Dialog from "app/components/elements/dialog/dialog";
import GoogleAuthLogin from "app/components/modules/google_auth/google_auth";
import FacebookAuthLogin from "app/components/modules/facebook_auth/facebook_auth";
import Storage from "app/utils/storage/local";
import STORAGE_CONSTANTS from "app/constants/storage";
import logo from "assets/logo_full.svg";
import URL from "app/constants/route_urls";
import "app/views/public/auth/auth.scss";
import FormItemWrapper from "app/components/elements/form/form_item_wrapper";

function Login(): ReactElement {
    const { loginWithEmailAndPassword, socialLogin } = userSlice((state) => state);
    const location = useLocation();
    const [timeoutId, setTimeoutId] = useState<number | null>(null);
    const navigate = useNavigate();

    // Local state
    const [passwordResetMessage, setPasswordResetMessage] = useState<string>("");
    const [isLoginWithEmailAndPasswordLoading, setIsLoginWithEmailAndPasswordLoading] =
        useState<boolean>(false);
    const [isGoogleLoading, setIsGoogleLoading] = useState<boolean>(false);
    const [isFacebookLoading, setIsFacebookLoading] = useState<boolean>(false);
    const [nonFieldErrors, setNonFieldErrors] = useState<NonFieldErrorType>([]);
    const [verifyEmailError, setVerifyEmailError] = useState<string>("");
    const [passwordResetSuccessMessage, setPasswordResetSuccessMessage] = useState<string>("");

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(UserLoginSchema),
        mode: "onBlur",
        reValidateMode: "onBlur",
        defaultValues: {
            email: "",
            password: "",
        },
    });

    // Cleanup
    useEffect(
        () => () => {
            if (timeoutId) clearTimeout(timeoutId);
            Storage.remove(STORAGE_CONSTANTS.passwordResetMessage);
            Storage.remove(STORAGE_CONSTANTS.loginErrorMessage);
            Storage.remove(STORAGE_CONSTANTS.passwordResetSuccessMessage);
        },
        [timeoutId]
    );

    // Check for password reset message
    useEffect(() => {
        const passwordReset = Storage.get(STORAGE_CONSTANTS.passwordResetMessage);
        const verifyEmailMessage = Storage.get(STORAGE_CONSTANTS.loginErrorMessage);
        const passwordResetSuccessMessage = Storage.get(
            STORAGE_CONSTANTS.passwordResetSuccessMessage
        );

        if (!_isEmpty(passwordReset)) {
            setPasswordResetMessage(passwordReset as string);

            const timeoutId = timeout(() => {
                setPasswordResetMessage("");
                Storage.remove(STORAGE_CONSTANTS.passwordResetMessage);
            }, 15_000);

            setTimeoutId(timeoutId);
        }

        if (!_isEmpty(verifyEmailMessage)) {
            setVerifyEmailError(verifyEmailMessage as string);

            const timeoutId = timeout(() => {
                setPasswordResetMessage("");
                Storage.remove(STORAGE_CONSTANTS.passwordResetMessage);
            }, 15_000);

            setTimeoutId(timeoutId);
        }

        if (!_isEmpty(passwordResetSuccessMessage)) {
            setPasswordResetSuccessMessage(passwordResetSuccessMessage as string);

            const timeoutId = timeout(() => {
                setPasswordResetMessage("");
                Storage.remove(STORAGE_CONSTANTS.passwordResetSuccessMessage);
            }, 15_000);

            setTimeoutId(timeoutId);
        }
    }, []);

    type FiledNameTypes = "email" | "password";
    const submitHandler = async (values: { [K in FiledNameTypes]: string }): Promise<void> => {
        setIsLoginWithEmailAndPasswordLoading(true);
        setNonFieldErrors([]);
        const { email, password } = values;

        const [error] = await tryCatch(loginWithEmailAndPassword)(email, password);

        if (error) {
            setIsLoginWithEmailAndPasswordLoading(false);
            setNonFieldErrors(error.nonFieldErrors);
            return;
        }

        reset();
        setIsLoginWithEmailAndPasswordLoading(false);
    };

    const socialHandlers = {
        [SOCIAL_PROVIDERS.GOOGLE]: () => setIsGoogleLoading(!isGoogleLoading),
        [SOCIAL_PROVIDERS.FACEBOOK]: () => setIsFacebookLoading(!isFacebookLoading),
    };

    // Social login function
    const socialLoginHandler = async (
        socialToken: string,
        provider: SOCIAL_PROVIDERS.GOOGLE | SOCIAL_PROVIDERS.FACEBOOK
    ): Promise<void> => {
        setNonFieldErrors([]);
        socialHandlers[provider]();

        const [error] = await tryCatch(socialLogin)(socialToken, provider);
        if (error) socialHandlers[provider]();

        socialHandlers[provider]();
        reset();
        navigate(URL.UPCOMING_RACES);
    };

    // Display non-field errors in a dialog box
    const displayNonFieldErrors = (): JSX.Element[] | undefined | null => {
        if (!_isEmpty(nonFieldErrors)) {
            return nonFieldErrors.map((error: string) => (
                <Dialog message={error} key={error} type="error" />
            ));
        }
        return null;
    };

    // Display success message
    const displayDialogMessage = (type: "success" | "error"): JSX.Element | undefined | null => {
        if (!_isEmpty(passwordResetMessage)) {
            return <Dialog type={type} message={passwordResetMessage} />;
        }

        if (!_isEmpty(passwordResetSuccessMessage)) {
            return <Dialog type={type} message={passwordResetSuccessMessage} />;
        }

        if (!_isEmpty(verifyEmailError)) return <Dialog type={type} message={verifyEmailError} />;
        return null;
    };

    return (
        <div className="auth">
            <div className="auth-container">
                <div className="auth-wrap">
                    <div className="auth-wrap__header">
                        <a href="/" rel="noopener noreferrer">
                            <div className="auth-wrap__logo">
                                <img src={logo} alt="logo" />
                            </div>
                        </a>
                    </div>
                    <div className="auth-wrap__content">
                        {displayNonFieldErrors()}
                        {!_isEmpty(passwordResetMessage) && displayDialogMessage("success")}
                        {!_isEmpty(passwordResetSuccessMessage) && displayDialogMessage("success")}
                        {!_isEmpty(verifyEmailError) && displayDialogMessage("error")}
                        <div className="auth-wrap__social-group">
                            <div className="auth-wrap__social-group-item">
                                <GoogleAuthLogin
                                    text="Continue with Google"
                                    isLoading={isGoogleLoading}
                                    onError={() =>
                                        setNonFieldErrors([
                                            "Something went wrong. If the problem persists, please contact us.",
                                        ])
                                    }
                                    tokenHandler={(token: string) => {
                                        setNonFieldErrors([]);
                                        socialLoginHandler(token, SOCIAL_PROVIDERS.GOOGLE);
                                    }}
                                />
                            </div>
                            <div className="auth-wrap__social-group-item">
                                {isGoogleLoading && <SpinnerOutlined />}
                            </div>
                        </div>
                        <div className="auth-wrap__social-group">
                            <div className="auth-wrap__social-group-item">
                                <FacebookAuthLogin
                                    text="Continue with Facebook"
                                    isLoading={isFacebookLoading}
                                    onError={(err: string) => {
                                        setNonFieldErrors([err]);
                                    }}
                                    tokenHandler={(token: string) => {
                                        setNonFieldErrors([]);
                                        socialLoginHandler(token, SOCIAL_PROVIDERS.FACEBOOK);
                                    }}
                                />
                            </div>
                            <div className="auth-wrap__social-group-item">
                                {isFacebookLoading && <SpinnerOutlined />}
                            </div>
                        </div>
                        <div className="auth-wrap__divider">
                            <Typography.Text>Or sign in with e-mail</Typography.Text>
                        </div>
                        <form onSubmit={handleSubmit(submitHandler)}>
                            <div className="auth-wrap__form-group">
                                <FormItemWrapper label="Email" error={errors.email}>
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <Input
                                                value={value || ""}
                                                placeholder="Enter your email address"
                                                status={errors?.email ? "error" : ""}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                            />
                                        )}
                                    />
                                </FormItemWrapper>
                            </div>
                            <div className="auth-wrap__form-group">
                                <FormItemWrapper
                                    label={
                                        <div className="auth-wrap__password-label-wrap">
                                            <Typography.Text strong>Password</Typography.Text>
                                            <div className="auth-wrap__password-reset-wrap">
                                                <LockOutlined />
                                                <Typography.Text
                                                    className="ant-link"
                                                    onClick={() =>
                                                        navigate(URL.PASSWORD_RESET, {
                                                            state: { from: location?.state?.from },
                                                        })
                                                    }
                                                >
                                                    Forgot your password
                                                </Typography.Text>
                                            </div>
                                        </div>
                                    }
                                    error={errors.password}
                                >
                                    <Controller
                                        name="password"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <Input.Password
                                                placeholder="Enter your password"
                                                value={value || ""}
                                                status={errors?.password ? "error" : ""}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                            />
                                        )}
                                    />
                                </FormItemWrapper>
                            </div>

                            <div className="auth-wrap__form-group--submit">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={isLoginWithEmailAndPasswordLoading}
                                    disabled={isLoginWithEmailAndPasswordLoading}
                                >
                                    Sign in
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="auth__linking">
                    <Typography.Text>
                        Don&apos;t have an account?{" "}
                        <Link
                            to={URL.REGISTER + (location?.search || "")}
                            state={location.state}
                            className="ant-link"
                        >
                            Sign up now
                        </Link>
                    </Typography.Text>
                </div>
            </div>
        </div>
    );
}

export default Login;
